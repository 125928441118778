import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import styled from 'styled-components'
import { useParams } from 'react-router'
import moment from 'moment-timezone'

import './style.scss'
import { BROWSER_TIMEZONE } from '../../../../constants/timezone'
import { ROUTE_NAMES } from '../../../../constants/route-names'
import { encodeOccurrence } from '../../../../helpers/encodeOccurrence'

interface Props {
  id: number
  isActive?: boolean
  isBeforeCurrentDate?: boolean
  shift: any
  title: string
  event: any
  available?: number
  isMyEvent?: boolean
}

export default function CalendarEvent(props: Props) {
  const { organizationId } = useParams()

  if (!organizationId) {
    throw new Error('organization id not found')
  }
  const orgId = parseInt(organizationId!, 10)

  const YELLOW = 'rgba(255, 204, 0, 0.13)'
  const BLUE = 'rgba(94, 143, 232, 0.1)'

  let availableSlotsValue: number = 0
  if (!props.isMyEvent) {
    const eventTimeShift = props.event.timeshifts
    if (eventTimeShift) {
      const roles = eventTimeShift.flat()
      const availableSlotsArrValue = roles.map((item: any) => {
        return item.roles
      })
      const availableSlotsArr = availableSlotsArrValue.flat()
      const availableSlots = availableSlotsArr.map((item: any) => {
        return item.available
      })
      availableSlotsValue = availableSlots.length && availableSlots.reduce((a: number, b: number) => a + b)
    }
  }
  const eventIsFull = availableSlotsValue <= 0
  const disabledUrl = props.isBeforeCurrentDate

  const getUrlLink = () => {
    if (!props.isMyEvent) {
      return ROUTE_NAMES.occurrenceDetails(orgId, encodeOccurrence(props.event.opportunityId, props.event.startsAt, props.event.organization.timeZone))
    }
    
    return props.isMyEvent ?
      ROUTE_NAMES.myEventDetails(orgId, props.id) :
      ROUTE_NAMES.eventDetails(orgId, props.id)
  }

  const CalendarEventContainer = styled(Link)`
    background: ${(props.isActive && !eventIsFull) || props.isMyEvent
      ? YELLOW
      : BLUE};
    color: ${props.isActive ? 'inherit' : 'gray'};
    pointer-events: ${props.isActive && !disabledUrl ? 'visible' : 'none'};
  `
  const timezone = props.event.organization.timeZone || BROWSER_TIMEZONE
  const startTime = moment(props.shift.startsAt).format('h:mm a')
  const endTime = moment(props.shift.endsAt).format('h:mm a')
  const startTimeWithTimezone = moment(props.shift.startsAt).tz(timezone).format('h:mm a')
  const endTimeWithTimezone = moment(props.shift.endsAt).tz(timezone).format('h:mm a')

  return (
    !props.isBeforeCurrentDate || props.isMyEvent ?
      <CalendarEventContainer
        to={disabledUrl ? '#' : getUrlLink()}
        className={cn('calendar_event-container', {
          'calendar_event-no_registration': props.event.missingRequiredMemberships,
        })}
>
        {props.isMyEvent ?
          <div>
            {startTime} - {endTime}
          </div>
          : <div>
              {startTimeWithTimezone} - {endTimeWithTimezone}
            </div>
        }
       <span className="calendar_event-title">{props.title}</span>
      </CalendarEventContainer>
      : null
  )
}
