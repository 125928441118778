import { useCallback, useMemo } from 'react'
import { useFetch } from "../../api/hooks";

export const useFetchPerson = () => {
    const [request, { result, loading, error, called }] = useFetch()
    const fetchPerson = useCallback(async (onResultUpdate) => {
        return request({
            onSuccess: result => {
                onResultUpdate && onResultUpdate(result)
            },
            method: 'get',
            url: `/user/get`,
        })
    }, [request])
    return useMemo(
        () => [
            fetchPerson,
            {
                result,
                loading,
                called,
                error,
            },
        ],
        [result, loading, error, called, fetchPerson],
    )
}
