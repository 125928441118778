import { chunk, groupBy } from 'lodash'
import moment, { unitOfTime } from 'moment'
import React, { useMemo } from 'react'

import CalendarHeader from '../../../components/calendar/header'
import CalendarDay, { Props as CalendarDayProps } from '../../../components/calendar/day'
import './style.scss'
import { viewTypes } from '../../../../scenes/volunteer-events/viewTypes'
import { ValueOf } from '../../../../utils/typeUtils'

interface Props {
  date: Date
  events: any
  isMyEvents?: boolean
  viewType?: ValueOf<typeof viewTypes>
}

// TODO move to share, reconcile with Events in admin
const useEventCalendarGrid = (props: Props) => {
  const {
    date = new Date(),
    events,
    isMyEvents,
    viewType = viewTypes.MONTH,
  } = props

  // TODO make sure the tz is accounted for in format('L')!
  const eventsById = useMemo(
    () => groupBy(events, event => {
      const startsAt = isMyEvents ? event.opportunity.startsAt : event.startsAt
      return moment(startsAt).format('L')
    }),
    [events, isMyEvents]
  )

  return useMemo(() => {
    const month = date.getMonth()

    const today = moment()
    const startsAt = moment(date).startOf(viewType as unitOfTime.StartOf).weekday(0)
    const endsAt = moment(date).endOf(viewType as unitOfTime.StartOf).weekday(6)

    const cells: CalendarDayProps[] = []

    for (const date = startsAt.clone(); date.isBefore(endsAt); date.add(1, 'day')) {
      const key = date.format('L')

      cells.push({
        isCurrentMonth: date.month() === month,
        isToday: date.isSame(today, 'day'),
        isBeforeCurrentDate: date.isBefore(today, 'day'),
        dayNumber: date.date(),
        weekDay: String(date.day()),
        events: eventsById[key] || [],
        isMyEvent: isMyEvents // -.- *sigh*
      })
    }

    return chunk(cells, 7)
  }, [date, eventsById, isMyEvents, viewType])
}

const Calendar = (props: Props) => {
  const grid = useEventCalendarGrid(props)

  return <div className="calendar-container">
    <CalendarHeader />
    <div className="calendar_body-container">
      {grid.map((row: CalendarDayProps[], week: number): any => (
        <div key={week} className={`calendar_body-week-${week + 1}`}>
          {row.map((cell: CalendarDayProps, day: number) => (
            <CalendarDay
              key={day}
              hidden={!cell.isCurrentMonth && props.viewType === viewTypes.MONTH}
              {...cell}
            />
          ))}
        </div>
      ))}
    </div>
  </div>
}

export default Calendar
