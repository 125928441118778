import React, { useCallback } from "react"
import { useSnackbar } from "notistack"
import { CircularProgress } from '@material-ui/core'

const useInProgressNotification = () => {
    const { enqueueSnackbar } = useSnackbar()

    return useCallback( msg => {
        return enqueueSnackbar(msg, {
            variant: 'default',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
            },
            persist: true,
            action: () => (<CircularProgress />)
        })
    }, [enqueueSnackbar])
}

export default useInProgressNotification