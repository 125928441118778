import React from 'react'
import { Person } from '../../question-sets/types'
import { Field, Form, Formik } from 'formik'
import Grid from '@material-ui/core/Grid'
import { Button, FormControlLabel, Radio } from '@material-ui/core'
import { useModalStyles } from '../StepModalContainer'
import yup from '../../utils/yup'
import { RadioGroup } from 'formik-material-ui'
import isNull from 'lodash/isNull'

interface PersonCheckStepProps {
  cancel: () => void
  handleSubmit: (...props: any) => any
  persons: Person[]
  personName: string
  emailExactMatch: boolean
}

const validationSchema = yup.object({
  personId: yup.number().required(),
})

export function PersonCheckStep({
  cancel,
  handleSubmit,
  persons,
  emailExactMatch,
  personName,
}: PersonCheckStepProps) {
  const classes = useModalStyles()
  return (
    <Formik initialValues={{ personId: null }} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ submitForm, isSubmitting, values }) => {
        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field component={RadioGroup} name="personId">
                  {persons.map(({ id, givenName, familyName, email, phoneNumber }) => (
                    <FormControlLabel
                      key={id}
                      value={id.toString()}
                      control={<Radio disabled={isSubmitting} />}
                      label={`${givenName} ${familyName} - ${email || phoneNumber}`}
                    />
                  ))}
                  {!emailExactMatch && (
                    <FormControlLabel
                      value={'0'}
                      control={<Radio disabled={isSubmitting} />}
                      label={`I would like to add new ${personName}`}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              classes={{ root: classes.buttonsContainer }}
            >
              <Button onClick={cancel} variant="contained" color="secondary" className={classes.button}>
                Back
              </Button>
              <Button
                className={classes.button}
                disabled={isSubmitting || isNull(values.personId)}
                variant="contained"
                color="primary"
                onClick={submitForm}
              >
                {isNull(values.personId)
                  ? 'Next'
                  : values.personId === '0'
                  ? `Add new ${personName}`
                  : `Add selected ${personName}`}
              </Button>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default PersonCheckStep
