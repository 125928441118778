import { Auth } from 'aws-amplify'
import jwtDecode from 'jwt-decode'
import { getAuthorizationToken as getAuthToken } from '../config/get-authorization-token'
import getErrorMessage from '../../src/utils/getErrorMessage'
import { mapToSnakeCaseKeys } from '../utils/case-mapper'
import { Person } from '../civic-champs-shared/question-sets/types'

type ENV_KEYS =
  | 'AWS_REGION'
  | 'COGNITO_USER_POOL_ID'
  | 'COGNITO_WEB_CLIENT_ID'
  | 'COGNITO_AUTH_FLOW'
  | 'COGNITO_DOMAIN'
  | 'API_ENDPOINT'
  | 'ENV'
  | 'URL'
  | 'LANDING_URL'
  | 'NGROK_API_URL'

interface CognitoUser {
  email?: string
  email_verified?: string
  family_name: string
  given_name: string
  phone_number?: string
  phone_number_verified?: string
  sub: string
}

interface DecodedToken extends CognitoUser {
  'custom:permissions'?: string
  exp: number
  expiresIn: string
  iat: number
}

// @ts-ignore
const mapPersonToCognitoUser = (person: Person): CognitoUser => ({ ...mapToSnakeCaseKeys(person), sub: person.cognitoSub })

export default class BaseService {
  getEnvVar = (key: ENV_KEYS) => process.env['REACT_APP_' + key]

  base = (rest: string) => `${this.getEnvVar('API_ENDPOINT')}/${rest}`

  getAuthorizationToken = async () => {
    try {
      return await getAuthToken()
    } catch (e) {
      localStorage.clear() // TODO clear only token-specific localStorage keys
      return null
    }
  }

  decodeSchedulerToken = (token: string): DecodedToken => {
    const decodedToken = jwtDecode(token) as DecodedToken
    if (Date.now() >= decodedToken.exp * 1000) {
      throw new Error('Your token is expired')
    }
    return decodedToken
  }

  currentUser = async () => {
    try {
      const schedulerToken = localStorage.getItem('schedulerToken')
      if (schedulerToken) {
        return this.decodeSchedulerToken(schedulerToken)
      }
      const currentUser = await this.getJSON(this.base('user'))
      return mapPersonToCognitoUser(currentUser)
    } catch (e) {
      if (getErrorMessage(e) === 'No current user') {
        return null
      }
      console.log('Error while getting current user in baseService', e)
      throw (e instanceof Error ? e : Error(e))
    }
  }

  requestWithToken = async () => {
    const token = await this.getAuthorizationToken()
    if (!!token) {
      return {
        'Content-Type': 'application/json',
        Authorization: token,
      }
    } else {
      return { 'Content-Type': 'application/json' }
    }
  }

  async getJSON(api: string): Promise<any> {
    try {
      const config = {
        method: 'get',
        headers: await this.requestWithToken(),
      }
      const req = await fetch(api, config as any)
      const json = await req.json()
      if (json && json.statusCode === 403) {
        try {
          await Auth.signOut()
        } catch (e) {
          console.log(e)
        } finally {
          localStorage.clear()
        }
        return
      }
      return json
    } catch (e) {
      console.log(`error in getJSON with api: ${api}`, e)
      throw Error(e)
    }
  }

  async postFile(api: string, body: object): Promise<any> {
    const headers = await this.requestWithToken()
    delete headers['Content-Type']
    try {
      const response = await fetch(api, {
        method: 'post',
        headers,
        body,
      } as any)
      const data = await response.json()
      if (data.statusCode >= 400 && data.statusCode < 600) {
        throw new Error(data.message)
      }
      return data
    } catch (err) {
      console.log(`error in getJSON with api: ${api}`, err)
      throw new Error(err)
    }
  }

  async postJSON(api: string, body: object): Promise<any> {
    try {
      const config = {
        method: 'post',
        headers: await this.requestWithToken(),
        body: JSON.stringify(body),
      }
      const req = await fetch(api, config as any)
      return await req.json()
    } catch (e) {
      console.log(`error in postJSON with api: ${api}`, e)
      throw Error(e)
    }
  }

  async DELETE(api: string): Promise<any> {
    try {
      const req = await fetch(api, {
        method: 'DELETE',
        headers: await this.requestWithToken(),
      } as any)
      return await req.json()
    } catch (e) {
      console.error('', e)
    }
  }

  async putJSON(api: string, body: object): Promise<any> {
    try {
      const config = {
        method: 'put',
        headers: await this.requestWithToken(),
        body: JSON.stringify(body),
      }
      const req = await fetch(api, config as any)
      return await req.json()
    } catch (e) {
      console.error('', e)
    }
  }
}
