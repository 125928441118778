import moment from 'moment-timezone'

import { BROWSER_TIMEZONE } from '../constants/timezone'

const getTimeZone = (timeZone: any) => {
  if (!timeZone) {
    return BROWSER_TIMEZONE
  }

  return timeZone
}

const encodeOccurrence = (opportunityId: number | string, startsAt: any, timeZone: string): string => {
  const payload = JSON.stringify({
    opportunityId,
    date: moment.tz(startsAt, getTimeZone(timeZone)).format('YYYY-MM-DD'),
  })

  return window.btoa(payload)
}

const decodeOccurrenceId = (encodedStartDate: string): { date: string; opportunityId: number } =>
  JSON.parse(window.atob(encodedStartDate))

export { encodeOccurrence, decodeOccurrenceId }
