import * as React from 'react'
import { FormikErrors, FormikProps } from 'formik'
import { InvitationType } from './invitation'
import { NamedRef, OrgNamedRef, PersonRef } from '../../common/types'

export * from '../../common/types'
export { InvitationType } from './invitation'

// TODO: might need to fix this
export interface QuestionForm {
  questions: Question[]
}

export interface PersonPayload {
  givenName?: string
  familyName?: string
  email?: string
  phoneNumber?: string
  homePhoneNumber?: string
  emailVerified?: boolean
  phoneNumberVerified?: boolean
  cognitoSub?: string
  stripeCustomerId?: string
  sendInvite?: boolean
  birthday?: string
}

export interface PartialPerson extends PersonPayload {
  id?: number
}

export interface Person extends PersonPayload {
  id: number
  givenName: string
  familyName: string
  createdAt: Date
  deletedAt?: Date
}

export interface CredentialIDSignaturePayload {
  personId: number
  credentialId: number
  associatedEntity: AssociatedEntity
  expiredAt?: any | undefined
  identificationType?: string
  attachmentIds?: number[]
  identificationTypeOther?: string
}

export enum CredentialType {
  Waiver = 'waiver',
}

export enum CredentialContentType {
  Text = 'text',
  Url = 'url',
}

export enum CredentialExpirationOption {
  Day = 'day',
  Month = 'month',
  Year = 'year',
  Date = 'date',
  Updated = 'updated',
  DayOf = 'dayof',
}

export interface CredentialListItem {
  title: string
  credentialId: number
  credentialVersionId: number
  type: CredentialType
  totalOpportunies: number
  totalVolunteers: number
  createdAt: Date
  updatedAt: Date
}

export interface CredentialList {
  data: CredentialListItem[]
  total?: number
  offset?: number
}

export interface CredentialStats {
  totalVolunteers?: number
  totalOpportunities?: number
  totalVersions?: number
}

export interface Credential {
  id: number
  organizationId: number
  isPublished: boolean
  type: CredentialType
  createdAt: Date
  expirationOption: CredentialExpirationOption
  expirationOptionLength: number
  expirationDate: Date
  organization?: OrgNamedRef
}

export interface CredentialVersion {
  id: number
  title: string
  description: string
  content: string
  contentType: CredentialContentType
  url: string
  credentialId: number
  waiverStatement: string
  createdAt: Date
}

export enum UserCredentialStatus {
  VALID = 'Valid',
  EXPIRED = 'Expired',
  OUTDATED = 'Outdated',
}

export interface UserCredentialPopulatedLessUserAndOrganization {
  id: number
  credentialVersionId: number
  credentialId: number
  createdAt: Date
  expiredAt?: Date
  status: UserCredentialStatus
  identificationType?: string
  identificationTypeOther?: string
}

export interface UserCredentialPopulated extends UserCredentialPopulatedLessUserAndOrganization {
  user: PersonRef
  organization: OrgNamedRef
}

export interface CredentialFull {
  credential: Credential
  version: CredentialVersion
  stats?: CredentialStats
  signedAt?: Date
  expiredAt?: Date
  userCredential?: UserCredentialPopulated
  updated?: boolean
}

export enum QuestionType {
  SHORT_TEXT = 'Short Text',
  LONG_TEXT = 'Long Text',
  MULTIPLE_CHOICE = 'Multiple Choice',
  SINGLE_CHOICE = 'Single Choice',
  DROPDOWN = 'Dropdown',
  TRUE_OR_FALSE = 'True or False',
  NUMBER = 'Number',
  DOCUMENT = 'Document',
  PHONE_NUMBER = 'Phone Number',
  EMAIL = 'Email',
  EMERGENCY_CONTACT = 'Emergency Contact',
  HOME_ADDRESS = 'Home Address',
}

export const QuestionTypeLabels = {
  [QuestionType.SHORT_TEXT]: 'Short Answer',
  [QuestionType.LONG_TEXT]: 'Long Answer',
  [QuestionType.SINGLE_CHOICE]: 'Multiple Choice',
  [QuestionType.MULTIPLE_CHOICE]: 'Checkboxes',
}

export interface Question {
  questionId: number
  answerId?: number
  questionType: QuestionType
  question: string
  options: string[]
  questionCreatedAt: Date
  questionDeletedAt?: Date
  organization?: OrgNamedRef
  createdBy?: PersonRef
  isRequired: boolean
  answeredBy?: PersonRef
  answerSet?: {
    id: number
    description: string
  }
  answerCreatedAt?: Date
  answerDeletedAt?: Date
  response?: any
  completed?: boolean
  includeOther?: boolean
  otherAnswer?: string
  conditionedOnQuestionId?: number
  conditionedOnAnswer?: string
  disabled?: boolean
}

export interface QuestionPayload {
  questionId?: number
  questionType?: QuestionType
  question?: string
  options?: string[]
  createdById?: number
  createdAt?: Date
  organizationId?: number
  isRequired?: boolean
}

export interface QuestionItemProps {
  questions: Question[]
  question: Question
  onChangeField: (value: any, key?: string) => void
  onRemoveQuestion: () => void
  onDuplicateQuestion: () => void
  isClickedToNextStep: boolean
  onChangeValidField: (value: boolean | null) => void
  existingQuestions?: Question[]
}

export interface QuestionAnsweringFormProps {
  onSubmit: (val: any) => void
  onSkip?: (val: any) => void
  onCancel?: (val: any) => void
  onEdit?: (val: any) => void
  questionSet: QuestionSetResponseWithRequired
  children?: React.ReactNode
  heading?: string
  additionalSchema?: any
  additionalInitialValues?: any
  required?: boolean
  readonly?: boolean
  organizationId: number
}

export interface QuestionSet {
  questionSetId: number
  answerSetId?: number
  organization?: OrgNamedRef
  name: string
  questionSetCreatedAt: Date
  questionSetDeletedAt?: Date
  answerSetCreatedAt?: Date
  answerSetDeletedAt?: Date
  createdBy?: PersonRef
  answeredBy?: PersonRef
  answerSetDescription?: string
  anonymous?: boolean
  surveyVersion?: number
}

export interface QuestionSetResponse extends QuestionSet {
  questions: Question[]
}

export interface QuestionSetResponseWithRequired extends QuestionSetResponse {
  required: boolean
  associatedEntity?: AssociatedEntity
}

export interface JoinQuestionSetPayload {
  questionSetId: number
  required: boolean
  name?: string
}

export interface JoinSurveyPayload {
  surveyId: number
  required: boolean
  name?: string
}

export interface QuestionSetResponsePayload {
  questionSetId?: number
  organizationId?: number
  name?: string
  createdAt?: Date
  deletedAt?: Date
  createdById?: number
  questions: QuestionPayload[]
}

export interface AnswerSetResponsePayload {
  questionSetId?: number
  answerSetId?: number
  organizationId?: number
  name?: string
  createdById?: number
  questions: AnswerPayload[]
}

export interface AnswerPayload {
  questionId?: number
  answerId?: number
  answeredById?: number
  answerSetId?: number
  response?: any
  completed?: boolean
  otherAnswer?: string
  disabled?: boolean
}

export interface QuestionFormBaseProps {
  item: Question
  error?: FormikErrors<Question>
  formikProps?: FormikProps<QuestionForm>
  readonly?: boolean
}

export interface ConditionLookup {
  [key: string]: Array<{ conditionedOnAnswer: any; targetedQuestionId: number; targetedQuestionIndex: number }>
}

export enum GroupType {
  Volunteer = 'volunteer',
  Member = 'member',
  UserDefined = 'user-defined',
  TaskRequester = 'task-requester',
  TaskRecipient = 'task-recipient',
  TaskVolunteer = 'task-volunteer',
  Mentee = 'mentee',
}

export interface AssociatedEntity {
  groupId?: number
  encodedOccurrence?: string
  organizationId?: number
  requiredGroup?: GroupType
}

export interface AssociatedEntityWithNameAndAction extends AssociatedEntity {
  name: string
  action: PrerequisitesAction
}

export interface QuestionSetPreReq extends QuestionSetResponseWithRequired {
  associatedEntity: AssociatedEntityWithNameAndAction
}

export enum PrerequisitesAction {
  GROUP_SIGN_UP = 'group_sign_up',
  EVENT_REGISTRATION = 'event_registration',
  USER_PROFILE = 'user_profile',
  USER_SIGN_UP = 'user_sign_up',
}

export interface PrerequisiteFilters {
  personId?: number
  required?: number
  associatedEntity?: AssociatedEntity
  invitationToken?: string //TODO call it "secureToken?"
  action?: PrerequisitesAction
  freshAnswerSet?: boolean
  excludeCompletedAnswerSets?: boolean
  includeSignedCredentials?: boolean
  excludedQuestionSetIds?: number[]
  excludeCredentials?: boolean
}

export interface ViewAnswerSetsContainerProps {
  filters: PrerequisiteFilters
  fullHeight: boolean
}

export interface RequirementsProps extends ViewAnswerSetsContainerProps {
  onCompleted: () => void
  isAdmin?: boolean
  person?: PartialPerson
  continueEvenIfError?: boolean
  organizationId?: number
}

export interface CredentialWithAssociatedEntity extends CredentialFull {
  associatedEntity: AssociatedEntity
}

export interface Prerequisites {
  fulfilledAllRequirements: boolean
  questionSets: QuestionSetPreReq[]
  credentials: CredentialWithAssociatedEntity[]
}

export interface OpportunityOccurrenceQuestionSet {
  id: number
  organization: OrgNamedRef
  occurrence: NamedRef
  questionSet: NamedRef
  survey?: NamedRef
  required: boolean
  createdAt: Date
  deletedAt?: Date
}

// Use Omit to exclude one or more fields (use "field1"|"field2"|"field3" etc to exclude multiple)
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

// TODO: think this through more - likely add more actions
export const invitationTypeToActionLookup = {
  [InvitationType.VOLUNTEERING]: PrerequisitesAction.GROUP_SIGN_UP,
  [InvitationType.MEMBERSHIP]: PrerequisitesAction.GROUP_SIGN_UP,
  [InvitationType.MENTORSHIP]: PrerequisitesAction.GROUP_SIGN_UP,
  [InvitationType.KIOSK]: PrerequisitesAction.EVENT_REGISTRATION,
  [InvitationType.SIGN_UP]: PrerequisitesAction.USER_SIGN_UP,
  [InvitationType.CUSTOM_GROUP]: PrerequisitesAction.GROUP_SIGN_UP,
}
