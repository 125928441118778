import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { Button } from '../../components/button'
import { Link, withRouter } from 'react-router-dom'
import IconComponent from '../../components/icon/index'
import SignUpPopup from '../auth/login/sign-up-popup'
import SignInPopup from '../auth/login/sign-in-popup'
import ConfirmSignUp from '../auth/confirm-sign-up'
import ForgotPassword from '../auth/forgot-password'
import './style.scss'
import ResetPassword from '../auth/forgot-password/reset-password'
import { ROUTE_NAMES } from '../../../constants/route-names'

import { RequirementsModal } from 'civic-champs-shared/question-sets/components'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { PrerequisitesAction } from 'civic-champs-shared/question-sets/types'
import { isRNWebView } from 'civic-champs-shared/constants/system'

interface SignUpInfo {
  username: string
  email: string
  password: string
  phone: string
}

const EMPTY_SIGN_UP_INFO = { username: '', email: '', password: '', phone: '' }

function PageHeader(props: any) {
  const [openMenu, setOpenMenu] = useState(false)
  const [openSignUp, setOpenSignUp] = useState(false)
  const [resetUsername, setResetUsername] = useState('')
  const [openConfirmSignUp, setOpenConfirmSignUp] = useState(false)
  const [openForgotPassword, setOpenForgotPassword] = useState(false)
  const [openResetPassword, setOpenResetPassword] = useState(false)
  const [signUpInfo, setSignUpInfo] = useState<SignUpInfo>(EMPTY_SIGN_UP_INFO)
  // const [cognitoSub, setCognitoSub] = useState<string>('')
  const logout = async () => {
    try {
      await Auth.signOut()
    } catch (error) {
      console.log('error ', error)
    } finally {
      localStorage.clear()
    }
  }

  const fulfillRequirementsPrompt = useShowPrompt(RequirementsModal)

  const handleRequirements = async () => {
    try {
      const done = await fulfillRequirementsPrompt({
        filters: {
          action: PrerequisitesAction.USER_SIGN_UP,
          freshAnswerSet: false,
          excludeCompletedAnswerSets: true,
        },
        // can't really change it without refactoring
        continueEvenIfError: true,
      })
      return done
    } catch (error) {
      return false
    }
  }

  const urlLink = ROUTE_NAMES.allEvents(props.orgId)

  if (isRNWebView) return null

  return (
    <div className={`header-module ${openMenu ? 'header-module--mobile' : ''}`}>
      <div className="header-module_head">
        <Link to={urlLink} className="header-module_head_logo">
          <IconComponent name={'logo'} height={'40px'} width={'117px'} color={'white'} />
        </Link>
        {props.currentUser && props.currentUser.sub ? (
          <span className="header-module_head_menu-icon" onClick={() => setOpenMenu(!openMenu)}>
            <IconComponent name={openMenu ? 'x_mark' : 'menu'} height={'12px'} width={'18px'} color={'white'} />
          </span>
        ) : (
          <Button
            className="header-module_head_sign-in"
            onClick={() => {
              props.setOpenLogin(true)
              setOpenSignUp(false)
            }}
            label={'Sign In'}
            type={'primary'}
            style={{ boxShadow: 'none' }}
            width={'111px'}
            height={'40px'}
            labelColor={''}
          />
        )}
      </div>
      {props.currentUser && props.currentUser.sub ? (
        <div className={'header-module_group'}>
          <Link to={ROUTE_NAMES.myEvents(props.orgId)} className={'header-module_text-bold'}>
            My Events
          </Link>
          <IconComponent name={'avatar'} height={'20px'} width={'20px'} color={'white'} style={{ marginRight: 8 }} />

          <span className={'header-module_name'}>
            {props.currentUser.given_name} {props.currentUser.family_name}
          </span>
          <span className={'header-module_logout'} onClick={logout}>
            (Log Out)
          </span>
        </div>
      ) : (
        <div className={'header-module_group'}>
          <Button
            onClick={() => {
              props.setOpenLogin(true)
              setOpenSignUp(false)
            }}
            label={'Sign In'}
            type={'primary'}
            style={{ boxShadow: 'none' }}
            width={'111px'}
            height={'40px'}
            labelColor={''}
            className={'desktop-sign-in'}
          />
        </div>
      )}
      {props.currentUser && props.currentUser.sub && (
        <>
          {openMenu ? (
            <div className={'header-module_group-mobile'}>
              <Link
                to={ROUTE_NAMES.myEvents(props.orgId)}
                className={'header-module_group-mobile_events'}
                onClick={() => setOpenMenu(false)}
              >
                My Events
              </Link>
              <div className="header-module_group-mobile_info">
                <span className="header-module_group-mobile_avatar">
                  <IconComponent
                    name={'user'}
                    height={'20px'}
                    width={'20px'}
                    color={'white'}
                    style={{ marginRight: 8 }}
                  />
                </span>
                <span className={'header-module_group-mobile_name'}>
                  {props.currentUser.given_name} {props.currentUser.family_name}
                </span>
                <span className={'header-module_group-mobile_logout'} onClick={logout}>
                  (Log Out)
                </span>
              </div>
            </div>
          ) : null}
        </>
      )}

      <SignUpPopup
        isOpen={openSignUp}
        closePopup={() => {
          setOpenSignUp(false)
          props.isEventDetailPage && props.setOpenLogin(false)
        }}
        onSignInClick={() => {
          setOpenSignUp(false)
          props.setOpenLogin(true)
        }}
        onSignUp={async (username: string, email: string, password: string, phone: string, cognitoSub?: string) => {
          setOpenSignUp(false)
          setSignUpInfo({ username, email, password, phone })
          setOpenConfirmSignUp(true)
          props.isEventDetailPage && props.setOpenLogin(false)
          if (cognitoSub) {
            await handleRequirements()
          }
          document.body.classList.remove('disable-scroll')
        }}
      />
      <SignInPopup
        isOpen={props.data.openLogin}
        closePopup={() => {
          props.setOpenLogin(false)
        }}
        onForgotPasswordClick={() => {
          props.setOpenLogin(false)
          setOpenForgotPassword(true)
        }}
        onSignUpClick={() => {
          props.setOpenLogin(false)
          setOpenSignUp(true)
        }}
        onSignIn={() => {
          // await setUser()
          props.setOpenLogin(false)
          document.body.classList.remove('disable-scroll')
        }}
      />
      <ConfirmSignUp
        signUpInfo={signUpInfo}
        isOpen={openConfirmSignUp}
        onConfirmed={() => {
          props.isEventDetailPage && props.setOpenLogin(false) // TODO huh
          document.body.classList.remove('disable-scroll')
        }}
        closePopup={() => setOpenConfirmSignUp(false)}
      />
      <ForgotPassword
        isOpen={openForgotPassword}
        closePopup={() => {
          setOpenForgotPassword(false)
          props.isEventDetailPage && props.setOpenLogin(false)
        }}
        onSignInClick={() => {
          setOpenForgotPassword(false)
          props.setOpenLogin(true)
        }}
        onForgotPassword={username => {
          setResetUsername(username)
          setOpenForgotPassword(false)
          setOpenResetPassword(true)
        }}
      />
      <ResetPassword
        username={resetUsername}
        isOpen={openResetPassword}
        closePopup={() => {
          setOpenResetPassword(false)
          props.isEventDetailPage && props.setOpenLogin(true)
          document.body.classList.remove('disable-scroll')
        }}
        onResetPassword={() => setOpenResetPassword(false)}
      />
    </div>
  )
}

export default withRouter(PageHeader)
