import React, { useCallback, useMemo, useState } from 'react'
import cn from 'classnames'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFilterStyles, useFormStyles } from '../helpers/style-input/useStyledInputStyles'

export interface InputProps {
  label?: string
  value: string
  onChange: (value: string) => void
  onBlur?: (e: React.FocusEvent<any>) => void
  onFocus?: () => void
  endAdornment?: React.ReactNode
  onAdornmentClick?: () => void
  clearable?: boolean
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  variant?: 'filter' | 'form'
  error?: string
  disabled?: boolean
  maxLength?: number
  className?: string
}

export const StyledInput = React.forwardRef(
  (
    {
      label,
      value,
      onChange,
      endAdornment,
      onAdornmentClick,
      onBlur,
      clearable,
      onKeyDown,
      onFocus,
      placeholder,
      variant = 'form',
      error,
      disabled,
      maxLength,
      className,
    }: InputProps,
    ref: any,
  ) => {
    const filterClasses = useFilterStyles({})
    const formClasses = useFormStyles({})
    const classes: Record<any, string> = useMemo(
      () => (variant === 'filter' ? filterClasses : formClasses),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [variant],
    ) as Record<any, string>
    const [focused, setFocused] = useState(false)
    const handleInputChange = useCallback(
      e => {
        e.preventDefault()
        onChange(e.target.value)
      },
      [onChange],
    )
    return (
      <div className={cn(classes.wrapper, className)}>
        <div
          className={cn(classes.inputWrapper, {
            [classes.focused]: focused,
            [classes.filled]: !!value,
            [classes.withAdornment]: !!endAdornment,
            [classes.error]: !!error,
            [classes.disabled]: !!disabled,
          })}
          tabIndex={-1}
          ref={ref}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
        >
          {label && (
            <span
              className={cn(classes.label, {
                [classes.focused]: focused,
                [classes.filled]: !!value,
                [classes.withPlaceholder]: !!placeholder,
                [classes.error]: !!error,
              })}
            >
              <span>{label}</span>
            </span>
          )}
          <input
            type="text"
            className={cn(classes.input, { [classes.error]: !!error })}
            value={value}
            onChange={handleInputChange}
            onFocus={e => {
              setFocused(true)
              e.target.select()
            }}
            onBlur={() => setFocused(false)}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
          />
          {clearable && !disabled && value && (
            <div
              className={cn(classes.clearDiv, { [classes.withAdornment]: !!endAdornment })}
              onClick={() => onChange('')}
            >
              <CloseIcon className={classes.clearIcon} />
            </div>
          )}
          {endAdornment && !disabled && (
            <div className={cn(classes.adornment)}>
              {onAdornmentClick ? (
                <IconButton classes={{ root: classes.adornmentIconButton }} onClick={onAdornmentClick}>
                  {endAdornment}
                </IconButton>
              ) : (
                { endAdornment }
              )}
            </div>
          )}
        </div>

        {error && <span className={cn(classes.errorText)}>{error}</span>}
      </div>
    )
  },
)
export default StyledInput
