const GenerateButtonColors = (bgColor: string, textColor: string, borderColor: string) => ({
  bgColor,
  textColor,
  borderColor,
})

export const BUTTON_COLORS: any = {
  primary: {
    contained: {
      normal: GenerateButtonColors('#FFCD00', '#3A3D3D', 'transparent'),
      hover: GenerateButtonColors('#FFD839', '#3A3D3D', 'transparent'),
      focus: GenerateButtonColors('#EABC00', '#3A3D3D', 'transparent'),
    },
  },
  secondary: {
    contained: {
      normal: GenerateButtonColors('#5C8DE8', '#ffffff', 'transparent'),
      hover: GenerateButtonColors('#437DE9', '#ffffff', 'transparent'),
      focus: GenerateButtonColors('#386FD4', '#ffffff', 'transparent'),
    },
    outlined: {
      normal: GenerateButtonColors('transparent', '#5C8DE8', '#5C8DE8'),
      hover: GenerateButtonColors('transparent', '#749CE6', '#749CE6'),
      focus: GenerateButtonColors('transparent', '#4576D3', '#4576D3'),
    },
    text: {
      normal: GenerateButtonColors('transparent', '#5C8DE8', 'transparent'),
      hover: GenerateButtonColors('transparent', '#5C8DE8', 'transparent'),
      focus: GenerateButtonColors('transparent', '#4576D3', 'transparent'),
    },
  },
}