import { isString, get } from 'lodash';

const DEFAULT_ERROR_MESSAGE = 'An error has occurred.  Please try again later.';

export default function errorMessage(e) {
    if (isString(e)) {
        return e
    }
    // to some degree, throwing stuff at the wall here
    // I forgot where now, but we looked at error.event somewhere
    return (
      get(e, 'response.data.message') ||
      get(e, 'message.message') ||
      get(e, 'message') ||
      get(e, 'event') ||
      DEFAULT_ERROR_MESSAGE
    )
}
