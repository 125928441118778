import DOMPurify from 'dompurify'

export const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|".+")@((\[[0-9]{1,3}\.0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const phoneNumberRegex = /^([+]?)(1\s?)?[\s\-]?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/
export const passwordREGEX = /[^\w\d]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*)$)/

export const validate = {
  email: (email: string) => emailRegex.test(email),
  password: (pwd: string) => passwordREGEX.test(pwd) && pwd.length >= 8,
  phoneNumber: (phone: string) => phoneNumberRegex.test(phone.replace(/\s+/g, '')),
}

export const queryStringToObject = (rawQueryString: string) => {
  const arr = rawQueryString.replace('?', '').split('&')

  return arr.reduce(function (arr, item) {
    const params = item.split('=')
    arr[params[0]] = params[1]

    return arr
  }, {} as any)
}

export const getNotificationOptions = (variant: string) => ({
  variant,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
})

export const createMarkup = (html?: string) => {
  return { __html: html ? DOMPurify.sanitize(html) : '' }
}
