import * as _ from 'lodash';

export const USER = 'USER';
export const KIOSK = 'KIOSK';
export const ADMIN = 'ADMIN';
export const SUPER_ADMIN = 'SUPER_ADMIN';
export const INTERNAL_ADMIN = 'INTERNAL_ADMIN';
export const INTERNAL_SUPER_ADMIN = 'INTERNAL_SUPER_ADMIN';
export const DEVELOPER = 'DEVELOPER'

export const ROLE_TO_NAME = {
    [USER]: 'User',
    [KIOSK]: 'Kiosk',
    [ADMIN]: 'Admin',
    [SUPER_ADMIN]: 'Super Admin',
    [INTERNAL_ADMIN]: 'Internal Admin',
    [INTERNAL_SUPER_ADMIN]: 'Internal Super Admin',
    [DEVELOPER]: 'Developer',
}

export const humanize = (role) => _.isString(role) ? ROLE_TO_NAME[role] : null

const ROLE_RANKING = [USER, KIOSK, ADMIN, SUPER_ADMIN, INTERNAL_ADMIN, INTERNAL_SUPER_ADMIN, DEVELOPER];

export const isRole = (value) => ROLE_RANKING.includes(value);

export const rank = (role) => _.indexOf(ROLE_RANKING, _.toUpper(role));

const INTERNAL_ADMIN_RANK = rank(INTERNAL_ADMIN);

export const isRoleAtLeast = (permissions, minRole, organization=null) => {
    if(!permissions) return false;

    const { role, managedOrganization } = permissions;
    const userRank = rank(role);
    const minRank = rank(minRole);

    if(minRank >= INTERNAL_ADMIN_RANK || !organization) {
        return userRank >= minRank;
    } else {
        return (
            userRank >= INTERNAL_ADMIN_RANK ||
            ( 
                userRank >= minRank && 
                _.isObject(managedOrganization) &&
                Number(managedOrganization.id) === Number(organization.id)
            )
        ); 
    }
}

