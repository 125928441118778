import React from 'react'
import styled from 'styled-components'
import './styles.scss'
import useClickOutside from '../../../helpers/click-outside'
import IconComponent from '../icon'
import { PALETTE_COLORS } from '../../../constants/colors'

interface PopupProps {
  children?: React.ReactNode
  height?: string
  width?: string
  open?: any
  closePopup?: (value: boolean) => void
  clickOutside?: boolean
  radius?: string
  closeIcon?: boolean
}
const PopupInnerContainer = styled<any>('div')`
  width: ${(props: any) => props.width || '400px'};
  height: ${(props: any) => props.height || '300px'};
  border-radius: ${(props: any) => props.radius || '30px'};
`
/*
* Example
<Popup
    open={boolean}
    closePopup={() => void)}
    clickOutside={boolean}
    height='100px'
    width='100px'
 >
  popup
</Popup>
* */
const Popup = (props: PopupProps) => {
  const clickOutside = useClickOutside(() => {
    props.closePopup && props.closePopup(false)
    document.body.classList.remove('disable-scroll')
  })
  const refer = props.clickOutside ? clickOutside : null
  if (!props.open) {
    return null
  }

  props.open && document.body.classList.add('disable-scroll')

  const { width, height, children, radius, closeIcon = false } = props
  return (
    <div className="popup_wrapper">
      <PopupInnerContainer className="popup_inner" ref={refer} width={width} height={height} radius={radius}>
        <div className="popup_inner_content">
          {children}
          {closeIcon &&
          <div className="close-icon" onClick={() => {
            document.body.classList.remove('disable-scroll')
            props.closePopup && props.closePopup(false)
          }}>
            <IconComponent
              height={10}
              width={10}
              customColor={PALETTE_COLORS.textColors.default}
              name={'x_mark'}
            />
          </div>}
        </div>
      </PopupInnerContainer>
    </div>
  )
}
export default Popup
