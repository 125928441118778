import moment from 'moment'
import { string } from 'yup'

const formatPhoneNumberForDatabase = (value: any) => {
  if (!value) {
    // will cause issues if the field isn't nullable()
    return value
  }
  // stripping away all '+' and '-'
  const interimValue = value.replace(/\D/g, '')
  // ensuring +1 is always there
  const prefix = interimValue.length === 11 ? '+' : '+1'
  return prefix + interimValue
}

const PHONE_NUMBER_REGEX = /([+][1][2-9][0-9]{9})/

const emailValidationSchema = string()
  .required('This field is required.')
  .email('Please enter a valid email address.')

const validateEmail = (email: string) => emailValidationSchema.isValidSync(email)

const passwordValidationSchema = string()
  .required('This field is required.')
  .min(8, 'Password must be at least 8 characters.')
  .trim()

const validatePassword = (password: string) => passwordValidationSchema.isValidSync(password)

const validatePhoneNumber = (phone: string) => PHONE_NUMBER_REGEX.test(formatPhoneNumberForDatabase(phone))

const dateRegex = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/

const dateValidationSchema = string()
  .required('This field is required.')
  .test('date-validator', 'Invalid date.', value => {
    const current = new Date() >= new Date(value)
    const birthday = moment(value)
    return birthday.isValid() && dateRegex.test(value) && current
  })
  .test('age-test', 'Invalid date: only users older than 13 years old can create an account.', value => {
    const thirteenYearsAgo = moment().subtract(13, 'years')
    const birthday = moment(value)
    return thirteenYearsAgo.isAfter(birthday)
  })

const validateDate = (date: string | any) => {
  const thirteenYearsAgo = moment().subtract(13, 'years')
  const birthday = moment(date)
  const current = new Date() >= new Date(date)
  if (!birthday.isValid() || !dateRegex.test(date) || !current) {
    return 'Invalid date'
  } else if (thirteenYearsAgo.isAfter(birthday) && dateRegex.test(date)) {
    return ''
  } else {
    return 'Invalid date: only users older than 13 years old can create an account'
  }
}

const parseUsername = (username: string) => {
  if (/^[-()\d\s]+$/.test(username)) {
    return formatPhoneNumberForDatabase(username)
  }
  return username
}

const emailOrPhoneValidationSchema = string()
  .required('This field is required.')
  .test('email-or-phone', 'Please enter a valid email address or a 10 digit phone number.', value => {
    if (validateEmail(value)) {
      return true
    } else if (validatePhoneNumber(value)) {
      return true
    }

    return false
  })

export {
  validatePhoneNumber,
  validateEmail,
  validatePassword,
  emailValidationSchema,
  passwordValidationSchema,
  dateValidationSchema,
  parseUsername,
  formatPhoneNumberForDatabase,
  PHONE_NUMBER_REGEX,
  validateDate,
  emailOrPhoneValidationSchema,
}
