import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { withFormik, FormikProps, FormikValues, FormikBag } from 'formik'
import { object, string } from 'yup'
import Popup from '../../../../components/popup'
import IconComponent from '../../../../components/icon'
import { PALETTE_COLORS } from '../../../../../constants/colors'
import { Button } from '../../../../components/button'
import Input from '../../../../components/input'
import SocialLogin from '../../social-login'
import FeatureEnabled from '../../../features/FeatureEnabled'
import { parseUsername } from '../../../../../helpers/validation'
import './style.scss'

const signInValidationSchema = object().shape({
  emailOrPhone: string().required('This field is required.'),
  password: string().required('This field is required.'),
})

interface FormValues {
  emailOrPhone: string
  password: string
}

const initialFormValues: FormValues = {
  emailOrPhone: '',
  password: '',
}

interface PopupProps {
  onSignIn: () => void
  isOpen: boolean
  closePopup: (action: boolean) => void
  onSignUpClick: () => void
  onForgotPasswordClick: () => void
}

interface FormProps {
  onSignUpClick: () => void
  onForgotPasswordClick: () => void
  isOpen: boolean
  closePopup: (action: boolean) => void
}

const SignInPopupForm = (props: FormProps & FormikProps<FormValues>) => {
  const {
    closePopup,
    errors,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    isOpen,
    isSubmitting,
    onForgotPasswordClick,
    onSignUpClick,
    resetForm,
    touched,
    values,
  } = props

  const [security, setSecurity] = useState(false)

  return (
    <Popup
      width={'498px'}
      height={'727px'}
      radius={'4px'}
      closeIcon={true}
      open={isOpen}
      clickOutside={true}
      closePopup={() => {
        resetForm()
        closePopup(false)
      }}
    >
      <div className="sign-in-wrap">
        <IconComponent
          name={'logo'}
          width={176}
          height={60}
          customColor={PALETTE_COLORS.mainColors['primary']}
          className={'sign-in-wrap_icon'}
        />
        <form className="sign-in-wrap-input">
          <span className={'sign-in_text'}>Sign In</span>
          <Input
            style={{ marginBottom: 31 }}
            tag={'input'}
            placeholder={'Email or Phone'}
            onChange={handleChange}
            onBlur={handleBlur}
            name={'emailOrPhone'}
            type={'text'}
            value={values.emailOrPhone}
            hasError={!!errors.emailOrPhone}
            errorMessage={errors.emailOrPhone}
          />
          <div onKeyPress={e => e.key === 'Enter' && handleSubmit()}>
            <Input
              onBlur={handleBlur}
              hasError={errors.password && touched.password ? true : false}
              errorMessage={errors.password && touched.password ? errors.password : ''}
              style={{ marginBottom: 25 }}
              tag={'input'}
              placeholder={'Password'}
              onChange={handleChange}
              name={'password'}
              type={security ? 'text' : 'password'}
              value={values.password}
              children={
                <div onClick={() => setSecurity(!security)}>
                  <IconComponent
                    height={!security ? 15 : 23.3}
                    width={22}
                    style={{ marginTop: 6, cursor: 'pointer' }}
                    customColor={'#AFBCD5'}
                    name={!security ? 'open_eye' : 'closed_eye'}
                  />
                </div>
              }
            />
          </div>
          <span
            onClick={() => {
              resetForm()
              onForgotPasswordClick()
            }}
            className={'sign-in_forgot-pass'}
          >
            Forgot Password?
          </span>
          <Button
            className={'sign-in_button'}
            label={'Sign In'}
            type={'secondary'}
            style={{ background: isValid ? '#5C8DE8' : '#B1BED6' }}
            labelColor={'#FFFFFF'}
            labelStyle={{ fontSize: 16 }}
            disabled={!isValid}
            isBold={true}
            loading={isSubmitting}
            onClick={handleSubmit}
          />
          <FeatureEnabled code="SocialSignIn">
            <div className={'sign-in_line-wrap'}>
              <span className={'sign-in_line'} />
              <span className={'sign-in_line-text'}>or</span>
              <span className={'sign-in_line'} />
            </div>
            <SocialLogin />
          </FeatureEnabled>
          <span className={'sign-in_have-account'}>
            New to Civic Champs?{' '}
            <span
              className={'sign-in_terms-link'}
              onClick={() => {
                resetForm()
                onSignUpClick()
              }}
            >
              Create an account
            </span>
          </span>
        </form>
      </div>
    </Popup>
  )
}

const signIn = async (values: FormikValues, formikBag: FormikBag<PopupProps, FormValues>): Promise<void> => {
  const { emailOrPhone, password } = values
  const { props, setFieldError, setSubmitting } = formikBag
  const parsedUsername = parseUsername(emailOrPhone).toLowerCase()

  try {
    await Auth.signIn(parsedUsername, password)
    props.onSignIn()
  } catch (error) {
    if (error.code === 'NotAuthorizedException' || 'UserNotFoundException') {
      setFieldError('emailOrPhone', error.message)
      return
    }
  } finally {
    setSubmitting(false)
  }
}

const SignInPopup = withFormik<PopupProps, FormValues>({
  mapPropsToValues: () => initialFormValues,
  validationSchema: signInValidationSchema,
  handleSubmit: signIn,
})(SignInPopupForm)

export default SignInPopup
