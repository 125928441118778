import React from 'react'
import Modal from '@material-ui/core/Modal'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Stepper from './Stepper'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import cn from 'classnames'

export const useModalStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    height: '100%',
    overflowY: 'auto',
    '&.compact': {
      background: 'transparent',
    },
  },
  compact: {
    background: '#F8FAFF',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '20px',
  },
  maxWidthMd: {
    maxWidth: '700px',
  },
  maxWidthLg: {
    maxWidth: '1000px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '190px 1fr',
    gap: '0px 0px',
    gridAutoFlow: 'row',
  },
  button: {
    borderRadius: '100px',
    color: '#fff',
    textShadow: '1px 1px rgba(1,1,1,0.2)',
    marginBottom: '8px',
  },
  backButton: {
    borderRadius: '100px',
  },
  stepHeader: {
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '28px',
    lineHeight: '36px',
    color: '#000000',
    marginBottom: '15px',
  },
  stepHeaderHasSubheader: {
    lineHeight: '24px',
  },
  stepSubHeader: {
    fontSize: '1rem',
    lineHeight: '1rem',
  },
  buttonsContainer: {
    marginTop: '24px',
  },
  a: {
    textDecoration: 'none',
    color: '#5C8DE8',
    fontWeight: 600,
  },
  bold: {
    fontWeight: 600,
  },
  marginTop: {
    marginTop: '136px',
  },
})

interface StepModalContainerProps {
  showing: boolean
  cancel: () => void
  activeStep: number
  steps: string[]
  children: React.ReactNode
  getStepHeader?: (activeStep: number) => string
  getStepSubHeader?: (activeStep: number) => string | null
  disableEnforceFocus?: boolean
}

export function StepModalContainer({
  showing,
  cancel,
  activeStep,
  steps,
  children,
  disableEnforceFocus,
  getStepHeader = () => steps[activeStep],
  getStepSubHeader = () => null,
}: StepModalContainerProps) {
  const classes = useModalStyles()
  const subHeader = getStepSubHeader(activeStep)
  return (
    <Modal open={showing} disableEnforceFocus={disableEnforceFocus}>
      <Container classes={{ root: classes.container }} maxWidth={false}>
        <Container classes={{ maxWidthMd: classes.maxWidthMd }} maxWidth="md">
          <div className={classes.grid}>
            <IconButton aria-label="close" style={{ position: 'absolute', right: 0, top: 0 }} onClick={cancel}>
              <CloseIcon fontSize="large" />
            </IconButton>
            {steps.length > 1 && (
              <Grid container direction="row" alignItems="flex-end" justify="space-between">
                <Stepper activeStep={activeStep} steps={steps} />
              </Grid>
            )}
            <Box className={cn({ [classes.marginTop]: steps.length === 1 })}>
              <Typography className={cn(classes.stepHeader, { [classes.stepHeaderHasSubheader]: !!subHeader })}>
                <>
                  {getStepHeader(activeStep)}
                  {subHeader && (
                    <>
                      <br />
                      <span className={classes.stepSubHeader}>{subHeader}</span>
                    </>
                  )}
                </>
              </Typography>
              {children}
            </Box>
          </div>
        </Container>
      </Container>
    </Modal>
  )
}

export default StepModalContainer
