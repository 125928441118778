import React from 'react'
import './styles.scss'

interface Props {
  totalMembers: number | string
  allRoles: any
  opportunity_timeshift_id: number
  roleName: string
  timeShifts: any
}

export function WhoIsNeeded(props: Props) {
  const membersNeeded = props.totalMembers + ' ' + props.roleName
  const time = props.timeShifts.filter((item: any) => item.id === props.opportunity_timeshift_id)
  return (
    <div className="who_is_need_wrapper">
      <div className="who_is_need_icon" />
      <div className="who_is_need_members">
        {props.totalMembers > 0 ? (
          <div className="who_is_need_time_shifts">
            <div className="who_is_need_time_shifts_value">
              {`${time[0].time_start}
                ${time[0].time_end}`}
                </div>
            <span className="who_is_need_bold">{membersNeeded}</span> still needed
          </div>
        ) : (
          <div>
            No more <span className="who_is_need_bold">{props.roleName}</span> needed
          </div>
        )}
      </div>
    </div>
  )
}
