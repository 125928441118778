import { connect } from 'react-redux'
import VolunteerEventRegisterScene from '../../scenes/volunteer-event-register'
import {
  addRole,
  addShift,
  clear,
  removeShift,
  validate,
} from '../reducers/VolunteerEventRegister'

function MSTPVolunteerEventRegister(state: any) {
  return {
    data: state.VolunteerEventRegister,
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    addRole: (shiftId: number, roleId: number) => dispatch(addRole(shiftId, roleId)),
    addShift: (shift: any) => dispatch(addShift(shift)),
    removeShift: (shiftId: any) => dispatch(removeShift(shiftId)),
    validate: () => dispatch(validate()),
    clear: () => dispatch(clear()),
  }
}

export default connect(MSTPVolunteerEventRegister, mapDispatchToProps)(VolunteerEventRegisterScene)
