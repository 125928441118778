import React from 'react'
import { Auth } from 'aws-amplify'
import { withFormik, FormikProps, FormikValues, FormikBag } from 'formik'
import { object } from 'yup'
import Popup from '../../../components/popup/index'
import { Button } from '../../../components/button'
import { PALETTE_COLORS } from '../../../../constants/colors'
import IconComponent from '../../../components/icon'
import { emailOrPhoneValidationSchema, parseUsername } from '../../../../helpers/validation'
import Input from '../../../components/input'

import './style.scss'

const forgotPasswordValidationSchema = object().shape({
  username: emailOrPhoneValidationSchema,
})

interface FormValues {
  username: string
}

const initialFormValues = {
  username: '',
}

interface PopupProps {
  isOpen: boolean
  closePopup: (action: boolean) => void
  onSignInClick: () => void
  onForgotPassword: (username: string) => void
}

interface FormProps {
  isOpen: boolean
  closePopup: (action: boolean) => void
  onSignInClick: () => void
}

function ForgotPasswordForm(props: FormProps & FormikProps<FormValues>) {
  const {
    closePopup,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isOpen,
    isSubmitting,
    isValid,
    onSignInClick,
    resetForm,
    touched,
    values,
  } = props

  return (
    <Popup
      height="460px"
      width="500px"
      radius={'4px'}
      closeIcon={true}
      open={isOpen}
      clickOutside={true}
      closePopup={() => {
        resetForm()
        closePopup(false)
      }}
    >
      <form className="forgot-popup">
        <div className="forgot-popup__icon">
          <IconComponent name={'logo'} width={176} height={60} customColor={PALETTE_COLORS.mainColors.primary} />
        </div>
        <div className="forgot-popup__title" style={{ color: PALETTE_COLORS.textColors.default }}>
          Forgot Password?
        </div>
        <div className="forgot-popup__text" style={{ color: PALETTE_COLORS.textColors.default }}>
          Enter your username and we'll send you instructions <br />
          for resetting your password
        </div>
        <div className="forgot-popup__form">
          <Input
            tag={'input'}
            placeholder={'Username'}
            onBlur={handleBlur}
            onChange={handleChange}
            name={'username'}
            type={'text'}
            value={values.username}
            hasError={errors.username && touched.username ? true : false}
            errorMessage={errors.username && touched.username ? errors.username : ''}
          />
          <div className="forgot-popup__form__btn">
            <Button
              label={'Next'}
              type={'secondary'}
              height={'46px'}
              width={'auto'}
              fontSize={'16px'}
              labelColor={PALETTE_COLORS.textColors.white}
              onClick={handleSubmit}
              style={{ background: isValid ? '#5C8DE8' : '#B1BED6' }}
              disabled={!isValid}
              isBold={true}
              loading={isSubmitting}
            />
          </div>
        </div>
        <div className="forgot-popup__back" style={{ color: PALETTE_COLORS.textColors.default }}>
          Back to{' '}
          <span
            className="sign-in"
            style={{ color: PALETTE_COLORS.textColors.secondaryLighten }}
            onClick={onSignInClick}
          >
            Sign In
          </span>
        </div>
      </form>
    </Popup>
  )
}

const handleNext = async (values: FormikValues, formikBag: FormikBag<PopupProps, FormValues>): Promise<void> => {
  const { username } = values
  const { props, setFieldError, setSubmitting } = formikBag
  const parsedUsername = parseUsername(username).toLowerCase()

  try {
    await Auth.forgotPassword(parsedUsername)
    props.onForgotPassword(parsedUsername)
  } catch (error) {
    setFieldError('username', error.message)
  } finally {
    setSubmitting(false)
  }
}

const ForgotPassword = withFormik<PopupProps, FormValues>({
  mapPropsToValues: () => initialFormValues,
  validationSchema: forgotPasswordValidationSchema,
  handleSubmit: handleNext,
})(ForgotPasswordForm)

export default ForgotPassword
