import { every } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useSet } from 'react-use'
import { Button, Link, makeStyles } from '@material-ui/core'
import SubmitButton from '../../core/submit-button'
import DoneIcon from '@material-ui/icons/Done'
import cn from 'classnames'
import { CredentialContentType } from '../types'

import { useSignWaiver } from '../hooks'
import { createMarkup } from '../../core/utils/helpers'

const useStyles = makeStyles(() => ({
  modalBody: {
    display: 'flex',
    backgroundColor: 'white',
    maxWidth: '80vw',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    paddingBottom: '30px',
  },
  title: {
    fontSize: '21px',
  },
  smallTitle: {
    fontSize: '16px',
    marginTop: '40px',
    marginBottom: '20px',
  },
  text: {
    fontSize: '16px',
    marginBottom: '50px',
  },
  waiverTitle: {
    fontSize: '18px',
    textAlign: 'center',
    width: '100%',
    marginBottom: '20px',
  },
  readFull: {
    fontSize: '17px',
    paddingLeft: '4px',
  },
  waiverText: {
    fontSize: '16px',
  },
  modalButton: {
    width: '146px',
    height: '45px',
    background: '#5F8FE8',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: '30px',
  },
  disabledButton: {
    width: '146px',
    height: '45px',
    background: 'grey',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: '30px',
  },
  nextText: {
    color: 'white',
    fontSize: '15px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '460px',
    alignItems: 'center',
  },
}))

export function WaiversToSign(props) {
  const { complete, waivers, user } = props
  const classes = useStyles()
  const [selectedWaiver, setSelectedWaiver] = useState(null)
  const [signWaiver, { loading: signingWaiver }] = useSignWaiver()
  const [signedWaiverIds, { add, has }] = useSet()

  const isSigned = waiver => has(waiver.credential.id)

  const unsignedWaivers = useMemo(() => waivers.filter(waiver => !has(waiver.credential.id)), [waivers, has])
  const indexByWaiverId = useMemo(
    () =>
      waivers.reduce((acc, curr, index) => {
        acc[curr.credential.id] = index
        return acc
      }, {}),
    [waivers],
  )
  const waiver = unsignedWaivers[0]

  const handleSigning = waiver => async () => {
    try {
      const waiverId = waiver.credential.id
      const { associatedEntity } = waiver
      if (signingWaiver || has(waiverId)) return
      const signatureOptions = {
        personId: user.id,
        credentialId: waiverId,
        associatedEntity,
      }
      await signWaiver(signatureOptions)
      add(waiverId)
    } catch {}
  }

  useEffect(() => {
    if (every(waivers, waiver => signedWaiverIds.has(waiver.credential.id))) {
      complete()
    }
  }, [waivers, signedWaiverIds, complete])

  return (
    <div className={classes.modalBody}>
      <div className={classes.title}>
        <b>Waivers</b>
      </div>
      <div className={classes.smallTitle}>Hi {user.givenName},</div>
      <div className={classes.text}>
        It looks like you haven’t signed the {waivers.length} required waiver(s) for this event. Please review and
        accept the following waiver(s) before you continue.
      </div>
      {selectedWaiver && (
        <>
          <div dangerouslySetInnerHTML={createMarkup(selectedWaiver.version.content)} />
          <Button className={classes.modalButton} onClick={() => setSelectedWaiver(null)}>
            Return to signing screen
          </Button>
        </>
      )}
      {!selectedWaiver && waiver && (
        <div>
          <div className={classes.waiverTitle}>
            <b>{waiver.version.title}</b>{' '}
            <i className={classes.smallTitle}>
              Waiver {indexByWaiverId[waiver.credential.id] + 1} of {waivers.length}
            </i>
          </div>

          <div>
            <div className={cn(classes.waiverText)}>{waiver.version.waiverStatement} </div>
            {waiver.version.contentType === CredentialContentType.Url && (
              <div>
                <a target="_blank" rel="noopener noreferrer" className={classes.readFull} href={waiver.version.url}>
                  Read full waiver
                </a>
              </div>
            )}
            {waiver.version.contentType === CredentialContentType.Text && (
              <div>
                <Button
                  component={Link}
                  onClick={() => {
                    setSelectedWaiver(waiver)
                  }}
                >
                  Read full waiver
                </Button>
              </div>
            )}
          </div>
          <SubmitButton disabled={isSigned(waiver) || signingWaiver} onClick={handleSigning(waiver)} title="I AGREE">
            {isSigned(waiver) && <DoneIcon size={30} />}
          </SubmitButton>
        </div>
      )}
    </div>
  )
}

export default WaiversToSign
