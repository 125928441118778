// this is (awkwardly) translating a data array back into one time events, managing deps manually
/* eslint-disable-file react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useSnackbar } from 'notistack'

function LifeCycle({ value, onAdd, onRemove }) {
  useEffect(() => {
    onAdd(value)
    return () => {
      onRemove(value)
    }
  })

  return null
}

export function Notifications(props) {
  const { notifications = [], removeNotification } = props
  const [displayed, setDisplayed] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const addDisplayed = key => setDisplayed([...displayed, key])
  const removeDisplayed = key => setDisplayed(displayed.filter(someKey => someKey !== key))

  useLayoutEffect(() => {
    notifications.forEach(({ message, options }) => {
      if (displayed.includes(options.key)) return

      enqueueSnackbar(message, {
        ...options,
        action: key => {
          const close = () => closeSnackbar(key)
          return (
            <>
              <LifeCycle key={key} onAdd={addDisplayed} onRemove={removeDisplayed} />
              {options.action ? options.action(close) : null}
            </>
          )
        },
      })

      addDisplayed(options.key)
      if (removeNotification) {
        removeNotification(options.key)
      }
    })
  }, [notifications]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default Notifications
