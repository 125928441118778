import isUndefined from 'lodash/isUndefined'

const SET_CURRENT_USER = 'SET_CURRENT_USER'
const SET_OPEN_LOGIN = 'SET_OPEN_LOGIN'

const initialState = {
  currentUser: null,
  openLogin: false,
  redirectToRegisterAfterLogin: false,
}

export function setCurrentUser(user: any) {
  return { type: SET_CURRENT_USER, user }
}

export function setOpenLogin(isOpenLogin: boolean, redirectToRegisterAfterLogin?: boolean) {
  const action: any = { type: SET_OPEN_LOGIN, isOpenLogin }
  if (!isUndefined(redirectToRegisterAfterLogin)) {
    action.redirectToRegisterAfterLogin = redirectToRegisterAfterLogin
  } else if (isOpenLogin) {
    action.redirectToRegisterAfterLogin = false
  }
  return action
}

export default function volunteerEventDetailReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user,
      }
    case SET_OPEN_LOGIN:
      return {
        ...state,
        openLogin: action.isOpenLogin,
        ...(!isUndefined(action.redirectToRegisterAfterLogin)
          ? { redirectToRegisterAfterLogin: action.redirectToRegisterAfterLogin }
          : {}),
      }

    default:
      return state
  }
}
