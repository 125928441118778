import { makeStyles } from '@material-ui/styles'

export const useAdvancedAutocompleteStyles = makeStyles({
  autocompleteContainer: {
    width: '100%',
    height: 'auto',
  },
  optionsContainer: {
    maxWidth: '100%',
    position: 'fixed',
    zIndex: 1300,
    background: 'white',
    overflow: 'auto',
    borderRadius: '4px',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    minHeight: '40px',
  },
  options: {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#74777F',
      borderRadius: '4px',
      width: '8px',
    },
    scrollbarColor: '#74777F transparent',
    scrollbarWidth: 'thin',
    overflow: 'auto',
    '&$filterVariant $option': {
      fontSize: '13px',
    },
  },
  filterVariant: {},
  addNew: {
    height: '39px',
    padding: '4px 12px',
    color: '#74777F',
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '39px',
    letterSpacing: '0.5px',
    cursor: 'pointer',
    borderTop: '1px dashed #74777F',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    display: 'flex',
    alignItems: 'center',
  },
  option: {
    color: '#74777F',
    padding: '4px 12px',
    overflow: 'hidden',
    fontSize: '16px',
    wordBreak: 'break-word',
    fontFamily: 'Nunito',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    cursor: 'pointer',
    display: 'flex',
    gap: '4px',
    justifyContent: 'space-between',
    '&$noOptions': {
      cursor: 'default',
    },
    '&$focusedOption': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '&$disabledOption': {
      color: 'lightgrey',
    },
  },
  noOptions: {},
  focusedOption: {},
  disabledOption: {},
  optionLeft: {
    flexShrink: 0,
  },
  optionRight: {},
  group: {
    position: 'relative',
  },
  groupTitle: {
    position: 'sticky',
    top: 0,
    color: '#001B3F',
    padding: '4px 12px',
    overflow: 'hidden',
    fontSize: '16px',
    wordBreak: 'break-word',
    fontFamily: 'Nunito',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    background: 'white',
  },
  offScreenPlaceholder: {
    flexShrink: 0,
    width: '100%',
  },
})
