import { useCallback, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ModalContext from './ModalContext'
import useLazyRef from '../../utils/useLazyRef'
import ConfirmPrompt from './ConfirmPrompt'

export const usePrompt = component => {
  let context = useContext(ModalContext)
  if (!context) {
    throw new Error(`'usePrompt() hook requires a 'ModalPromptProvider' parent!'`)
  }

  let promptRef = useLazyRef(() => context.createPrompt(component))

  useEffect(() => {
    return () => {
      promptRef.current.close()
      promptRef.current = null
    }
  }, [promptRef])

  return promptRef.current
}

export const useShowPrompt = component => {
  const prompt = usePrompt(component)

  return useCallback(
    params => {
      return new Promise(resolve => {
        const unlisten = prompt.onComplete(result => {
          resolve(result)
        })
        prompt.onHide(() => unlisten())

        prompt.show(params)
      })
    },
    [prompt],
  )
}

//TODO should really be something that let's you provide a series of responses?
export const useConfirm = (promptComponent = ConfirmPrompt) => {
  const confirmPrompt = usePrompt(promptComponent)

  return useCallback(
    (prompt, options) => {
      const {
        type = 'confirm',
        title = 'Confirm',
        confirmText = 'Yes',
        rejectText = 'No',
        subText = '',
        placeholder = '',
        rows = '1',
        fullWidth = true,
        multiline = true,
        variant = 'outlined',
      } = options || {}

      return new Promise(resolve => {
        const unlisten = confirmPrompt.onComplete(confirmed => {
          resolve(confirmed)
        })
        confirmPrompt.onHide(() => unlisten())

        confirmPrompt.show({
          type,
          title,
          content: prompt,
          subText,
          confirmText,
          rejectText,
          placeholder,
          rows,
          fullWidth,
          multiline,
          variant,
        })
      })
    },
    [confirmPrompt],
  )
}

//TODO implemented for now because useConfirm not parameterized

export const usePromptStyles = makeStyles(theme => ({
  button: {
    borderColor: 'currentColor',
    textTransform: 'initial',
    fontWeight: 'bold',
    minWidth: 100,
  },
  titleContainer: {
    position: 'relative',
    '.confirmPrompt &': {
      color: '#ffffff',
      backgroundColor: '#8d8d8d',
    },
    '.infoPrompt &': {
      color: '#ffffff',
      backgroundColor: '#4444cc',
    },
    '.successPrompt &': {
      color: '#ffffff',
      backgroundColor: '#44cc44',
    },
    '.warningPrompt &': {
      color: '#ffffff',
      backgroundColor: '#FF6F3E',
    },
    '.errorPrompt &': {
      color: '#ffffff',
      backgroundColor: '#ff4264',
    },
  },
  title: {
    fontSize: '2rem;',
  },
  windowedTitleContainer: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '20px',
  },
  windowedContentContainer: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '20px',
    '& > h1:first-child, & > h2:first-child, & > h3:first-child, & > p:first-child, & > div:first-child': {
      marginTop: 0,
    },
    '&:not(:first-child)': {
      paddingTop: '8px',
    },
    '&:last-child': {
      paddingBottom: '20px',
    },
  },
  contentContainer: {
    flex: 'unset',
  },
  content: {
    fontSize: '1.25em',
  },
  subText: {
    marginTop: '2em',
    fontSize: '0.75em',
  },
  field: {
    marginBottom: '2em',
  },
  closeButton: {
    position: 'absolute',
    top: '0.65em',
    right: '1em',
  },
  uploadButton: {
    marginLeft: '25px',
    padding: '0px 20px',
    height: '40px',
    minHeight: 'auto',
    minWidth: 'auto',
    fontSize: '0.875rem',
    fontWeight: '500',
    textTransform: 'uppercase',
    '&:hover': {
      color: 'white',
      backgroundColor: '#5C8DE8',
    },
  },
  windowedActionContainer: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
  },
  actionContainer: {
    '.fullScreen &': {
      paddingRight: '2em',
    },
  },
  actionButton: {
    marginTop: '10px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '&:first-child': {
      'margin-left': '0',
    },
    '&:last-child': {
      'margin-right': '0',
    },
  },
  inputFieldOutlined: {
    marginTop: '10px',
    marginBottom: '10px',
    color: '#3d3d3d',
  },
  orangeCancel: {
    color: theme.palette.danger.main,
  },
  orangeButton: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.neutral.white,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
    },
  },
}))
