import cn from 'classnames'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button, { ButtonProps } from '@material-ui/core/Button/Button'

const useStyles = makeStyles({
  button: {
    color: '#0F5DB5',
    textTransform: 'none',
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    borderRadius: '100px',
    borderColor: '#EEF3F6',
    padding: '6px 10px 6px 16px',
  },
  buttonRightMargin: {
    marginRight: '8px',
  },
  icon: {
    color: '#0F5DB5',
    fontSize: '18px !important',
  },
  disabledIcon: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
})

export interface TableMenuButtonProps extends Omit<ButtonProps, 'variant'> {
  iconClassName?: string
  rightMargin?: boolean
}

export const TableMenuButton: React.FC<TableMenuButtonProps> = React.forwardRef(
  ({ children, className, disabled, rightMargin, ...props }, ref) => {
    const classes = useStyles()
    return (
      <Button
        ref={ref}
        variant="outlined"
        className={cn(classes.button, { [classes.buttonRightMargin]: rightMargin }, className)}
        disabled={disabled}
        classes={{
          ...props.classes,
          startIcon: cn(classes.icon, {
            [classes.disabledIcon]: disabled,
          }),
          endIcon: cn(classes.icon, {
            [classes.disabledIcon]: disabled,
          }),
        }}
        {...props}
      >
        {children}
      </Button>
    )
  },
)

export default TableMenuButton
