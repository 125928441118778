import React from 'react'
import './style.scss'

export const weekDayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export default function CalendarHeader() {
  return (
    <div className="calendar_header-container">
      {weekDayNames.map((dayName, i) => (
        <span key={i} className="calendar_header-week_day_name">
          {dayName}
        </span>
      ))}
    </div>
  )
}
