import React, { useEffect } from 'react'
import { connect, FormikProps } from 'formik'

export interface FormikEffectProps {
  formik: FormikProps<any>
  onChange?: (formikState: FormikProps<any>) => void
}

export const FormikEffect = (props: FormikEffectProps) => {
  const { formik, onChange } = props
  const { values, errors } = formik
  useEffect(() => {
    if (onChange) {
      onChange(formik)
    }
  }, [values, errors])
  return null
}

export default connect(FormikEffect);
