import React from 'react'
import { Moment } from 'moment'

import Calendar from '../../kit/modules/events/calendar'
import { viewTypes } from './viewTypes'

interface Props {
  date: Moment
  events: any
  organization: any
  onDateChange: (value: Moment) => void
}

export const WeekView = (props: Props) => {
  return (
    <div className="event_list_wrapper">
      <div className="event_list_block">
        <div className="event_list_block_content">
          <div className="event_list_block_content_items">
            <Calendar
              viewType={viewTypes.WEEK}
              date={props.date.toDate()}
              events={props.events}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
