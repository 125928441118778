import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '10px',
      marginBottom: '10px',
      color: '#3d3d3d',
      '& label.MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled)': {
        transform: 'translate(8px, 14px) scale(1)',
      },
      '& label.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '& label.MuiFormLabel-filled': {
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '& .MuiOutlinedInput-root': {
        fontSize: '16px',
        lineHeight: '20px',
        minHeight: '45px',
        '& input.MuiOutlinedInput-input': {
          padding: '14px 8px',
        },
        '& fieldset': {
          borderColor: 'rgb(175, 188, 213)',
        },
        '&:hover fieldset': {
          borderColor: 'rgb(175, 188, 213)',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.87)',
          border: '1px solid',
        },
      },
    },
  }),
)

export default function StyledOutlinedTextField(props: any) {
  const classes = useStyles()

  return <TextField {...props} classes={{ root: classes.root }} variant="outlined" />
}
