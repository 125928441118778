import { useCallback } from 'react'
import { Status } from '../../../api/hooks/fetchRefactoredSchema'
import useFetchRefactored from '../../../api/hooks/useFetchRefactored'
import { useCurrentOrg, useHasRole } from '../../../auth/hooks'
import { Person } from '../../../question-sets/types'
import { INTERNAL_SUPER_ADMIN } from '../../../auth/utils/permissions'

export enum ContactType {
  EMAIL = 'email',
  PHONE = 'phone',
}

export enum PersonContactType {
  EMAIL = 'email',
  MOBILE_PHONE = 'mobile-phone',
  HOME_PHONE = 'home-phone',
  WORK_PHONE = 'work-phone',
}

export interface AddContactPayload {
  contactType?: ContactType
  personContactType: PersonContactType
  confirmed?: boolean
  email?: string
  phoneNumber?: string
}
const useAddPersonContact = (): [(id: number, payload: AddContactPayload) => Promise<Person>, Status<Person>] => {
  const isInternalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const currentOrg = useCurrentOrg()
  const [request, status] = useFetchRefactored<Person>({
    successMessage: 'Successfully added contact',
    errorMessage: 'Error creating contact',
  })

  const addPersonContact = useCallback(
    (personId: number, payload: AddContactPayload) => {
      return request({
        method: 'post',
        url: isInternalSuperAdmin
          ? `/persons/${personId}/person-contacts`
          : `/organizations/${currentOrg.id}/persons/${personId}/person-contacts`,
        config: {
          body: payload,
        },
      })
    },
    [currentOrg.id, isInternalSuperAdmin, request],
  )

  return [addPersonContact, status]
}

export default useAddPersonContact
