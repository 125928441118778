import { useEffect, useCallback } from 'react'

const SCRIPT_ID = 'gtm_script'
const { REACT_APP_GTM_MEASUREMENT_ID, REACT_APP_ENV } = process.env
const GTM_SCRIPT_URL = `https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GTM_MEASUREMENT_ID}`

export const useGoogleAnalytics = () => {
  // Initialization
  useEffect(() => {
    if (!REACT_APP_GTM_MEASUREMENT_ID || document.getElementById(SCRIPT_ID)) {
      return
    }

    const script = document.createElement('script')
    script.src = GTM_SCRIPT_URL
    script.id = SCRIPT_ID
    script.async = true
    document.head.appendChild(script)

    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    window.gtag = gtag

    gtag('js', new Date())
    gtag('config', REACT_APP_GTM_MEASUREMENT_ID, { debug_mode: REACT_APP_ENV === 'development' })
  }, [])

  const track = useCallback((event, data) => {
    window.gtag('event', event, data)
  }, [])

  return [track]
}

export default useGoogleAnalytics
