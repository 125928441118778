import React from 'react'

import { Button, makeStyles } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

const useStyles = makeStyles(theme => ({
  cancelButton: {
    // @ts-ignore
    backgroundColor: theme.palette.danger.main,
    // @ts-ignore
    color: theme.palette.neutral.white,
    '&:hover': {
      // @ts-ignore
      backgroundColor: theme.palette.danger.dark,
    },
  },
  icon: {
    marginRight: '5px',
  },
}))

type Props = {
  onClick: () => void
  disabled: boolean
}

export const RemoveButton = ({ onClick, disabled }: Props): JSX.Element => {
  const classes = useStyles()
  return (
    <Button variant="contained" onClick={onClick} className={classes.cancelButton} disabled={disabled}>
      <HighlightOffIcon className={classes.icon} />
      Remove
    </Button>
  )
}

export default RemoveButton
