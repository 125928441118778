import React from 'react'
import { TextField } from '@material-ui/core'
import { fieldToTextField } from 'formik-material-ui'

export function InputTextField(props) {
  return (
    <TextField
      {...fieldToTextField(props)}
      classes={{ root: 'custom-input-component' }}
      variant="outlined"
      fullWidth
      size="small"
    />
  )
}

export default InputTextField
