import {isString, get} from 'lodash';

const DEFAULT_ERROR_MESSAGE = 'An error has occurred.  Please try again later.';

export default function errorMessage(e) {
    if( isString(e) ) return e;
    return get(e, 'response.data.message', e.message || DEFAULT_ERROR_MESSAGE)
}

export const getFormikFieldError = (fieldName, errors, touched) => {
    return errors[fieldName] && touched[fieldName]
        ? errors[fieldName]
        : undefined
}
