import * as React from 'react'
import { FormikErrors } from 'formik'
import { Question } from '../../../../types'

import '../question-styles.scss'

interface QuestionSkeletonProps {
  questionTitle: string
  children: any
  error?: FormikErrors<Question>
  isRequired?: boolean
}

const QuestionSkeleton = (props: QuestionSkeletonProps) => {
  const {questionTitle, error, children, isRequired } = props

  return (
    <div className="feedback-form-block-question-list-item">
      <div className="feedback-form-block-question-list-item__question">
        {questionTitle}
        {isRequired ? '*' : ''}
      </div>
      <div className="feedback-form-block-question-list-item__value">{children}</div>
      {typeof error?.response === 'string' && <div className="feedback-error-message">{error.response}</div>}
    </div>
  )
}

export default QuestionSkeleton

