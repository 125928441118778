import { connect } from 'react-redux'
import VolunteersMyEventsListScene from '../../scenes/volunteers-my-events'
import { setOpenLogin } from '../reducers/MyEvents'

function MSTPMyEvents(state: any) {
  return {
    data: state.VolunteerMyEvents,
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    setOpenLogin: (isOpenLogin: boolean) => dispatch(setOpenLogin(isOpenLogin)),
  }
}

export default connect(MSTPMyEvents, mapDispatchToProps)(VolunteersMyEventsListScene)
