import React from 'react'
import './style.scss'
import DayPicker from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'

/* * Examples
export default function PickRangeModule() {
  const [range, setRange] = useState<any>({from: null, to: null})

  return (
      <div>
       <DatePickerComponent onChange={(e: any) => setRange(e)} range={range} selectRange={true}/>
    </div>
  )
}

export default function PickOneDate() {
  const [value, setValue] = useState()

  return (
      <div>
      <DatePickerComponent onChange={(e: any) => setValue(e)} date={value}/>
    </div>
  )
}

export default function InputModule() {
  const [value, setValue] = useState()

  return (
      <div>
      <DatePickerComponent onChange={(e: any) => setValue(e)} date={value} input={true}/>
    </div>
  )
}
* */

interface Range {
  from: Date | null
  to: Date | null
}

interface Props {
  onChange: (e: Range | Date) => void
  range?: Range
  date?: Date
  input?: boolean
  selectRange?: boolean
}

export function DatePickerComponent(props: Props) {
  const { range, onChange } = props

  const { from, to } = range ? range : {
    from: null,
    to: null,
  }
  const modifiers = {
    start: from || undefined,
    end: to || undefined,
  }

  const handleChange = props.selectRange && range ? (date: Date) => {
    if (range.to && range.to.getTime() < range.from!.getTime() && range.to.getTime() === date.getTime()) {
      return onChange({
        from: null,
        to: null,
      })
    }

    if (range.from && date.getTime() === range.from.getTime()) {
      return onChange({
        from: null,
        to: null,
      })
    }

    if (range.to && date.getTime() === range.to.getTime()) {
      return onChange({
        from: null,
        to: null,
      })
    }

    if (!range.from) {
      return onChange({
        ...range,
        from: date,
      })
    }

    return onChange({
      ...range,
      to: date,
    })
  } : (date: Date) => {
    onChange(date)
  }

  return (
    <div className="date_picker">

      {props.input ? (
        <DayPickerInput placeholder="Date" onDayChange={(date: Date) => handleChange(date)} value={undefined}/>
      ) : (
        <DayPicker
          selectedDays={props.selectRange ? range as any : props.date}
          onDayClick={(date: Date) => handleChange(date)}
          modifiers={modifiers}
          disabledDays={from !== null ? (date) => date < from : undefined}
        />
      )}
    </div>
  )
}
