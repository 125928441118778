import { useFetch } from '../../api/hooks'
import { useCallback } from 'react'
import { useErrorNotification } from '../../api/hooks'
import { useState } from 'react'

export interface Metadata {
  personId?: number
  organizationId?: number
  donationCampaignId?: number
  groupId?: number
  volunteerTaskId?: number
  encodedOccurrence?: number
  opportunityId?: number
  viewerId?: number
  userCredentialId?: number
  documentTypeId?: number
}

export default function useFileManagementUpload(orgId: number) {
  const [apiServerFetch, status] = useFetch()
  const [loading, setLoading] = useState<boolean>(false)
  const showError = useErrorNotification()
  const start = useCallback(
    (contentType?: string) => {
      // @ts-ignore
      return apiServerFetch({
        method: 'post',
        url: `/organizations/${orgId}/files/generate_url`,
        config: {
          body: {
            contentType,
            isPrivate: true,
          },
        },
      })
    },
    [apiServerFetch, orgId],
  )

  const done = useCallback(
    (
      filename: string,
      contentType: string,
      url: string,
      uploadedFor?: string,
      tags?: string[],
      metadata?: Metadata,
    ) => {
      // @ts-ignore
      return apiServerFetch({
        method: 'post',
        url: `/organizations/${orgId}/files`,
        config: {
          body: {
            filename,
            contentType,
            url,
            uploadedFor,
            tags,
            isPrivate: true,
            metadata,
          },
        },
      })
    },
    [apiServerFetch, orgId],
  )

  const getSignedUrl = useCallback(
    (token: string) => {
      // @ts-ignore
      return apiServerFetch({
        method: 'get',
        url: `/files/${token}/download-url`,
      })
    },
    [apiServerFetch],
  )

  const uploadFile = useCallback(
    async (file: any, uploadedFor: string, metadata?: Metadata) => {
      setLoading(true)
      try {
        const { uploadUrl, downloadUrl } = await start(file.type)
        // @ts-ignore
        await fetch(uploadUrl, {
          method: 'put',
          body: file,
        })
        const result = await done(file.name, file.type, downloadUrl, uploadedFor, undefined, metadata)
        const signedUrl = await getSignedUrl(result.token)
        return {
          ...result,
          ...signedUrl,
        }
      } catch (err) {
        showError('Error uploading your file')
      } finally {
        setLoading(false)
      }
    },
    [start, done, fetch],
  )

  return { status: { ...status, loading }, done, start, uploadFile }
}
