import BaseService from '../services/base.service'

const baseService = new BaseService()
const MAIN_API = `${baseService.getEnvVar('API_ENDPOINT')}/`
const orgId = localStorage.getItem('orgId') || ''
const queryString = (params: { [key: string]: any }) =>
  Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&')

const ENDPOINT_ROUTES = {
  Events: {
    // note: we should refactor to use 'queryStringParams'
    getOccurrenceRefactorEvents: (orgId: number | string, query?: string) =>
      MAIN_API + `public/organizations/${orgId}/opportunity_instances` + (query ? `/?${query}` : ''),
    getOccurrence: (encodedOccurrence: string, params?: { token?: string, includeMissingRequiredMemberships?: boolean }) =>
      MAIN_API + `public/opportunity_instances/${encodedOccurrence}?${queryString(params || {})}`,
    getRolesOccurrenceRefactor: (orgId: number | string) => MAIN_API + `organizations/${orgId}/roles`,
    getMyEventsOccurrenceRefactor: (params?: { [key: string]: string | number }) =>
      MAIN_API + `user_registrations?${queryString(params || {})}`,
    // TODO: is this right ?
    getMyEventById: (eventId: number) => MAIN_API + `registrations/${eventId}`,
    getMyEventByIdOccurrenceRefactor: (registrationId: number) => MAIN_API + `registrations/${registrationId}`,
  },
  EventRegistration: {
    registerOccurrenceRefactor: (encodedOccurrence: string) =>
      MAIN_API + `opportunity_instances/${encodedOccurrence}/registration`,
    updateRegistration: (eventId: number) => MAIN_API + `registration/${eventId}`,
    cancel: (eventId: number) => MAIN_API + `registration/${eventId}`,
  },
  Features: {
    getFeaturesEnabled: (id: number | string) => MAIN_API + `organizations/${id}/features`,
  },
  User: {
    verificate: (orgId: number) => MAIN_API + `organizations/${orgId}/volunteers/sign-up`,
  },
  Meetings: {
    get: (params: string) => MAIN_API + `organizations/${orgId}/meetings?${params}`,
  },
  Matches: {
    matches: (params?: { limit?: number; offset?: number }) => `${MAIN_API}matches?${queryString(params || {})}`,
    match: (id: number) => `${MAIN_API}matches/${id}`,
    archive: `${MAIN_API}matches/archive`,
    uploadFile: `${MAIN_API}matches/upload`,
    confirmMatches: `${MAIN_API}matches/confirm`,
  },
  Company: {
    createNewCompany: MAIN_API + `organizations/${orgId}/company`,
  },
  Organization: {
    getOrganization: (orgId: number | string) => MAIN_API + `organizations/${orgId}`,
  },
}

export default ENDPOINT_ROUTES
