import React from 'react'
import { Moment } from 'moment'
import { pick } from 'lodash'
import { Box, Typography, makeStyles } from '@material-ui/core'

import { EventCard } from '../../kit/modules/events/event-card'
import { ROUTE_NAMES } from '../../constants/route-names'
import { encodeOccurrence } from '../../helpers/encodeOccurrence'

interface Props {
  date: Moment
  events: any
  organization: any
  onDateChange: (value: Moment) => void
}

const useStyles = makeStyles(() => ({
  noEventsLabel: {
    fontFamily: 'Magallanes, arial, sans-serif',
    fontWeight: 'bold',
    fontSize: 24,
  },
}))

export const DayView = (props: Props) => {
  const classes = useStyles()

  return (
    <div className="event_list_wrapper">
      <div className="event_list_block">
        <div className="event_list_block_content">
          <div className="event_list_block_content_items">
            {!props.events.length &&
            <Box textAlign="center" mt={3}>
              <Typography className={classes.noEventsLabel}>No Events Today</Typography>
            </Box>}
            {props.events.map((event: any, index: number) => {
              const address = pick(event, ['address', 'city', 'state', 'zip'])

              const url = ROUTE_NAMES.occurrenceDetails(
                props.organization.id,
                encodeOccurrence(event.opportunityId, event.startsAt, event.organization.timeZone),
              )

              return (
                <div className="event_list_block_content_items" key={index}>
                  <EventCard
                    address={address}
                    dateEnd={event.endsAt}
                    dateStart={event.startsAt}
                    description={event.description}
                    eventId={event.id}
                    orgId={props.organization.id}
                    subtitle={event.organization.name}
                    timeShifts={event.timeshifts}
                    timeZone={event.organization.timeZone}
                    title={event.name}
                    link={url}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
