import * as React from 'react'

import QuestionSkeleton from '../question-skeleton'
import { QuestionFormBaseProps } from '../../../../types'

import { ContactForm } from '../../base/contact-form'
import { ContactFormProps } from '../../base/contact-form/types'

export type ContactQuestionProps = QuestionFormBaseProps & {
  fieldName: string
}

const ContactQuestion = (props: ContactQuestionProps) => {
  const { item, error, fieldName, readonly } = props

  if (item.disabled) {
    return null
  }

  return (
    <QuestionSkeleton questionTitle={item.question} error={error} isRequired={item.isRequired}>
      <ContactForm
        fieldName={fieldName}
        errors={error?.response as ContactFormProps['errors']}
        isRequired={item.isRequired}
        readonly={readonly}
      />
    </QuestionSkeleton>
  )
}

export default ContactQuestion
