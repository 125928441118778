import React from 'react'
import './style.scss'
import { roundMetersTo5Feet } from '../../helpers/toRoundMetersTo5Feet'
import { Slider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  radiusSlider: {
    display: 'flex',
  },
  radiusSliderTitle: {
    margin: 0,
    padding: 0,
    fontSize: '16px',
    fontFamily: 'Open Sans, sans-serif',
    color: '#000',
    fontWeight: 'bold',
    inlineHeight: '42px',
    minWidth: '150px',
  },
})

interface Props {
  onChange: (e: number) => void
  radius: number
  min: number
  max: number
}

export default function GeofenceRadiusSlider(props: Props) {
  const { onChange, radius, min, max } = props
  const classes = useStyles()

  const handleChangeRadius = (e?: any, newRadius?: any) => {
    onChange(newRadius)
  }

  return (
    <>
      <div className="geofence-radius"></div>
      <div className={classes.radiusSlider}>
        <p className={classes.radiusSliderTitle}>Geofence Radius</p>
        <Slider aria-label="Radius" min={min} max={max} value={radius} onChange={handleChangeRadius} />
      </div>
      <p className="location-map__radius-info">
        approx. &nbsp;
        {roundMetersTo5Feet(radius)}ft
      </p>
    </>
  )
}
