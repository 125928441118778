import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import CalendarEvent from '../event'
import './style.scss'
import { PALETTE_COLORS } from '../../../../constants/colors'
import moment from 'moment'
import { weekDayNames } from '../header'

export interface Props {
  weekDay?: string
  isBeforeCurrentDate?: boolean
  hidden?: boolean
  isCurrentMonth?: boolean
  dayNumber?: number
  isToday?: boolean
  events?: any // TODO select type for event
  isMyEvent?: boolean
}

interface ICalendarDayContainer {
  isToday?: boolean
  isEmptyCell?: boolean
  isBeforeCurrentDate?: boolean
}

const CalendarDayContainer = styled<any>('div')`
  ${(props: ICalendarDayContainer) =>
  props.isToday &&
  `box-shadow: inset 0px 0px 0px 3px ${PALETTE_COLORS.mainColors.primary};
      
      .calendar_cell-day_number {
        color: ${PALETTE_COLORS.mainColors.primary};
      }
  `}
`
const DayNumber = styled<any>('span')`
  color: ${(props: { isBeforeCurrentDate?: boolean }) =>
  props.isBeforeCurrentDate ? '#999999' : PALETTE_COLORS.textColors.default};
`
const WeekDay = styled<any>('span')`
  color: ${(props: { isBeforeCurrentDate?: boolean }) =>
  props.isBeforeCurrentDate ? '#999999' : PALETTE_COLORS.textColors.default};
`

function CalendarDay(props: Props) {
  const classes = classNames('calendar_cell-container', {
    'calendar_cell--other': !props.isCurrentMonth,
    'calendar_cell--hidden': props.hidden,
  })

  return (
    <CalendarDayContainer
      className={classes}
      isToday={props.isToday}
    >
      <div className="calendar_cell-day_info">
        <DayNumber className="calendar_cell-day_number" isBeforeCurrentDate={props.isBeforeCurrentDate}>
          {props.dayNumber}
        </DayNumber>
        <WeekDay className="calendar_cell_weekday" isBeforeCurrentDate={props.isBeforeCurrentDate}>
          {props.weekDay ? weekDayNames[Number(props.weekDay)] : ''}
        </WeekDay>
      </div>
      {props.events.map((event: any) => (
        <CalendarEvent
          key={event.id}
          id={event.id}
          title={props.isMyEvent ? event.opportunity.name : event.name}
          shift={props.isMyEvent ? event.opportunity : event}
          isBeforeCurrentDate={props.isBeforeCurrentDate}
          isActive={props.isMyEvent ? moment(event.opportunity.endsAt).isAfter(moment()) : moment(event.endsAt).isAfter(moment())}
          event={event}
          isMyEvent={props.isMyEvent}
        />
      ))}
    </CalendarDayContainer>
  )
}

export default CalendarDay
