import Amplify from 'aws-amplify'
import BaseService from '../services/base.service'
import { getAuthorizationToken } from './get-authorization-token'

const baseService = new BaseService()

Amplify.configure({
  Auth: {
    region: baseService.getEnvVar('AWS_REGION'),
    userPoolId: baseService.getEnvVar('COGNITO_USER_POOL_ID'),
    userPoolWebClientId: baseService.getEnvVar('COGNITO_WEB_CLIENT_ID'),
    mandatorySignIn: true,
    oauth: {
      domain: baseService.getEnvVar('COGNITO_DOMAIN'),
      scope: ['phone', 'email', 'profile', 'openid'],
      redirectSignIn: baseService.getEnvVar('URL'),
      redirectSignOut: baseService.getEnvVar('URL'),
      responseType: 'code'
    }
  },
  API: {
    endpoints: [
      {
        name: 'civicChampsApi',
        endpoint: baseService.getEnvVar('API_ENDPOINT'),
        custom_header: async () => ({ Authorization: await getAuthorizationToken() })
      },
    ],
  },
})
