import React from 'react'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepperComponent, { StepperProps } from '@material-ui/core/Stepper/Stepper'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import StepConnector from '@material-ui/core/StepConnector'
import { StepIconProps } from '@material-ui/core/StepIcon/StepIcon'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import { ReactComponent as Ellipse } from '../public/assets/icons/ellipse.svg'
import { ReactComponent as EllipseSmall } from '../public/assets/icons/ellipse-small.svg'
import { ReactComponent as StepperUnchecked } from '../public/assets/icons/stepper-unchecked.svg'
import { useBreakPoint } from '../helpers/useBreakpoint'

export const Connector = withStyles(theme =>
  createStyles({
    alternativeLabel: {
      top: 16,
      left: 'calc(-50% + 15px)',
      right: 'calc(50% + 14px)',
      [theme.breakpoints.down(600)]: {
        top: 6,
        left: 'calc(-50% + 8px)',
        right: 'calc(50% + 8px)',
      },
    },
    active: {
      '& $line': {
        borderColor: '#C4C7C7',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#0F5DB5',
      },
    },
    line: {
      borderColor: '#C4C7C7',
      borderTopWidth: 3,
      borderRadius: 1,
      [theme.breakpoints.down(600)]: {
        borderTopWidth: 2,
      },
    },
  }),
)(StepConnector)

const useStyles = makeStyles(theme =>
  createStyles({
    stepper: {
      width: '100%',
      padding: 0,
      [theme.breakpoints.down(600)]: {
        maxWidth: '450px',
      },
    },
    stepLabel: {
      '&.MuiStepLabel-label': {
        fontFamily: 'Nunito, sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        marginTop: '8px',
        [theme.breakpoints.down(600)]: {
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: '0.4px',
        },
      },
      '&.MuiStepLabel-active': {
        color: '#0F5DB5',
      },
      '&.MuiStepLabel-completed': {
        color: '#0F5DB5',
      },
    },
    stepLabelDisabled: {
      color: '#747778',
    },
    stepLabelRoot: {
      marginBottom: '40px',
      [theme.breakpoints.down(600)]: {
        marginBottom: '0',
      },
    },
    step: {
      width: 0,
    },
  }),
)

const CheckIcon = (props: StepIconProps): JSX.Element => {
  const isSmall = useBreakPoint(600)
  return props.completed ? (
    <CheckCircleIcon style={{ fontSize: isSmall ? 16 : 32, color: '#0F5DB5', marginTop: isSmall ? '-1px' : 0 }} />
  ) : props.active ? (
    isSmall ? (
      <StepperUnchecked />
    ) : (
      <RadioButtonUncheckedIcon style={{ fontSize: 32, color: '#0F5DB5' }} />
    )
  ) : isSmall ? (
    <EllipseSmall />
  ) : (
    <Ellipse style={{ margin: '3px 0', color: '#C4C7C7' }} />
  )
}

export interface ExtendedStepperProps extends Omit<StepperProps, 'children'> {
  steps: string[]
}

export default function Stepper({ steps, ...props }: ExtendedStepperProps) {
  const classes = useStyles()
  return (
    <StepperComponent {...props} classes={{ root: classes.stepper }} alternativeLabel connector={<Connector />}>
      {steps.map(label => (
        <Step key={label} className={classes.step}>
          <StepLabel
            StepIconComponent={CheckIcon}
            classes={{
              root: classes.stepLabelRoot,
              alternativeLabel: classes.stepLabel,
              disabled: classes.stepLabelDisabled,
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </StepperComponent>
  )
}
