import * as React from 'react'
import { useState } from 'react'
import { Loader } from '../../kit/components/loader'
import { useRouteMatch } from 'react-router'
import { Auth, Hub } from 'aws-amplify'
import { useMount } from 'react-use'
import { HubRedirectUrlData } from '../../utils/hub-utils'

interface LoginWithTokenParams {
  organizationId: string
  token: string
}

export default function LoginWithToken(props: any) {
  const [loading, setLoading] = useState<boolean>(true)

  const { params } = useRouteMatch<LoginWithTokenParams>()
  const { organizationId, token } = params

  useMount(async () => {
    if (token && organizationId) {
      localStorage.setItem('schedulerToken', token)
      await Auth.signOut()
      const data = new HubRedirectUrlData(`/organizations/${organizationId}/opportunities`)
      Hub.dispatch('auth', { event: 'signIn', data })

      setLoading(false)
    } else {
      setLoading(false)
    }
  })

  if (loading) {
    return <Loader />
  }

  return <div>There was an error parsing your token. Please return to the app and try again</div>
}
