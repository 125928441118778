import React, { useMemo, useState, useEffect } from 'react'
import Loading from '../../core/Loading'
import { Prerequisites, ViewAnswerSetsContainerProps } from '../types'
import * as _ from 'lodash'
import { Answers } from './index'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { useFetchRequirements } from '../hooks'

const useStyle = makeStyles(() => ({
  container: {
    border: '1px solid black',

  },
  scroll: {
    height: '200px',
    overflowY: 'auto',
  }
}))

const useCurrentPrevNextAnswerSet = (requirements: Prerequisites, selectedAnswerSetIndex: number) =>
  useMemo(() => {
    const { questionSets } = requirements
    const answerSet = _.get(questionSets, selectedAnswerSetIndex)
    const previousQuestionSet = _.get(questionSets, selectedAnswerSetIndex - 1)
    const nextQuestionSet = _.get(questionSets, selectedAnswerSetIndex + 1)
    return { answerSet, previousQuestionSet, nextQuestionSet }
  }, [requirements, selectedAnswerSetIndex])

export const ViewAnswerSetsContainer = (props: ViewAnswerSetsContainerProps) => {
  const { filters } = props
  const classes = useStyle()
  const [selectedAnswerSetIndex, setSelectedAnswerSetIndex] = useState(0)
  const [fetchRequirements, { result: requirements, loading, called }] = useFetchRequirements()

  useEffect(() => {
    if(!!filters) fetchRequirements(filters)
  }, [filters, fetchRequirements])

  const { answerSet, previousQuestionSet, nextQuestionSet } = useCurrentPrevNextAnswerSet(
    requirements,
    selectedAnswerSetIndex,
  )

  if (loading || !called) {
    return <Loading />
  }

  if (answerSet) {
    return (
      <div className={classes.container}>
        <h3>
          Answers to Questionnaire '{answerSet.name}'
        </h3>
        <p><i>To register for '{answerSet.associatedEntity.name}'</i></p>
        <Grid container direction="row" className={classes.scroll}>
          <Grid item xs={2}>
          </Grid>
          <Grid item xs={8}>
            <Answers answers={answerSet.questions} showQuestionLabelEvenIfNotCompleted={true} />
          </Grid>
          <Grid item xs={2}>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={2}>
            {previousQuestionSet && (
              <Button
                variant="text"
                color="secondary"
                disabled={false}
                onClick={() => setSelectedAnswerSetIndex(selectedAnswerSetIndex - 1)}
              >
                Previous
              </Button>
            )}
          </Grid>
          <Grid item xs={8}>
          </Grid>
          <Grid item xs={2}>
            {nextQuestionSet && (
              <Button
                variant="text"
                color="secondary"
                disabled={false}
                onClick={() => setSelectedAnswerSetIndex(selectedAnswerSetIndex + 1)}
              >
                Next
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }

  return <></>
}
