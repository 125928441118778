import { useCallback, useMemo } from 'react'
import { useFetch } from '../../api/hooks'
import { emptyRequirements } from './useRequirementsManagement'
import * as _ from 'lodash'

export const useFetchRequirements = (onResultUpdate, onError) => {
  const [request, { result, loading, error, called }] = useFetch(
    'Error Fetching Requirements.  Please refresh the page and try again',
  )
  const fetchRequirements = useCallback(
    async filter => {
      return request({
        onSuccess: result => {
          onResultUpdate && onResultUpdate(result)
        },
        onError,
        method: 'put',
        url: `/persons/${filter.personId}/prerequisites`,
        config: { body: filter },
      })
    },
    [request, onResultUpdate, onError],
  )
  return useMemo(
    () => [
      fetchRequirements,
      {
        result: result || _.cloneDeep(emptyRequirements),
        loading: loading,
        called,
        error,
      },
    ],
    [result, loading, error, called],
  )
}
