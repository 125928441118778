import React, { useState } from 'react'
import { AddButton } from '../add-button'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import PersonAutocomplete from './PersonAutocomplete'
import { PersonSuggestion } from '../../api/hooks/useFetchPersonSuggestions'
import Button from '@material-ui/core/Button'
import isNull from 'lodash/isNull'
import { useShowPrompt } from '../modal/hooks'
import AddPersonFlow, { AddPersonPayload } from './AddPersonFlow'
import first from 'lodash/first'
import { toInteger } from 'lodash'
import useAddPersonContact, { ContactType, PersonContactType } from './hooks/useAddPersonContact'

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '4px',
    display: 'inline-block',
    position: 'relative',
    lineHeight: '28px',
  },
  wrapperOpen: {
    borderColor: '#000',
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    borderWidth: '2px',
    borderBottomWidth: 0,
    borderStyle: 'solid',
  },
  borderClear: {
    width: '100%',
    height: '2px',
    backgroundColor: 'white',
    position: 'absolute',
    top: '100%',
    left: 0,
    zIndex: 3,
  },
  dropDownContainer: {
    zIndex: 2,
    position: 'absolute',
    top: '100%',
    left: '-2px',
    backgroundColor: 'white',
    borderColor: '#000',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    borderWidth: '2px',
    borderStyle: 'solid',
    width: '400px',
    padding: '8px',
  },
  hidden: {
    display: 'none',
  },
  blurHandler: {
    zIndex: 1,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'transparent',
  },
  secondaryButton: {
    height: 36,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    display: 'block',
    float: 'right',
    marginTop: '8px',
  },
  icon: {
    marginRight: 5,
  },
  addButton: {
    zIndex: 2,
  },
}))

const getInitialPersonCreateValues = (input: string) => {
  if (input.trim()) {
    const emailMatch = input.match(/[^\s]+@[^\s]+/)
    const email = first(emailMatch) || ''
    input = input.replace(email, '')
    const phoneMatch = input.match(/^\+?\d+$|^\+?\d+[^\s]|[^\s]\+?\d+[^\s]/)
    const phone = first(phoneMatch) || ''
    const names = input.replace(phone, '').trim().replace(/\s+/, ' ').split(' ')
    return {
      givenName: names.shift(),
      familyName: names.join(' '),
      email,
      phone,
      home: null,
      birthday: null,
      sendInvite: true,
    }
  }
}

export interface AddPersonButtonProps {
  groupId: number
  groupTitle: string
  autocompleteLabel: string
  personName: string
  handlePersonCreate: (data: AddPersonPayload) => Promise<any>
  legendWidthFixture?: number
  otherTitle?: string
  disableGroupPersons?: boolean
  classes?: {
    button?: string
    borderClear?: string
  }
}

export function AddPersonButton({
  groupId,
  groupTitle,
  autocompleteLabel,
  personName,
  handlePersonCreate,
  legendWidthFixture,
  otherTitle,
  disableGroupPersons,
  classes: externalClasses,
}: AddPersonButtonProps) {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<PersonSuggestion | null>(null)
  const showAddPerson = useShowPrompt(AddPersonFlow)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [addPersonContact] = useAddPersonContact()
  const handlePersonReady = (data: AddPersonPayload) => {
    setIsSubmitting(true)
    const { id, userData } = data
    if (id && Object.keys(userData).length) {
      for (const [key, value] of Object.entries(userData)) {
        if (!['email', 'phoneNumber', 'homePhoneNumber'].includes(key)) continue
        const payload: any = {}
        if (key === 'email') {
          payload.contactType = ContactType.EMAIL
          payload.personContactType = PersonContactType.EMAIL
          payload.email = value
        } else {
          payload.contactType = ContactType.PHONE
          payload.personContactType =
            key === 'phoneNumber' ? PersonContactType.MOBILE_PHONE : PersonContactType.HOME_PHONE
          payload.phoneNumber = value
        }
        addPersonContact(id, payload)
      }
    }
    return handlePersonCreate(data)
      .then(() => setOpen(false))
      .finally(() => setIsSubmitting(false))
  }

  const handleAddPerson = (inputValue: string) =>
    showAddPerson({
      submit: async (data: AddPersonPayload) => {
        await handlePersonReady(data)
      },
      personName: personName,
      initialValues: getInitialPersonCreateValues(inputValue),
    })

  return (
    <div className={cn(classes.wrapper, { [classes.wrapperOpen]: open })}>
      <div className={cn(classes.blurHandler, { [classes.hidden]: !open })} onClick={() => setOpen(false)} />
      <AddButton className={cn(classes.addButton, externalClasses?.button)} onClick={() => setOpen(true)} />
      <div className={cn(classes.borderClear, externalClasses?.borderClear)}>&nbsp;</div>
      <div className={cn(classes.dropDownContainer, { [classes.hidden]: !open })}>
        <PersonAutocomplete
          label={autocompleteLabel}
          groupId={groupId}
          groupTitle={groupTitle}
          otherTitle={otherTitle}
          disabled={isSubmitting}
          onCreate={handleAddPerson}
          onChange={setSelectedUser}
          disableGroupPersons={disableGroupPersons}
          addToGroupOnSelection={false}
          legendWidthFixture={legendWidthFixture}
        />
        <Button
          onClick={() => {
            setIsSubmitting(true)
            handlePersonCreate({ id: toInteger(selectedUser?.id), sendInvite: true, userData: {} })
              .then(() => setOpen(false))
              .finally(() => setIsSubmitting(false))
          }}
          disabled={isNull(selectedUser) || isSubmitting}
          className={classes.secondaryButton}
          variant="contained"
        >
          Add
        </Button>
      </div>
    </div>
  )
}

export default AddPersonButton
