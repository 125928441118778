import { useEffect, useMemo, useState, useCallback } from 'react'
import { useAnswerQuestionSet, useFetchPerson, useFetchRequirements } from './index'
import * as _ from 'lodash'
import {
  PartialPerson,
  PrerequisiteFilters,
  Prerequisites,
  QuestionSetResponse,
} from '../types'

export const emptyRequirements = {
  fulfilledAllRequirements: false,
  questionSets: [],
  credentials: [],
}

interface Props {
  person?: PartialPerson
  excludedQuestionSetIds: number[]
  filters: PrerequisiteFilters
  onCompleted?: (success: boolean) => void
  continueEvenIfError?: boolean
}

interface RequirementsManagementReturn {
  loading: boolean
  requirements: Prerequisites
  allSet: boolean
  answerQuestionSet: (answerSet: QuestionSetResponse) => Promise<any>
  person: PartialPerson
  refresh: () => any
}

// in scheduler, for example, we might have a cognitoSub, but not a personId - so, we have to fetch if missing
const usePerson = (personProp?: PartialPerson, onError?: () => void): [PartialPerson, { loaded: boolean }] => {
  const [person, setPerson] = useState<PartialPerson>(personProp || {})
  const personId = _.get(person, 'id')
  const [fetchPerson, { loading }] = useFetchPerson(setPerson)
  useEffect(() => {
    if (!personId) {
      fetchPerson()
        .then((res) => setPerson(res))
        .catch(() => onError && onError())
    }
  }, [personId])
  return [
    person,
    {
      loaded: !!personId && !loading,
    },
  ]
}

const useComplete = (allSet: boolean, onCompleted?: (success: boolean) => void) => {
  useEffect(() => {
    if (allSet && onCompleted) {
      onCompleted(true)
    }
  }, [allSet, onCompleted])
}

const useCombinedFilters = ({
  person,
  excludedQuestionSetIds,
  filters,
}: {
  person: PartialPerson
  excludedQuestionSetIds: number[]
  filters: PrerequisiteFilters
}): PrerequisiteFilters => {
  const personId = _.get(person, 'id')
  return useMemo(
    () =>
      _.isEmpty(excludedQuestionSetIds) ? { ...filters, personId } : { ...filters, excludedQuestionSetIds, personId },
    [filters, excludedQuestionSetIds, person],
  )
}

const useSetMustFetchOnChange = (
  filters: PrerequisiteFilters,
  personLoaded: boolean,
  setDirty: (val: boolean) => void,
) => {
  useEffect(() => {
    if (personLoaded) {
      setDirty(true)
    }
  }, [filters, personLoaded, setDirty])
}

const useRequirements = (filters: PrerequisiteFilters, onError: () => void) => {
  const [dirty, setDirty] = useState<boolean>(false)
  const [requirements, setRequirements] = useState<Prerequisites>(_.cloneDeep(emptyRequirements))
  const [fetchRequirements, status] = useFetchRequirements(setRequirements, onError)
  useEffect(() => {
    if (dirty) {
      fetchRequirements(filters).then(() => setDirty(false))
    }
  }, [filters, dirty, fetchRequirements])
  return {
    requirements,
    status,
    setDirty,
  }
}

const useAllSet = (requirements: Prerequisites, loading: boolean): boolean => {
  return useMemo(() => {
    if (loading) {
      return false
    }
    const { questionSets, credentials } = requirements
    return !questionSets.length && !credentials.length
  }, [requirements, loading])
}

const useOnError = (onCompleted?: (success: boolean) => void, continueEvenIfError?: boolean) =>
  useCallback(() => onCompleted && onCompleted(!!continueEvenIfError), [onCompleted, continueEvenIfError])

export const useRequirementsManagement = ({
  person: personProp,
  excludedQuestionSetIds,
  filters: filtersProp,
  onCompleted,
  continueEvenIfError,
}: Props): RequirementsManagementReturn => {
  const onError = useOnError(onCompleted, continueEvenIfError)
  const [person, { loaded: personLoaded }] = usePerson(personProp, onError)
  const filters = useCombinedFilters({ person, filters: filtersProp, excludedQuestionSetIds })
  const {
    requirements,
    status: { loading: fetchRequirementsLoading, called },
    setDirty,
  } = useRequirements(filters, onError)
  useSetMustFetchOnChange(filters, personLoaded, setDirty)
  const onSuccess = () => setDirty(true)
  const [answerQuestionSet, { loading: editQuestionLoading }] = useAnswerQuestionSet(onSuccess, onError)
  const loading = fetchRequirementsLoading || !personLoaded || editQuestionLoading || !called
  // TODO: might have to think this through - e.g. when there are un-required question-sets or when you want to edit existing answers
  const allSet = useAllSet(requirements, loading)
  useComplete(allSet, onCompleted)

  return {
    loading,
    requirements,
    allSet,
    answerQuestionSet,
    person,
    refresh: onSuccess,
  }
}
