import * as React from 'react'
import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { Hub } from 'aws-amplify'

import BaseService from './services/base.service'
import NotFound from './scenes/not-found'
import VolunteersEventsScene from './scenes/volunteer-events'
import { EventListProvider } from './contexts/EventListContext'
import { FeatureEnabledProvider } from './contexts/FeatureContext'

// connected with redux
import VolunteerEventRegisterScene from './redux/containers/VolunteerEventRegisterContainer'
import VolunteersEventDetailsScene from './redux/containers/EventDetailContainer'
import VolunteersMyEventsListScene from './redux/containers/MyEventsContainer'
import PageHeader from './redux/containers/PageHeaderContainer'
import ErrorBoundary from './kit/components/error-boundary/'
import ErrorFallback from './kit/components/error-boundary/error-fallback'
import { ROUTE_NAMES } from './constants/route-names'
import LoginWithToken from './scenes/login-with-token'
import { LoginWithTokenRegister } from './scenes/login-with-token-register'
import { paths } from './config/paths'
import { isRedirectUrl } from './utils/hub-utils'
import useGoogleAnalytics from './civic-champs-shared/utils/useGoogleAnalytics'

const baseService = new BaseService()

const ROOT_URL = baseService.getEnvVar('LANDING_URL') || 'https://civicchamps.com'

export default function Routes(props: any) {
  const [currentUser, setCurrentUser] = useState<any>(null)
  const [orgId, setOrgId] = useState<number | null>(null)
  useGoogleAnalytics()

  useEffect(() => {
    const organizationId = parseInt(window.location.pathname.split('/')[2], 10)
    setOrgId(organizationId)

    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signOut':
          setCurrentUser(null)
          window.location.href = ROUTE_NAMES.allEvents(organizationId)
          break
        case 'signIn':
          //TODO need to not "re-use" the auth:signIn event
          if (isRedirectUrl(data)) {
            window.location.href = data.redirectUrl
          } else {
            //clearing out scheduler token (if present) since `fetchUser()` / `BaseService.currentUser()` prefer it to cognito
            localStorage.removeItem('schedulerToken')
            fetchUser()
          }
          break
        case 'signUp':
          fetchUser()
          break
        default:
      }
    })

    Hub.listen('app', ({ payload: { event, data } }) => {
      switch (event) {
        case 'fetchUser':
          fetchUser()
          break
        default:
      }
    })

    async function fetchUser() {
      try {
        const user = await baseService.currentUser()
        user ? setCurrentUser(user) : setCurrentUser({})
      } catch (err) {
        setCurrentUser({})
      }
    }

    fetchUser()
  }, [])

  const serviceProps = {
    getJSON: async (api: string) => {
      try {
        return await baseService.getJSON(api)
      } catch (e) {
        console.log(e)
      }
    },
    postJSON: async (api: string, data: object) => {
      try {
        return await baseService.postJSON(api, data)
      } catch (e) {
        console.log(e)
      }
    },

    putJSON: async (api: string, data: object) => await baseService.putJSON(api, data),
    currentUser,

    DELETE: async (api: string) => await baseService.DELETE(api),
  }

  const redirectToLanding = () => {
    window.location.href = ROOT_URL
  }

  return currentUser ? (
    <FeatureEnabledProvider>
      <EventListProvider>
        <Router>
          <PageHeader orgId={orgId} currentUser={currentUser} />

          <ErrorBoundary orgId={orgId} fallback={ErrorFallback}>
            <Switch>
              {/*Public Routes Start*/}
              <Route exact path={paths.ROOT}>
                {redirectToLanding}
              </Route>

              <Route
                exact
                path={paths.TOKEN_LOGIN_REGISTER}
                render={props => <LoginWithTokenRegister {...props} {...serviceProps} />}
              />

              <Route exact path={paths.TOKEN_LOGIN} render={props => <LoginWithToken {...props} {...serviceProps} />} />

              <Route
                exact
                path={paths.CALENDAR}
                render={props => <VolunteersEventsScene {...props} {...serviceProps} />}
              />

              <Route
                exact
                path={paths.OPPORTUNITY_DETAILS}
                render={props => <VolunteersEventDetailsScene {...props} {...serviceProps} />}
              />

              <Redirect exact from={paths.OPPORTUNITIES} to={paths.CALENDAR} />
              {/*Public Routes End*/}

              {/*User Routes Start*/}
              <Route
                exact
                path={paths.MY_EVENTS_LIST}
                render={props => <VolunteersMyEventsListScene {...props} {...serviceProps} />}
              />

              <Route
                exact
                path={paths.EVENT_DETAILS}
                render={props => <VolunteersEventDetailsScene {...props} {...serviceProps} isMyEvent={true} />}
              />

              <Route
                exact
                path={paths.EVENT_REGISTRATION}
                render={props => <VolunteerEventRegisterScene {...props} {...serviceProps} />}
              />

              {/*User Routes End*/}

              <Route
                exact
                path={paths.UNAUTHORIZED}
                render={props => <NotFound message="Session timed out" {...props} />}
              />
              <Route render={props => <NotFound orgId={orgId} {...props} />} />
            </Switch>
          </ErrorBoundary>
        </Router>
      </EventListProvider>
    </FeatureEnabledProvider>
  ) : null
}
