import React, { ReactNode } from 'react'
import styled from 'styled-components'
import './style.scss'

export interface Props {
  children?: ReactNode
  tag?: 'input' | 'textarea'
  type?: 'text' | 'password'
  placeholder?: string
  disabled?: boolean
  required?: boolean
  hasError?: boolean
  errorMessage?: string
  name: string
  value?: string
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  style?: any
  onKeyPress?: (event: any) => void
  maxLength?: number
  maxHeight?: string
  className?: string
  ref?: any
}

const InputContainer = styled<any>('div')`
  ${props => {
    if (props.tag === 'textarea') {
      return `
      height: 130px;
      max-height: ${props.maxHeight};
    `
    }

    return `
    max-height: 61px;
  `
  }}
`

const InnerContent = styled<any>('div')`
  border: 1px solid
    ${props => {
      if (props.hasError) {
        return '#FF6F3E'
      }
      if (props.required) {
        return '#BDBDBD'
      }
      if (props.disabled) {
        return '#B1BED6'
      }

      return '#616882'
    }};

  ${props => {
    if (props.tag === 'textarea') {
      return `
      height: 130px;
      max-height: ${props.maxHeight};
      border-radius: 4px;

      #input {
        resize: none;
        height: auto;
        border: none;
      }`
    }

    return `
    height: 45px;
    max-height: 45px;
    border: 1px solid #B1BED6;
    box-sizing: border-box;
    border-radius: 4px;
    #input {
      height: 24px;
    }
  `
  }}
`

const Placeholder = styled<any>('label')`
  pointer-events: none;
  color: ${props => {
    if (props.hasError) { return '#FF6F3E !important' }
    if (props.disabled) { return '#B1BED6 !important' }
    if (props.required) { return '#A4A4A4 !important' }
  }};

  ${props => {
    if (props.required) {
      return `
        &::after {
          content: '*';
          color: #FF6F3E;
        }
      `
    }
  }}
`

const ErrorMessage = styled<any>('span')`
  color: ${props => (props.hasError ? '#FF6F3E' : '#A4A4A4')};
`

function Input(props: Props) {
  const {
    disabled,
    hasError,
    errorMessage,
    required,
    type,
    name,
    value,
    onChange,
    onBlur,
    placeholder,
    tag,
    children,
    onKeyPress,
    maxLength,
    maxHeight,
    className,
    ref,
    ...restProps
  } = props
  const Tag = tag || 'input'

  return (
    <>
      <InputContainer style={props.style}
                      className={`input-container ${className || ''}`}
                      maxHeight={maxHeight}
                      tag={tag || 'input'}>
        <InnerContent
          className="inner-content"
          disabled={disabled}
          hasError={hasError}
          required={required}
          maxHeight={maxHeight}
          tag={tag || 'input'}
        >
          <Tag
            id="input"
            ref={ref}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            placeholder=" "
            onKeyPress={onKeyPress}
            maxLength={maxLength}
            {...restProps}
          />
          <Placeholder htmlFor="input" hasError={hasError} disabled={disabled} required={required}>
            {placeholder}
          </Placeholder>

          {children && <div className="icon-container">{children}</div>}
        </InnerContent>
        {errorMessage && (
          <ErrorMessage className="error-message" hasError={hasError}>
            {errorMessage}
          </ErrorMessage>
        )}
      </InputContainer>
    </>
  )
}

export default Input
