import React from 'react'
import './style.scss'
import Popup from '../../../components/popup'
import { PALETTE_COLORS } from '../../../../constants/colors'
import { Button } from '../../../components/button'

interface Props {
  isOpen: boolean
  onClosePopup: (value: boolean) => void
}

export default function ErrorPopup(props: Props) {
  return (
    <Popup closeIcon={true} open={props.isOpen} height={'187px'} width="350px" radius={'4px'} clickOutside={true}>
      <div className="error-popup">
        <div className="error-popup__text" style={{ color: PALETTE_COLORS.textColors.error }}>
          Oops! something went wrong, please try again
        </div>
        <Button
          label={'Done'}
          height={'56px'}
          width={'100%'}
          type={'secondary'}
          isBold={false}
          labelColor={PALETTE_COLORS.textColors.white}
          disabled={false}
          onClick={() => props.onClosePopup(false)}
        />
      </div>
    </Popup>
  )
}
