import { connect } from 'react-redux'
import VolunteersEventDetailsScene from '../../scenes/volunteers-event-details'
import { setCurrentUser, setOpenLogin } from '../reducers/EventDetail'

function MSTPEventDetail(state: any) {
  return {
    data: state.VolunteerEventDetail,
  }
}

interface Props {
  isMyEvent?: boolean
}

function mapDispatchToProps(dispatch: any) {
  return {
    setCurrentUser: (user: any) => dispatch(setCurrentUser(user)),
    setOpenLogin: (isOpenLogin: boolean, redirectToRegisterAfterLogin?: boolean) =>
      dispatch(setOpenLogin(isOpenLogin, redirectToRegisterAfterLogin)),
  }
}

export default connect<{}, {}, Props>(MSTPEventDetail, mapDispatchToProps)(VolunteersEventDetailsScene)
