import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { get } from 'lodash'
import { Loader } from '../kit/components/loader'
import ENDPOINT_ROUTES from '../constants/APIs'

interface FeatureResponse {
  name: string
  enabled: boolean
  key: string
  id: number
  type: 'development' | 'product'
}

export interface FeatureEnabledState {
  isInitialized: boolean
  settings: { [key: string]: FeatureResponse } | {}
}

interface FeatureFetchSucceeded {
  type: 'GET_FEATURES_ENABLED_SUCCEEDED'
  payload: FeatureResponse[]
}

interface FeatureFetchFailed {
  type: 'GET_FEATURES_ENABLED_FAILED'
}

export type FeatureEnabledDispatch = (action: FeatureFetchSucceeded | FeatureFetchFailed) => void

interface Props {
  children: React.ReactNode
}

const FeatureStateContext = createContext<FeatureEnabledState | undefined>(undefined)
const FeatureDispatchContext = createContext<FeatureEnabledDispatch | undefined>(undefined)

const featureReducer = (state: FeatureEnabledState, action: FeatureFetchSucceeded | FeatureFetchFailed) => {
  switch (action.type) {
    case 'GET_FEATURES_ENABLED_SUCCEEDED':
      return { settings: action.payload, isInitialized: true }
    case 'GET_FEATURES_ENABLED_FAILED':
      return { settings: {}, isInitialized: false }
    default: {
      throw new Error('Unhandled feature enabled action type')
    }
  }
}

const FeatureEnabledProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(featureReducer, { isInitialized: false, settings: {} })

  return (
    <FeatureStateContext.Provider value={state}>
      <FeatureDispatchContext.Provider value={dispatch}>{children}</FeatureDispatchContext.Provider>
    </FeatureStateContext.Provider>
  )
}

const useFeatureEnabledState = () => {
  const context = useContext(FeatureStateContext)

  if (context === undefined) {
    throw new Error('useEventListState must be used within an EventListProvider')
  }

  return context
}

const useFeatureEnabledDispatch = () => {
  const context = useContext(FeatureDispatchContext)

  if (context === undefined) {
    throw new Error('useEventListDispatch must be used within an EventListProvider')
  }

  return context
}

const withFeatureEnabled = (Component: React.ComponentType<any>) => {
  return (props: any) => {
    const { match, getJSON } = props
    const orgId = match.params.organizationId
    const dispatch = useFeatureEnabledDispatch()
    const state = useFeatureEnabledState()

    useEffect(() => {
      (async () => {
                if (!state.isInitialized) {
          try {
            const features = await getJSON(ENDPOINT_ROUTES.Features.getFeaturesEnabled(orgId))
            dispatch({
              type: 'GET_FEATURES_ENABLED_SUCCEEDED',
              payload: features
            })
          } catch (e) {
            dispatch({
              type: 'GET_FEATURES_ENABLED_FAILED'
            })
          }
        }
      })()
    }, [])

    if (!state.isInitialized) {
      return <Loader />
    }

    return <Component featureEnabledDispatch={dispatch} featureEnabledState={state} {...props} />
  }
}

const getFeatureEnabled = (state: FeatureEnabledState, selector: string): FeatureResponse | null => {
  const {isInitialized, settings} = state
  if (!isInitialized) {
    return null
  }

  const slice = get(settings, selector)

  if (!slice) {
    return null
  }

  return slice.enabled ? slice : null
}

export { FeatureEnabledProvider, useFeatureEnabledState, useFeatureEnabledDispatch, withFeatureEnabled, getFeatureEnabled }
