import React from 'react'
import * as _ from 'lodash'
import { Button } from '@material-ui/core'
import useFileManagementUpload from 'civic-champs-shared/question-sets/hooks/useFileManagementUpload'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

interface FileInputProps {
  documentTypeId: number
  value: string | any
  onChange: (event: any) => void
  className?: string
  name?: string
  disabled?: boolean
  label?: string
  errorMessage?: string
  onBlur?: (event: any) => void
  text?: string

  organizationId: number
}

const useStyles = makeStyles({
  button: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    textAlign: 'center',
    borderRadius: '100px',
    textTransform: 'none',
  },
  close: {
    fontSize: '16px',
    position: 'relative',
    top: '2px',
    cursor: 'pointer',
  },
})

function FileInputComponent(props: FileInputProps) {
  const {
    value,
    className,
    name,
    onChange,
    disabled,
    documentTypeId,
    organizationId,
    text = 'Upload a Document',
  } = props
  const classes = useStyles()
  const { uploadFile, status } = useFileManagementUpload(organizationId)
  const upload = async (file: any) => {
    const result = await uploadFile(file, 'document', { documentTypeId })
    onChange(result)
  }

  if (value)
    return (
      <div>
        {value.filename} <CloseIcon className={classes.close} onClick={() => onChange(null)} />
      </div>
    )
  return (
    <div>
      <input
        name={name}
        id={`${name}-file-upload-input`}
        type="file"
        style={{ display: 'none' }}
        onChange={async e => upload(_.first(e.target.files))}
      />

      <label htmlFor={`${name}-file-upload-input`}>
        {/*
         @ts-ignore */}
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          component="span"
          disabled={disabled || status.loading}
        >
          {text}
        </Button>
      </label>
    </div>
  )
}

export default FileInputComponent
