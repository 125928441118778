import { useState, useCallback, useMemo } from 'react'
import { useErrorNotification } from './index'
import requestWithRetry from '../requestWithRetry'

export default function useFetch(errorMessage) {
  const [loading, setLoading] = useState(false)
  const [called, setCalled] = useState(false)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)
  const showError = useErrorNotification()

  const request = useCallback(
    async requestConfig => {
      const { method = 'get', url, config, onSuccess, onError } = requestConfig
      try {
        setCalled(true)
        setLoading(true)
        setError(null)

        const result = await requestWithRetry({ method, url, config })

        setResult(result)
        if (onSuccess) onSuccess(result)

        return result
      } catch (error) {
        setError(error)
        if (onError) onError(error)
        if (errorMessage) showError(errorMessage, error)
        throw error
      } finally {
        setLoading(false)
      }
    },
    [errorMessage, showError],
  )

  const status = useMemo(() => ({ loading, called, result, error }), [loading, called, result, error])

  return [request, status]
}
