import * as React from 'react'
import styled from 'styled-components'
import './style.scss'
import { Loader } from '../loader'
import { ReactNode } from 'react'

interface Props {
  className?: any
  color?: string
  colorShadow?: string
  active?: string
  hover?: string
  border?: string
  onClick?: any
  label: string
  disabled?: boolean
  height?: string
  width?: string
  loading?: boolean
  isBold?: boolean
  fontSize?: string
  style?: any
  labelColor?: string
  labelStyle?: any
  icon?: ReactNode
  type?: 'primary' | 'secondary' | 'outline'
}

export const Button = (props: Props) => {
  const ButtonStyle = styled<any>('div')`
    pointer-events: ${() => (!!props.disabled || props.loading ? 'none' : 'auto')};
    ${() => {
      if (props.type === 'primary') {
        return `
       height: ${props.height || '65px'}
       width: ${props.width || '256px'}
       background: ${props.color || '#FFCD00'}
       box-shadow: 3px 3px 3px 0px rgba(${props.colorShadow || '220,177,0,1'});
       &:hover {
         background: ${props.hover || '#FFD839'}
         border: 1px solid ${props.hover};
       }
       &:active {
         background: ${props.active || '#DCB100'}
         box-shadow: none;
       }
    `}

      if (props.type === 'secondary') {
        return `
      height: ${props.height || '56px'}
      width: ${props.width || '238px'}
      background: ${props.color || '#5C8DE8'}
      &:hover {
        background: ${props.hover || '#437DE9'}
      }
      &:active {
       background: ${props.active || '#386FD4'}
       box-shadow: none;
      }
    `}

      if (props.type === 'outline') {
        return `
      height: ${props.height || '56px'}
      width: ${props.width || '238px'}
      background: ${props.color || '#FFFFFF'}
      border: 1px solid ${props.border || '#5C8DE8'};
      &:hover {
        border: 1px solid ${props.hover || '#749CE6'}
      }
      &:active {
        border: 1px solid ${props.active || '#4576D3'}
        box-shadow: none;
      }`}
    }}
  `

  const Label = styled<any>('span')`
  font-weight: ${() => (props.isBold ? '600' : '500')};
  font-family: ${() => {
    if (props.type === 'primary') { return 'Inter' }
    return 'Open Sans'
  }}
  font-size: ${() => (props.fontSize ? props.fontSize : '14px')}
  color: ${() => props.labelColor || '#3A3D3D'};
`
  return (
    <ButtonStyle
      className={'button ' + props.className }
      type={props.type}
      border={props.border}
      style={{...props.style, ...(props.disabled ? { backgroundColor: '#777' } : {})}}
      onClick={props.onClick}
      icon={props.icon}
    >
      {props.loading ? (
        <Loader />
      ) : (
        <div className={'btn_content'}>
          {props.icon}
          <Label
            isBold={props.isBold}
            className={'btn_label'}
            style={props.labelStyle}
            labelColor={props.labelColor}
            type={props.type}
            fontSize={props.fontSize}
          >
            {!!props.label ? props.label : 'TEXT'}
          </Label>
        </div>
      )}
    </ButtonStyle>
  )
}
