import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Wave from '../public/assets/icons/wave.png'
import Wave1000 from '../public/assets/icons/wave-1008.png'
import Wave600 from '../public/assets/icons/wave-639.png'
import { ReactComponent as Logo } from '../public/assets/icons/ccLogoBlue.svg'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    mixWidth: '320px',
    [theme.breakpoints.down(600)]: {
      paddingTop: '20px',
    },
  },
  header: {
    width: '100%',
    height: '200px',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    textAlign: 'center',
    // Having 3 images as png shows artefacts if shrinked too much
    // and svg can't be stretched or shrinked only in one axis
    backgroundImage: `url(${Wave})`,
    // @ts-ignore
    [theme.breakpoints.between(640, 1008)]: {
      backgroundImage: `url(${Wave1000})`,
    },
    [theme.breakpoints.down(640)]: {
      backgroundImage: `url(${Wave600})`,
    },
    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
  headerContent: {
    width: '100%',
    height: '100%',
    maxWidth: '1440px',
    margin: '0 auto',
    backgroundPositionX: 'center',
    position: 'relative',
    textAlign: 'center',
  },
  logo: {
    position: 'absolute',
    top: '34px',
    left: '40px',
    [theme.breakpoints.down(1000)]: {
      display: 'none',
    },
  },
  title: {
    margin: 0,
    paddingTop: '42px',
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '45px',
    lineHeight: '52px',
    color: 'white',
  },
}))
export interface HeaderWrapperProps {
  children: React.ReactNode
  title: string
}

export const HeaderWrapper = ({ title, children }: HeaderWrapperProps) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Logo className={classes.logo} />
        <h1 className={classes.title}>{title}</h1>
      </div>
      {children}
    </div>
  )
}

export default HeaderWrapper
