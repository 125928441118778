import React, { useState } from 'react'
import { FormControl, OutlinedInput, InputLabel, InputAdornment, IconButton, FormHelperText } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

function PasswordField(props) {
  const { field, form, label, placeholder, className } = props
  const { value, onChange, name } = field
  const [showPassword, setShowPassword] = useState(false)
  const helperText = form.errors[name]
  const error = !!helperText
  const isAboveSmall = isWidthUp('sm', props.width)
  const labelOrPlaceholder = label || placeholder

  return (
    <FormControl error={error} variant="outlined" fullWidth={!isAboveSmall}>
      {labelOrPlaceholder && <InputLabel htmlFor={name}>{labelOrPlaceholder}</InputLabel>}
      <OutlinedInput
        id={name}
        className={className}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        fullWidth={!isAboveSmall}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setShowPassword(value => !value)
              }}
              onMouseDown={e => {
                e.preventDefault()
              }}
              edge="end"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={labelOrPlaceholder ? labelOrPlaceholder.length * 10 : 0}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default withWidth()(PasswordField)
