export interface NamedRef {
  id: number
  name: string
}

export interface OrgNamedRef {
  id: number
  name: string
  timeZone?: string
  website?: string
}

export enum VolunteerPreferredContactMethod {
  EMAIL = 'email',
  PHONE = 'phone',
  DO_NOT_CONTACT = 'do-not-contact',
}

export interface PersonRef {
  id: number
  givenName?: string
  familyName?: string
  email?: string
  phoneNumber?: string
  hasNoContact?: boolean
  emailVerified?: boolean
  phoneNumberVerified?: boolean
  homePhoneNumber?: string
  cognitoSub?: string
  pubnubId?: string
  preferredContactMethod?: VolunteerPreferredContactMethod
}

export interface Person extends PersonRef {
  givenName: string
  familyName: string
  email?: string
  phoneNumber?: string
  hasNoContact: boolean
  emailVerified: boolean
  phoneNumberVerified: boolean
  homePhoneNumber: string
  cognitoSub?: string
  pubnubId: string
}
