import { uniqueId } from 'lodash'
import React from 'react'

import EventEmitter from '../../utils/EventEmitter'

const nextKey = () => uniqueId('prompt-')

export default class ModalPrompt {
  constructor(component) {
    this._emitter = new EventEmitter()
    this._key = nextKey()
    this._showing = false
    this._shown = false
    this._component = component
    this._component_props = undefined

    this.close = this.close.bind(this)
    this.cancel = this.cancel.bind(this)
    this.complete = this.complete.bind(this)
  }

  get key() {
    return this._key
  }
  get showing() {
    return this._showing
  }

  render() {
    let ModalComponent = this._component
    return (
      <ModalComponent
        key={this.key}
        {...this._component_props}
        showing={this.showing}
        close={this.close}
        cancel={this.cancel}
        complete={this.complete}
      />
    )
  }

  show(props) {
    if (this._showing) return

    if (this._shown) {
      this._key = nextKey()
    }

    this._component_props = props || {}
    this._shown = this._showing = true

    this._emitter.emit('show', props)
  }

  close() {
    if (!this._showing) return

    this._showing = false
    this._emitter.emit('hide', { type: 'close' })
  }

  cancel(reason) {
    if (!this._showing) return

    this._showing = false
    this._emitter.emit('cancel', reason)
    this._emitter.emit('hide', { type: 'cancel', reason })
  }

  complete(value) {
    if (!this._showing) return

    this._showing = false
    this._emitter.emit('complete', value)
    this._emitter.emit('hide', { type: 'complete', value })
  }

  onShow(callback) {
    return this._emitter.listen('show', callback)
  }
  onHide(callback) {
    return this._emitter.listen('hide', callback)
  }
  onCancel(callback) {
    return this._emitter.listen('cancel', callback)
  }
  onComplete(callback) {
    return this._emitter.listen('complete', callback)
  }
}
