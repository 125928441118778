import React from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

interface Option {
  value: any
  label: string
}

interface Props {
  name: string
  value: any
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  options: Option[]
}

const useStyles = makeStyles({
  radioGroup: {
    padding: '8px 18px',
  },
  label: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#000000',
    paddingTop: '3px',
  },
  root: {
    alignItems: 'start',
  },
  radio: {
    padding: '0px 9px 9px 9px',
  },
})

export const RadioList = ({ value, options, onChange, name }: Props) => {
  const classes = useStyles()
  return (
    <FormControl component="fieldset">
      <RadioGroup aria-label={name} name={name} value={value} onChange={onChange} className={classes.radioGroup}>
        {options.map(({ value, label }) => (
          <FormControlLabel
            classes={{ root: classes.root, label: classes.label }}
            value={value}
            control={<Radio classes={{ root: classes.radio }} />}
            label={label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioList
