import React from 'react'
import './styles.scss'
import Pagination from 'react-js-pagination'

interface Props {
  activePage: number
  itemsCountPerPage?: number
  totalItemsCount: number
  pageRangeDisplayed?: number
  onChange: (val: number) => void
}

function PaginationComponent(props: Props) {
  const {activePage, itemsCountPerPage, totalItemsCount, pageRangeDisplayed, onChange} = props
  const itemsCount = itemsCountPerPage || 5
  const pageRange = pageRangeDisplayed || 5
  return(
    <div className="pagination_wrapper">
      <Pagination
        hideFirstLastPages
        activePage={activePage}
        itemsCountPerPage={itemsCount}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={pageRange}
        onChange={onChange}
      />
    </div>
  )
}

export default PaginationComponent
