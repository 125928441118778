import * as React from 'react'

type StyleFunction = (provided: React.CSSProperties, state: { [prop: string]: any }) => {}

interface StyleObject {
  [prop: string]: StyleFunction
}

const baseOptions: StyleObject = {
  control: (provided, state) => {
    return {
    ...provided,
    fontSize: '14px',
    fontWeight: '500',
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    minHeight: '45px',
    borderColor: 'transparent',
    '&:hover': { borderColor: 'transparent' },
  }},
  option: (provided, state) => {
    return {
      ...provided,
      fontSize: '16px',
      lineHeight: '26px',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      backgroundColor: state.isSelected ? 'rgb(38, 132, 255)' : 'transparent',
      '&:hover': { backgroundColor: state.isSelected ? 'rgb(38, 132, 255)' : 'rgb(242, 242, 242)' },
    }
  },
  singleValue: (provided, state ) => {
    return {
      ...provided,
      fontSize: '16px',
      color: `${state.disabled ? '#626262' : '#626262'}`,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }
  },
  placeholder: (provided, state) => ({
    ...provided,
    color: `${state.isDisabled ? 'rgba(98, 98, 98, 0.5)' : 'rgb(98, 98, 98)'}`,
    fontSize: '16px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
}

export const BaseSelectStyles: StyleObject = {
  ...baseOptions,
}
