import React from 'react'
import Popup from '../../../components/popup/index'
import { PALETTE_COLORS } from '../../../../constants/colors'
import './style.scss'
import { Button } from '../../../components/button'
import ENDPOINT_ROUTES from '../../../../constants/APIs'
import { ROUTE_NAMES } from '../../../../constants/route-names'

interface Props {
  eventName: string
  eventId: number
  onClosePopup: (value: boolean) => any
  isOpen?: boolean
  orgId: number
}

export default function CancelRegistrationPopup(props: Props & any) {
  const cancelRegistration = async () => {
    try {
      await props.DELETE(ENDPOINT_ROUTES.EventRegistration.cancel(props.eventId))
    } catch (error) {
      console.log('error ', error)
    }
  }

  const handleYes = async () => {
    await cancelRegistration()
    document.body.classList.remove('disable-scroll')
    props.onClosePopup(false)
    props.history.push(ROUTE_NAMES.myEvents(props.orgId))
  }

  const handleNo = () => {
    document.body.classList.remove('disable-scroll')
    props.onClosePopup(false)
  }

  return (
    <Popup
      open={props.isOpen}
      height={'381px'}
      width="537px"
      radius={'4px'}
      closeIcon={true}
      closePopup={props.onClosePopup}
    >
      <div className="cancel-registration">
        <div className="title" style={{ color: PALETTE_COLORS.textColors.defaultLight }}>
          Cancel Registration
        </div>
        <div className="text" style={{ color: PALETTE_COLORS.textColors.default }}>
          Are you sure you want to cancel your registration to{' '}
          <span style={{ fontWeight: 600 }}>{props.eventName}</span>?
        </div>
        <div className="action-block">
          <Button
            label={'Yes, Cancel'}
            height={'48px'}
            width={'190px'}
            type={'secondary'}
            isBold={false}
            labelColor={PALETTE_COLORS.textColors.white}
            disabled={false}
            onClick={handleYes}
            className={'cancel'}
            style={{ background: '#FF0101' }}
          />
          <div
            className="no-cancel"
            onClick={handleNo}
            style={{ color: PALETTE_COLORS.textColors.secondaryLighten }}
          >
            Don't Cancel
          </div>
        </div>
      </div>
    </Popup>
  )
}
