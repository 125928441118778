import React from 'react'
import Box from '@material-ui/core/Box'

import './style.scss'

interface Props {
  height?: string
}

export const Loader = (props: Props) => {
  return (
    <Box className="loader_wrapper" height={props.height}>
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </Box>
  )
}
