import { useCallback } from 'react'

import { useFetch } from '../../api/hooks'

export const useSignWaiver = () => {
  const [request, status] = useFetch('Error signing waiver.  Please try again')

  const signWaiver = useCallback(
    async ({
      personId,
      credentialId,
      associatedEntity,
      expiredAt,
      identificationType,
      attachmentIds = [],
      identificationTypeOther,
    }) => {
      return request({
        method: 'post',
        url: '/credentials/sign',
        config: {
          body: {
            personId,
            credentialId,
            associatedEntity,
            attachmentIds,
            identificationType,
            expiredAt,
            identificationTypeOther,
          },
        },
      })
    },
    [request],
  )

  return [signWaiver, status]
}
