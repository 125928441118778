import React from 'react'
import parse from 'autosuggest-highlight/parse'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { Grid, Typography } from '@material-ui/core'
import { GoogleAutoCompleteMixedResponse } from './AddressField'

export function PlacePredictionOption(props: GoogleAutoCompleteMixedResponse) {
  let parts: any[] = []
  let secondaryText
  if ('structured_formatting' in props) {
    // this is AutocompletePrediction item
    const matches = props.structured_formatting.main_text_matched_substrings || []
    parts = parse(
      props.structured_formatting.main_text,
      matches.map((match: any) => [match.offset, match.offset + match.length]),
    )
    secondaryText = props.structured_formatting.secondary_text
  } else if ('name' in props) {
    // this is PlaceResult item
    parts = [
      {
        highlight: false,
        text: props.name,
      },
    ]
    secondaryText = props.formatted_address
  }
  return (
    <Grid container alignItems="center">
      <Grid item>
        <LocationOnIcon />
      </Grid>
      <Grid item xs>
        {parts.map((part: any, index: any) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
        <Typography variant="body2" color="textSecondary">
          {secondaryText}
        </Typography>
      </Grid>
    </Grid>
  )
}
