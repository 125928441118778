import { Grid, makeStyles, Tooltip as TooltipComponent } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles({
  tooltipText: {
    width: '100%',
    textAlign: 'justify',
  },
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    borderRadius: '5px',
    border: '1px #E0E3E3 solid',
    padding: '8px',
  },
  arrow: {
    color: '#fff',
  },
})

export const Tooltip = ({ value, children }: any) => {
  const classes = useStyles()
  return (
    <TooltipComponent
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      title={
        <Grid container spacing={0}>
          <Grid container item xs={12} justify="space-between">
            <div className={classes.tooltipText}>{value}</div>
          </Grid>
        </Grid>
      }
      placement="right-start"
      arrow
      interactive
    >
      {children}
    </TooltipComponent>
  )
}

export default Tooltip
