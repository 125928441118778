import React from 'react'
import './style.scss'
import ShiftItem from './shift'
import { PALETTE_COLORS } from '../../../../constants/colors'
import { RoleModel, RoleType, Timeshift } from '../model'
import { Button } from '../../../components/button'

interface Props {
  shifts: Timeshift[]
  roles: RoleModel[]
  registrations: []
  isEditMode: boolean
  register: () => void
  isLoading: boolean
  disableUpdate?: boolean
}

export default function VolunteerRegisterForm(props: Props & any) {
  const shifts = props.shifts
  const checkedShifts = props.data.shifts
  const shiftError = props.data.shiftError
  const registrations = props.registrations

  const isAlreadyHaveRegistration = (shift_id: number) => {
    return registrations.find((registration: { details: any[] }) => {
      return registration.details.find((details: { timeshift: { id: any } }) => {
        return details.timeshift.id === shift_id
      })
    })
  }

  const handleRegister = () => {
    if (!checkedShifts.length && !props.isEditMode) {
      props.validate()
      return
    }
    props.register()
  }

  const getTotalAvailable = (accumulator: number, current: RoleType) => {
    return accumulator + current.available
  }
  return (
    <div className="register-form">
      {!props.isEditMode && (
        <div className="shifts">
          <div className="shifts__title" style={{ color: PALETTE_COLORS.textColors.defaultLight }}>
            Please choose shift(s)
          </div>
          {shifts &&
            shifts.length &&
            shifts.map((shift: Timeshift, idx: number) => (
              <ShiftItem
                disabled={isAlreadyHaveRegistration(shift.id) || shift.roles.reduce(getTotalAvailable, 0) <= 0}
                shift={shift}
                key={idx}
                id={idx}
                roles={props.roles}
                {...props}
              />
            ))}
          {shiftError && (
            <div className="shift-error" style={{ color: PALETTE_COLORS.textColors.error }}>
              {shiftError}
            </div>
          )}
        </div>
      )}
      <div className="register-btn">
        <Button
          label={props.isEditMode ? 'Update' : 'Register'}
          height={'56px'}
          width={'202px'}
          type={'secondary'}
          isBold={true}
          labelColor={PALETTE_COLORS.textColors.white}
          disabled={props.isLoading || props.disableUpdate}
          style={{background: props.isLoading || props.disableUpdate ? '#B1BED6' : '#5C8DE8'}}
          onClick={handleRegister}
        />
      </div>
    </div>
  )
}
