import { isFunction } from 'lodash';
import { useRef } from 'react';

const SENTINEL = Symbol('lazRef.sentinel');

export default function useLazyRef(initializer) {
    let ref = useRef(SENTINEL);
    if(ref.current === SENTINEL) {
        if( !isFunction(initializer) ) {
            throw new Error('useLazyRef() requires an initializer function.  For simple values prefer useRef()');
        }

        ref.current = initializer();
    }

    return ref;
}