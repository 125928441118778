import * as React from 'react'
import { useEffect, useState, useCallback } from 'react'
import VolunteerRegistrationModule from '../../kit/modules/volunteer-registration'
import { Loader } from '../../kit/components/loader'
import { ROUTE_NAMES } from '../../constants/route-names'
import ENDPOINT_ROUTES from '../../constants/APIs'
import { useFeatureEnabledDispatch, useFeatureEnabledState } from '../../contexts/FeatureContext'

function VolunteerEventRegisterScene(props: any) {
  const { match, history, getJSON } = props
  const isEditMode = props.location.state && props.location.state.isEditMode
  const eventId = (props.location.state && props.location.state.eventId) || props.match.params.eventId
  const token = props.match.params.token
  const organizationId = match.params.organizationId

  const [event, setEvent] = useState(null)
  const [roles, setRoles] = useState(null)
  const [loading, setLoading] = useState(false)
  const [registrations, setRegistration] = useState([])

  const getEvent = useCallback( async (eventId) => {
      if (!eventId) {
        history.push(ROUTE_NAMES.notFound())
    }

      if (isEditMode) {
        // TODO: MAKE THIS ENTIRE THING WORK...RIGHT NOW, EDITING REGISTRATIONS IS DISABLED ANYWAYS
        const response = await getJSON(ENDPOINT_ROUTES.Events.getMyEventById(Number(eventId)))
        if (response) {
          setEvent(response)
        }
      } else {
        const endpoint = ENDPOINT_ROUTES.Events.getOccurrence(match.params.eventId, token ? { token } : undefined)
        const response = await getJSON(endpoint)
        if (response) {
          setEvent(response)
        }
      }
  }, [getJSON, history, isEditMode])

  const getRoles = useCallback( async (organizationId) => {
    const response = await getJSON(ENDPOINT_ROUTES.Events.getRolesOccurrenceRefactor(organizationId))
    if (response) {
      setRoles(response)
    }
  }, [getJSON])

  const getRegistrations = useCallback(async (eventId) => {
    const endpoint = ENDPOINT_ROUTES.Events.getMyEventsOccurrenceRefactor({ encodedInstance: eventId })
    const response = await getJSON(endpoint)
    if (response) {
      setRegistration(response)
    }
  }, [getJSON])

  useEffect(() => {
    (async () => {
      setLoading(true)
      try {
        await Promise.all([
          getEvent(eventId),
          getRoles(organizationId),
          getRegistrations(eventId),
        ])
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [eventId, getEvent, getRoles, organizationId])

  if (loading || !event || !roles) {
    return <Loader />
  }

  return (
    <div className="volunteer-event-register">
      <VolunteerRegistrationModule
        event={event}
        roles={roles}
        registrations={registrations}
        isEditMode={isEditMode}
        organizationId={organizationId}
        {...props} />
    </div>
  )
}

// NOTE: can probably rip this out, but will leave for now in case we want to add back feature toggles
const withIsRefactorEnabled = (Component: any) => {
  return (props: any) => {
    const { match, getJSON } = props
    const organizationId = match.params.organizationId
    const featureEnabledState = useFeatureEnabledState()
    const featureEnabledDispatch = useFeatureEnabledDispatch()

    useEffect(() => {
      (async () => {
        if (!featureEnabledState.isInitialized) {
          try {
            const features = await getJSON(ENDPOINT_ROUTES.Features.getFeaturesEnabled(organizationId))
            featureEnabledDispatch({
              type: 'GET_FEATURES_ENABLED_SUCCEEDED',
              payload: features
            })
          } catch (e) {
            featureEnabledDispatch({
              type: 'GET_FEATURES_ENABLED_FAILED'
            })
          }
        }
      })()
    }, [])

    if (!featureEnabledState.isInitialized) {
      return <Loader />
    }

    return <Component {...props} />
  }
}
export default VolunteerEventRegisterScene
