import React, { BaseSyntheticEvent, useState } from 'react'
import './index.scss'
import classNames from 'classnames'
import _uniqueId from 'lodash/uniqueId'

interface Props {
  checked?: boolean
  className?: string
  onChange: (value: any) => void
  id?: string
  label?: string
  onBlur?: (event: BaseSyntheticEvent) => void
  readonly?: boolean
}

const CheckboxComponent = (props: Props) => {
  const { className, onChange, id, label, checked, onBlur, readonly } = props
  const classes = classNames('checkbox_item', {
    [`${className}`]: className,
  })
  const [ uniqueId ] = useState(() => _uniqueId('checkbox-'))

  return (
    <div className="checkbox_wrapper">
      <div className={classes}>
        <span className={`checkbox_checkmark ${checked ? 'checkbox_checked' : ''}`} />
        <input
          id={id ? id : uniqueId}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          disabled={readonly}
        />
        <label htmlFor={id ? id : uniqueId}>{label}</label>
      </div>
    </div>
  )
}

export default CheckboxComponent
