import React from 'react'
import Routes from './Routes'
import {Provider} from 'react-redux'
import {store} from './redux/configureStore'
import {SnackbarProvider} from 'notistack'
import ModalPromptProvider from './civic-champs-shared/core/modal/ModalPromptProvider'
import Notifications from './civic-champs-shared/core/notifications'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

export default function App() {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <SnackbarProvider>
          <Notifications/>
          <ModalPromptProvider>
            <Routes/>
          </ModalPromptProvider>
        </SnackbarProvider>
      </Provider>
    </MuiPickersUtilsProvider>
  )
}
