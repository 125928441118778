import { castArray } from 'lodash'
import { useMemo, useRef, useState } from 'react'
import useLazyRef from '../../utils/useLazyRef'
import EventEmitter from '../../utils/EventEmitter'
import get from 'lodash/get'

const markNew = item => ({ ...item, isNewInCollection: true })

const useRemoteCollection = (key = 'id') => {
  const [items, setItems] = useState([])
  const versionRef = useRef(0)
  const emitterRef = useLazyRef(() => new EventEmitter())

  const operations = useMemo(
    () => {
      const operations = {
        eagerReplace: (item, markAsNew = true) => {
          emitterRef.current.emit('beforeUpdate', item)
          let oldItem = null
          setItems(prev =>
            prev.map(i => {
              if (get(i, key) === get(item, key)) {
                oldItem = i
                return markAsNew ? markNew(item) : item
              } else {
                return i
              }
            }),
          )

          let timeoutId
          if (markAsNew) {
            // Marking newly added items as isNewInCollection for 5 seconds for UI purposes
            timeoutId = setTimeout(() => {
              operations.eagerReplace(item, false)
              emitterRef.current.emit('isNewInCollectionRemoved', item)
            }, 5000)
          }

          emitterRef.current.emit('updated', item)

          const currVersion = versionRef.current
          return function undoReplace() {
            if (versionRef.current !== currVersion) return
            clearTimeout(timeoutId)
            setItems(prev => prev.map(i => (get(i, key) === get(oldItem, key) ? oldItem : i)))
            emitterRef.current.emit('updated', oldItem)
          }
        },
        eagerAdd: item => {
          emitterRef.current.emit('beforeAdd', item)
          const originalItems = castArray(item)
          const itemArr = originalItems.map(markNew)
          const itemIds = itemArr.map(item => get(item, key))
          setItems(prev => [...itemArr, ...prev])
          emitterRef.current.emit('added', item)
          // Marking newly added items as isNewInCollection for 5 seconds for UI purposes
          const timeoutId = setTimeout(() => {
            originalItems.forEach(item => {
              operations.eagerReplace(item, false)
            })
            emitterRef.current.emit('isNewInCollectionRemoved', originalItems)
          }, 5000)

          const currVersion = versionRef.current
          return function undoAdd() {
            if (versionRef.current !== currVersion) return
            clearTimeout(timeoutId)
            setItems(prev => prev.filter(i => !itemIds.includes(get(i, key))))
            emitterRef.current.emit('removed', item)
          }
        },
        eagerRemove: item => {
          emitterRef.current.emit('beforeRemove', item)
          setItems(prev => prev.filter(i => get(i, key) !== get(item, key)))

          const currVersion = versionRef.current
          return function undoRemove() {
            if (versionRef.current !== currVersion) return
            setItems(prev => [item, ...prev]) //TODO how to recover sort order!?
            emitterRef.current.emit('added', item)
          }
        },
        eagerRemoveMany: items => {
          emitterRef.current.emit('beforeRemove', items)
          const ids = items.map(item => get(item, key))
          setItems(prev => prev.filter(i => ids.indexOf(get(i, key)) < 0))

          const currVersion = versionRef.current
          return function undoRemove() {
            if (versionRef.current !== currVersion) return
            setItems(prev => [...items, ...prev]) //TODO how to recover sort order!?
            emitterRef.current.emit('added', items)
          }
        },
        syncCollection: newItems => {
          emitterRef.current.emit('beforeSync', newItems)
          versionRef.current++
          setItems(newItems)
          emitterRef.current.emit('synced', newItems)
        },
      }
      return operations
    },
    [setItems, versionRef, emitterRef], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const events = useMemo(
    () => ({
      onAdd: callback => emitterRef.current.listen('added', callback),
      onRemove: callback => emitterRef.current.listen('removed', callback),
      onUpdate: callback => emitterRef.current.listen('updated', callback),
      onSync: callback => emitterRef.current.listen('synced', callback),
      onBeforeAdd: callback => emitterRef.current.listen('beforeAdd', callback),
      onBeforeRemove: callback => emitterRef.current.listen('beforeRemove', callback),
      onBeforeUpdate: callback => emitterRef.current.listen('beforeUpdate', callback),
      onBeforeSync: callback => emitterRef.current.listen('beforeSync', callback),
      onIsNewInCollectionRemoved: callback => emitterRef.current.listen('isNewInCollectionRemoved', callback),
    }),
    [emitterRef],
  )

  return [items, operations, events]
}

export default useRemoteCollection
