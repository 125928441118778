import React, { useEffect, useState } from 'react'
import './style.scss'
import SelectComponent from '../../../../components/default-select'
import CheckBox from '../../../../components/check-box'
import { PALETTE_COLORS } from '../../../../../constants/colors'
import { RoleModel, RoleType, Shift, Timeshift } from '../../model'

export interface OptionType {
  value: string
  label: string
}

interface Props {
  shift: Timeshift
  roles: RoleModel[]
  disabled?: boolean
}

export default function ShiftItem(props: Props & any) {
  const [role, setRole] = useState()
  const [check, setCheck] = useState(false)
  const [roleOptions, setRoleOptions] = useState([])

  const { id, name, time_start, time_end } = props.shift

  useEffect(() => {
    const roleOptions: any = []
    props.shift.roles.forEach((item: RoleType) => {
      if (item.available > 0) {
        roleOptions.push({
          label: item.name,
          value: String(item.id),
        })
      }
    })
    setRoleOptions(roleOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let error = ''
  props.data.shifts.forEach((item: Shift) => {
    if (item.id === id) {
      error = item.error
    }
  })

  useEffect(() => {
    if (check) {
      props.addShift({
        id: props.shift.id,
        roleId: null,
        error: '',
      })
    } else {
      props.removeShift(id)
      setRole(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check])

  useEffect(() => {
    // @ts-ignore
    role?.value && props.addRole(id, Number(role.value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role])

  const shift_time = (
    <p>
      {name} <br /> {time_start} - {time_end}
    </p>
  )
  return (
    <div className="shift">
      <div className="shift__text">
        <CheckBox
          text={shift_time}
          isChecked={check}
          isDisabled={!!props.disabled}
          onChange={e => setCheck(!check)}
        />
      </div>
      <div className="shift__select">
        <SelectComponent
          // @ts-ignore
          value={role}
          disabled={!!props.disabled}
          onChange={(option: OptionType) => {
            setCheck(true)
            // @ts-ignore
            setRole(option)
          }}
          options={roleOptions}
          placeholder={'Choose Role'}
        />
        {error && (
          <div className="error" style={{ color: PALETTE_COLORS.textColors.error }}>
            {error}
          </div>
        )}
      </div>
    </div>
  )
}
