import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Switch, { SwitchProps } from '@material-ui/core/Switch/Switch'
import { Color } from 'csstype'
import { Tooltip } from '@material-ui/core'

export interface StylesProps {
  thumbColor: Color
  trackColor: Color
}

interface Props extends SwitchProps, StylesProps {
  tooltip?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 29,
    height: 15,
    padding: 0,
    margin: 0,
  },
  switchBase: ({ trackColor }: StylesProps) => ({
    padding: '2px',
    '&.customSwitchChecked': {
      transform: 'translateX(13px)',
      '&:before': {
        content: '""',
        display: 'inline-block',
        transform: 'rotate(45deg)',
        height: '6px',
        width: '3px',
        borderBottom: `1px solid ${trackColor}`,
        borderRight: `1px solid ${trackColor}`,
        position: 'absolute',
        top: '3px',
      },
    },
  }),
  thumb: ({ thumbColor }: StylesProps) => ({
    width: 11,
    height: 11,
    color: thumbColor,
  }),
  track: ({ trackColor }: StylesProps) => ({
    borderRadius: 15 / 2,
    border: 0,
    backgroundColor: `${trackColor} !important`,
    opacity: '1 !important',
    transition: theme.transitions.create(['background-color', 'border']),
  }),
  focusVisible: {},
}))

export const CustomSwitchComponent = ({ thumbColor, trackColor, tooltip = '', ...props }: Props) => {
  const classes = useStyles({ thumbColor, trackColor })
  return (
    <Tooltip title={tooltip}>
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: 'customSwitchChecked',
        }}
        {...props}
      />
    </Tooltip>
  )
}

export default CustomSwitchComponent
