import React from 'react'
import InputMask from 'react-input-mask'
import { TextField } from '@material-ui/core'
import { fieldToTextField } from 'formik-material-ui'

export function PhoneField(props) {
  return (
    <InputMask {...fieldToTextField(props)} mask="+1 (999) 999-9999" maskChar=" " alwaysShowMask={false}>
      {inputProps => <TextField {...inputProps} />}
    </InputMask>
  )
}

export default PhoneField
