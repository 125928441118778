import moment, { unitOfTime, Moment } from 'moment'
import React from 'react'
import { Box } from '@material-ui/core'

import './styles.scss'
import { viewTypes } from '../viewTypes'

interface Props {
  onChangeDate?: (value: Moment) => void
  dateValue?: Moment
  viewType: unitOfTime.DurationConstructor
}

function SearchFilters(props: Props) {
  const onChangePeriod = (value: string) => {
    let dateValue = moment(props.dateValue)
    switch (value) {
      case 'prev':
        dateValue.subtract(1, props.viewType)
        break

      case 'next':
        dateValue.add(1, props.viewType)
        break

      case 'today':
      default:
        dateValue = moment()
    }

    props.onChangeDate && props.onChangeDate(dateValue)
  }

  const title = moment(props.dateValue).format(props.viewType === viewTypes.DAY ? 'MMMM D' : 'MMMM YYYY')

  return (
    <div className="search_filters2_wrapper">
      <div className="search_filters2_block">
        <div className="month_pagination2">
          <div className="month_pagination2_toolbar">
            <div className="month_pagination2_wrap" onClick={() => onChangePeriod('prev')}>
              <div className="month_pagination2_prev" />
            </div>
            <Box display="flex" flexDirection="column" alignItems="center">
              <div className="month_pagination2_title">{title}</div>
              {props.viewType === viewTypes.WEEK && (
                <Box component="span" fontSize={14} fontWeight={400}>
                  <b>Week:</b> {props.dateValue && props.dateValue.week()}
                </Box>
              )}
            </Box>
            <div className="month_pagination2_wrap" onClick={() => onChangePeriod('next')}>
              <div className="month_pagination2_next" />
            </div>
          </div>
          <div className="month_pagination2_today" onClick={() => onChangePeriod('today')}>
            Today
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchFilters
