import { Auth } from 'aws-amplify'

export const getAuthorizationToken = async () => {
  const schedulerToken = localStorage.getItem('schedulerToken')
  if (schedulerToken) {
    return `scheduler:${schedulerToken}`
  } else {
    const session = await Auth.currentSession()
    return session.getIdToken().getJwtToken()
  }
}
