import { NamedRef, Omit, OrgNamedRef, Person, PrerequisiteFilters } from './index'

export enum InvitationType {
  SIGN_UP = 'sign-up',
  VOLUNTEERING = 'volunteering',
  VOLUNTEERING_VERIFY = 'volunteering-verify',
  MEMBERSHIP = 'membership',
  KIOSK = 'kiosk',
  MENTORSHIP = 'mentorship',
  CUSTOM_GROUP = 'custom-group',
  TaskRequester = 'task-requester',
  TaskRecipient = 'task-recipient',
  TaskVolunteer = 'task-volunteer',
  MENTEE = 'mentee',
  SELF_SERVER_PURCHASE = 'self-serve-purchase',
  MESSAGE_QUESTIONNAIRE = 'message-questionnaire',

  // synthetic is not actually saved in the DB
  SYNTHETIC = 'synthetic',
}

export enum InvitationStatus {
  CREATED = 'created',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  NOT_SENT = 'not-sent',
  REVOKED = 'revoked',
  EXPIRED = 'expired',
  OPENED = 'opened',
  VIEWED = 'viewed',
  SPAM_FILTERED = 'spam-filtered',
  ERRORED = 'errored',
  BOUNCED = 'bounced',
}

export enum InvitationDisplayStatus {
  CREATED = 'created',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  NOT_SENT = 'not-sent',
  REVOKED = 'revoked',
  EXPIRED = 'expired',
  RECEIVED = 'received',
  SPAM_FILTERED = 'spam-filtered',
  ERRORED = 'errored',
  BOUNCED = 'bounced',
}

export const InvitationStatusToInvitationDisplayStatus = (
  invitationStatus: InvitationStatus,
): InvitationDisplayStatus => {
  if (invitationStatus === InvitationStatus.OPENED || invitationStatus === InvitationStatus.VIEWED) {
    return InvitationDisplayStatus.RECEIVED
  }
  return invitationStatus as unknown as InvitationDisplayStatus
}

// intentionally excluding 'not sent', 'spam filtered', 'errored', 'bounced' so that they do not show up in the isActive filter so that the invite is resendable
export const inProgressStatuses = [
  InvitationStatus.CREATED,
  InvitationStatus.PENDING,
  InvitationStatus.OPENED,
  InvitationStatus.VIEWED,
]

export const resendableStatuses = [
  InvitationStatus.NOT_SENT,
  InvitationStatus.SPAM_FILTERED,
  InvitationStatus.ERRORED,
  InvitationStatus.BOUNCED,
  InvitationStatus.REJECTED,
  InvitationStatus.REVOKED,
  InvitationStatus.EXPIRED,
]

export interface InvitationPayload {
  id?: number
  invitationType: InvitationType
  invitationStatus?: InvitationStatus
  secureToken: string
  registrationId?: number
  groupId?: number
  method: SendMethod
  invitingOrganizationId: number
  recipientPersonId: number
  recipientGivenName: string
  recipientFamilyName: string
  recipientEmail?: string
  recipientPhoneNumber?: string
  recipientHomePhoneNumber?: string
  expiresAt?: Date
  completedAt?: Date
}

export interface Invitation
  extends Omit<InvitationPayload, 'recipientPersonId' | 'registrationId' | 'invitingOrganizationId' | 'groupId'> {
  createdAt: Date
  person: Person
  registration?: {
    id: number
    name: string
    date: Date
  }
  group?: NamedRef
  organization?: OrgNamedRef
  isResendable?: boolean
  isRevocable?: boolean
  filters?: PrerequisiteFilters
  displayStatus?: InvitationDisplayStatus
}

export interface InvitationStatusChangeEventPayload {
  id?: number
  invitationId: number
  occurredAt?: Date
  event?: string
  actorId?: number
  newStatus: InvitationStatus
}

export interface InvitationStatusChangeEvent extends InvitationStatusChangeEventPayload {
  occurredAt: Date
}

export enum SendMethod {
  EMAIL = 'email',
  PHONE = 'phone',
  DO_NOT_CONTACT = 'do-not-contact',
}

export interface InvitationSentEvent {
  id?: number
  invitationId: number
  occurredAt?: Date
  recipientContact: string
  method: SendMethod
  errorMessage?: string
}

export interface InvitationFilters {
  invitationType?: InvitationType
  invitationStatus?: InvitationStatus
  invitingOrganizationId?: number
  recipientPersonId?: number
  completed?: boolean
  secureToken?: string
  isActive?: boolean
  registrationId?: number
  groupId?: number
}

export type AcceptOrRejectInvitationTypes = 'accept' | 'reject'

export interface Profile {
  email?: string
  phoneNumber?: string
  givenName: string
  familyName: string
  homePhoneNumber?: string
}
