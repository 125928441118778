import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import cn from 'classnames'
import { InputAdornment } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: ({ smaller }: { smaller?: boolean }) => ({
      marginTop: '10px',
      marginBottom: '10px',
      color: '#3d3d3d',
      '& label.MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled)': {
        transform: 'translate(16px, 14px) scale(1)',
      },
      '& label.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '& label.MuiFormLabel-filled': {
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '&$moreHeight': {
        '& > div': {
          lineHeight: '24px',
          '& input.MuiOutlinedInput-input': {
            fontFamily: 'Nunito, sans-serif',
            padding: '18px 16px',
          },
        },
      },
      '&$moreHeight:not($newStyles)': {
        '& label': {
          lineHeight: '26px',
        },
      },
      '& > div': {
        fontSize: '16px',
        lineHeight: '20px',
        minHeight: smaller ? '41px' : '45px',
        '& input.MuiOutlinedInput-input': {
          fontFamily: 'Nunito, sans-serif',
          padding: smaller ? '8.5px 10px' : '14px 16px',
        },
        '& fieldset': {
          borderColor: 'rgb(175, 188, 213)',
        },
        '&:hover fieldset': {
          borderColor: 'rgb(175, 188, 213)',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.87) !important',
          border: '1px solid !important',
        },
      },
      '&.marginless': {
        margin: 0,
      },
    }),
    label: {
      fontFamily: 'Nunito, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '18px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.5px',
    },
    multiline: {
      padding: '8px',
    },
    // @ts-ignore
    inputMultiline: {
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#74777F',
        borderRadius: '4px',
      },
      scrollbarColor: '#74777F transparent',
      scrollbarWidth: 'thin',
    },
    icon: {
      fontSize: '16px',
      cursor: 'pointer',
      opacity: 0,
      transition: 'opacity 200ms ease-in-out',
      '&:hover': {
        opacity: 1,
        transition: 'opacity 200ms ease-in-out',
      },
    },
    shrinkHidden: {
      display: 'none',
      '& ~ div > fieldset > legend': {
        display: 'none',
      },
    },
    moreHeight: {},
    newStyles: {},
    error: {
      borderColor: '#f44336 !important',
      color: '#f44336 !important',
    },
  }),
)

export default function StyledOutlinedTextField({
  moreHeight,
  marginless,
  smaller,
  onClear,
  maxLength,
  hideLabelOnShrink,
  className,
  newStyles,
  ...props
}: any) {
  const classes = useStyles({ smaller })
  const InputProps = {
    ...props.InputProps,
    classes: { multiline: classes.multiline, inputMultiline: classes.inputMultiline, error: classes.error },
  }
  if (onClear) {
    InputProps.endAdornment = (
      <InputAdornment position="end">
        <CloseIcon className={classes.icon} onClick={onClear} />
      </InputAdornment>
    )
  }
  const inputProps = {
    ...props.inputProps,
    maxLength,
  }

  return (
    <TextField
      {...props}
      classes={{ root: cn(classes.root, { [classes.moreHeight]: moreHeight, [classes.newStyles]: newStyles }) }}
      className={cn({ marginless: marginless }, className)}
      InputLabelProps={{
        classes: {
          root: classes.label,
          error: classes.error,
          ...(hideLabelOnShrink ? { shrink: classes.shrinkHidden } : {}),
        },
      }}
      InputProps={InputProps}
      FormHelperTextProps={{ classes: { error: classes.error } }}
      inputProps={inputProps}
      variant="outlined"
    />
  )
}
