import React, { useCallback } from "react";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import getErrorMessage from "../../utils/getErrorMessage";

const useStyles = makeStyles(() => ({
  linkButton: {
    color: "white",
    fontWeight: 700,
  },
}));

export const useErrorNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();

  return useCallback(
    (msg, error) => {
      const showAlert = () => window.alert(getErrorMessage(error));
      const key = enqueueSnackbar(msg, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        persist: true,
        action: (key) => (
          <>
            <Button className={classes.linkButton} onClick={showAlert}>
              Details
            </Button>
            <Button
              className={classes.linkButton}
              onClick={() => closeSnackbar(key)}
            >
              Dismiss
            </Button>
          </>
        ),
      });

      return key;
    },
    [enqueueSnackbar, classes.linkButton, closeSnackbar]
  );
};

export default useErrorNotification;
