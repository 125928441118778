import * as React from 'react'
import { Field } from 'formik'

import QuestionSkeleton from '../question-skeleton'
import { QuestionFormBaseProps } from '../../../../types'
import { InputTextField } from '../../../../../formik/components'

interface EmailQuestionProps extends QuestionFormBaseProps {
  fieldName: string
}

const EmailQuestion = (props: EmailQuestionProps) => {
  const { item, fieldName, readonly } = props

  return (
    <QuestionSkeleton questionTitle={item.question} isRequired={item.isRequired}>
      <Field
        component={InputTextField}
        name={fieldName}
        disabled={readonly || item.disabled}
        type="email"
        label="Email"
      />
    </QuestionSkeleton>
  )
}

export default EmailQuestion
