import cn from 'classnames'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button, { ButtonProps } from '@material-ui/core/Button/Button'
import Loader from './loader'
import './ContainedButton.scss'

const useStyles = makeStyles({
  button: {
    backgroundColor: '#3776CF',
    color: '#fff',
    textTransform: 'none',
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    borderRadius: '100px',
    padding: '8px 24px 8px 24px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'rgb(64, 98, 162)',
    },
  },
  icon: {
    color: '#FFF',
  },
})

interface ContainedButtonProps extends Omit<ButtonProps, 'variant' | 'color'> {
  isLoading?: boolean
}

export const ContainedButton: React.FC<ContainedButtonProps> = ({
  children,
  className,
  disabled,
  isLoading,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Button
      {...props}
      variant="contained"
      color="secondary"
      disabled={disabled || isLoading}
      className={cn(classes.button, 'contained-button', className)}
      classes={{
        ...props.classes,
        startIcon: classes.icon,
        endIcon: classes.icon,
      }}
    >
      {isLoading && <Loader width={18} height={18} color="#FFFFFF" />}
      {children}
    </Button>
  )
}

export default ContainedButton
