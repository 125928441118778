export const ROUTE_NAMES = {
  // Public routes
  allEvents: (orgId: number | string) => `/organizations/${orgId}/opportunities`,
  eventDetails: (orgId: number | string, eventId: number | string) =>
    `/organizations/${orgId}/opportunities/${eventId}`,
  occurrenceDetails: (orgId: number | string, encodedOccurrence: string) =>
    `/organizations/${orgId}/opportunities/${encodedOccurrence}`,
  eventRegistrationRefactor: (orgId: number | string, encodedOccurrence: string, token?: string) =>
    `/organizations/${orgId}/opportunities/${encodedOccurrence}/event-registration${token ? '/' + token : ''}`,
  notFound: () => '/not-found',

  // User routes
  myEvents: (orgId: number | string) => `/organizations/${orgId}/my/activities`,
  myEventDetails: (orgId: number | string, eventId: number | string) => `/organizations/${orgId}/activities/${eventId}`,
}
