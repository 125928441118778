import React from 'react'
import { FormControl } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
// @ts-ignore
import CheckBoxOutlineBlankIcon from '@material-ui/core/internal/svg-icons/CheckBoxOutlineBlank'
// @ts-ignore
import CheckBoxIcon from '@material-ui/core/internal/svg-icons/CheckBox'
// @ts-ignore
import IndeterminateCheckBoxIcon from '@material-ui/core/internal/svg-icons/IndeterminateCheckBox'

const useStyles = makeStyles({
  root: {
    padding: '5px 9px 9px 9px',
  },
  label: {
    fontSize: '14px',
    fontFamily: 'Nunito',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    cursor: 'pointer',
  },
  icon: {
    fontSize: '20px',
  },
})
export default function StyledCheckboxField({ form, field, labelstyle = {}, label, ...props }: any) {
  const { name, value } = field
  const helperText = form.errors[name]
  const error = !!helperText
  const classes = useStyles()

  return (
    <FormControl error={error} className="checkbox-container">
      <label className={classes.label}>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon className={classes.icon} />}
          checkedIcon={<CheckBoxIcon className={classes.icon} />}
          indeterminateIcon={<IndeterminateCheckBoxIcon className={classes.icon} />}
          classes={{ root: classes.root }}
          color="primary"
          {...props}
          checked={value}
          onChange={() => form.setFieldValue(name, !value)}
        />
        {label}
      </label>
    </FormControl>
  )
}
