import { MutableRefObject, useCallback } from 'react'

export const useHandleBlur = (
  refs: MutableRefObject<HTMLDivElement | null>[] | MutableRefObject<HTMLDivElement | null>,
  onBlur: () => void,
) => {
  return useCallback(() => {
    const isFocused = (): boolean =>
      (Array.isArray(refs) ? refs : [refs]).reduce(
        (acc: boolean, ref: MutableRefObject<HTMLDivElement | null>) =>
          acc || ref.current === document.activeElement || !!ref.current?.contains(document.activeElement),
        false,
      )
    // Need to ensure that render happened before checking focus
    window.requestAnimationFrame(() => {
      if (!isFocused()) {
        onBlur()
      }
    })
  }, [onBlur]) // eslint-disable-line react-hooks/exhaustive-deps
}
