import { camelCase, mapKeys, snakeCase } from 'lodash'

export const mapCaseToDifferentCase = (converter: (k?: string) => string) => (rawObject: { [key: string]: any }) => {
  // note: only converts if there is any object to convert - otherwise, it just return {} with is truthy, when it should be falsey
  return rawObject && mapKeys(rawObject, (value, key) => converter(key))
}

export const mapToSnakeCaseKeys = mapCaseToDifferentCase(snakeCase)

export const mapToCamelCaseKeys = mapCaseToDifferentCase(camelCase)
