import React, { useCallback} from 'react'
import { makeStyles, IconButton, Modal } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import WaiversToSign from './WaiversToSign'
import { isRNWebView } from '../../constants/system'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    alignSelf: 'flex-end',
    width: '60px',
    height: '60px',
    borderRadius: 0,
    color: 'white',
    backgroundColor: '#FF6F3E',
  },
}))

export default function WaiverPrompt(props) {
  const { showing, complete, waivers, user } = props
  const handleClose = useCallback(() => complete(false), [complete])
  const classes = useStyles()
  return (
    <Modal open={showing} className={classes.modal}>
      {!isRNWebView && (
        <IconButton className={classes.icon} aria-label="close" onClick={handleClose}>
          <Close />
        </IconButton>
      )}
      <WaiversToSign complete={complete} waivers={waivers} user={user} />
    </Modal>
  )
}
