import * as React from 'react'
import { compose, withProps } from 'recompose'
import { withGoogleMap, Marker, Circle, GoogleMap } from 'react-google-maps'
import { RefObject } from 'react'
import { GeoCoordinates } from './utils';
import { DEFAULT_GEOFENCING } from '../../constants/GEO_DATA';

// https://github.com/tomchentw/react-google-maps/blob/master/src/constants.js#L1
// https://stackoverflow.com/questions/44296040/accessing-map-reference-using-react-google-maps-v6
// Don't konw why the constants are not exported
export const MAP = `__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`

const GOOGLE_MAP_PARAMS = {
  containerElement: <div style={{ height: `100%`, minHeight: 300 }} />,
  mapElement: <div style={{ height: `100%` , minHeight: 300 }} />,
}

interface ReactGoogleMapProps {
  coordinates?: GeoCoordinates
  geofenceRadius?: number
  zoom?: number
  onMounted?: (input: any) => any
}

class RenderGoogleMapComponent extends React.Component<ReactGoogleMapProps> {
  componentDidMount() {
    this.getFitBounds()
  }

  componentDidUpdate(prevProps: ReactGoogleMapProps) {
    if (this.props.geofenceRadius !== prevProps.geofenceRadius) {
      this.getFitBounds()
    }
  }

  shouldComponentUpdate(nextProps: Readonly<ReactGoogleMapProps>, nextState: Readonly<{}>, nextContext: any): boolean {
    return (
      this.props.geofenceRadius !== nextProps.geofenceRadius ||
      this.props.zoom !== nextProps.zoom ||
      this.props.coordinates?.lat !== nextProps.coordinates?.lat ||
      this.props.coordinates?.lng !== nextProps.coordinates?.lng
    )
  }

  private mapRef: GoogleMap | undefined
  private circleRef: RefObject<Circle> = React.createRef()

  render() {
    const {
      coordinates = DEFAULT_GEOFENCING.location.coordinates,
      geofenceRadius,
      zoom = 14,
    }: ReactGoogleMapProps = this.props

    const circleColor = '#ffd600'

    const circleOptions = {
      center: coordinates,
      strokeColor: circleColor,
      strokeOpacity: 0.5,
      strokeWeight: 0,
      fillColor: circleColor,
      fillOpacity: 0.5,
    }

    return (
      <GoogleMap
        ref={this.onMapMounted.bind(this)}
        defaultZoom={12}
        defaultOptions={{ disableDefaultUI: true }}
        zoom={zoom}
        center={coordinates}
      >
        {!!geofenceRadius && (
          <Circle ref={this.circleRef} defaultRadius={0} radius={geofenceRadius} options={circleOptions} />
        )}
        <Marker position={coordinates} />
      </GoogleMap>
    )
  }

  private getFitBounds = () => {
    if (this.mapRef && this.circleRef.current) {
      const circle = this.circleRef.current
      const bounds = circle.getBounds()
      this.mapRef.fitBounds(bounds)
    }
  }

  private onMapMounted(map: any) {
    if (map) {
      this.props.onMounted?.(map)
      this.mapRef = map.context[MAP]
    }
  }
}

const ReactGoogleMap = compose<ReactGoogleMapProps, ReactGoogleMapProps>(
  withProps(GOOGLE_MAP_PARAMS),
  withGoogleMap,
)(RenderGoogleMapComponent)

export default ReactGoogleMap
