import { pick } from 'lodash'
import moment from 'moment'
import React from 'react'

import './styles.scss'

import { Loader } from '../../kit/components/loader'
import SearchFilters from '../../kit/modules/filter-search'
import { EventCard } from '../../kit/modules/events/event-card'
import Calendar from '../../kit/modules/events/calendar'
import PaginationComponent from '../../kit/components/pagination'
import ENDPOINT_ROUTES from '../../constants/APIs'
import Popup from '../../kit/components/popup'
import { Button } from '../../kit/components/button'
import { withEventListDispatch, EventListDispatch } from '../../contexts/EventListContext'
import { withFeatureEnabled } from '../../contexts/FeatureContext'

interface Match {
  params: any
  isExact: boolean
  path: string
  url: string
}

interface Props {
  history?: History | any
  location?: any
  match?: Match
  getJSON?: (api: string) => void
  eventListDispatch: EventListDispatch
}

interface EventListType {
  searchValue: string
  viewType: string
  eventList: any // TODO select type for this
  activePage: number
  itemsPerPage: number
  pageRangeDisplayed: number
  offset: number
  limit: number
  date: any // TODO select type for this
  loading: boolean
  showPopup: boolean
}

export const defaultDate = {
  value: {
    from: '',
    to: '',
  },
  label: 'All time',
}

// TODO we should transform the API response for /activities vs /organization/:id/opportunities so that the
//     event card lists can have similar input shape
class VolunteersMyEventsListScene extends React.Component<Props | any, EventListType> {
  constructor(props: Props) {
    super(props)
    this.state = {
      date: defaultDate,
      searchValue: '',
      viewType: 'table',
      eventList: null,
      activePage: 1,
      itemsPerPage: 5,
      pageRangeDisplayed: 5,
      offset: 0,
      limit: 5,
      loading: false,
      showPopup: false,
    }
  }
  onSearchChange = (value: string) => {
    this.setState({ searchValue: value })
  }

  onDateChange = (options: any) => {
    if (options.value === 'customDate') {
      return this.setState({ date: options })
    }
    return this.setState({ date: options }, () => this.getEventsList())
  }

  resetSearchValue = () => {
    this.setState({ date: defaultDate })
    this.setState({ searchValue: '' }, () => this.getEventsList())
  }

  handlePageChange = (value: number) => {
    this.setState({ activePage: value }, () => this.getEventsList())
  }

  getEventsList = async () => {
    this.setState({ loading: true })
    let allParams
    const offset = (this.state.activePage - 1) * this.state.limit
    const searchText = this.state.searchValue || ''
    if (this.state.date.value === '') {
      allParams = { search: searchText, offset, limit: this.state.limit }
    } else {
      const dates = {
        startDate: this.state.date.value.from,
        endDate: this.state.date.value.to,
      }

      allParams = {
        search: searchText,
        startDate: dates.startDate,
        endDate: dates.endDate,
        offset,
        limit: this.state.limit
      }
      
    }
    try {
      const endpoint = ENDPOINT_ROUTES.Events.getMyEventsOccurrenceRefactor(allParams)
      const response: any = await this.props.getJSON(endpoint)
      if (!response.statusCode) {
        const filteredResponse = response.filter(
          ({ opportunity, organization }: { opportunity: any; organization: any }) =>
            opportunity !== null && organization !== null,
        )

        this.props.eventListDispatch({ type: 'registrations', payload: filteredResponse })
        this.setState({ eventList: filteredResponse })
        this.setState({ loading: false })
      }
      if (response.statusCode === 401) {
        this.setState({ eventList: [] })
        this.setState({ loading: false })
        this.setState({ showPopup: true })
      }
    } catch (e) {
      console.log('err:', e)
    }
  }

  toggleView = (type: string) => {
    localStorage.setItem('type', type)
    if (type === 'table') {
      this.setState(
        {
          date: {
            value: { from: '', to: '' },
            label: 'Any Date',
          },
        },
        () => this.getEventsList(),
      )
    } else {
      this.setState({ date: defaultDate }, () => this.getEventsList())
    }
    this.setState({ viewType: type })
  }

  componentDidMount() {
    localStorage.setItem('type', 'table')
    this.getEventsList()
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.getEventsList()
    }
  }

  render() {
    const { eventList, loading } = this.state
    const date =
      this.state.date.value.from === ''
        ? moment().format('MMMM YYYY')
        : moment(this.state.date.value.from).format('MMMM YYYY')

    const currentDate = this.state.date.value.from === '' ? new Date() : new Date(this.state.date.value.from)
    const view = localStorage.getItem('type') || 'table'

    const BLUE_ICON = '#628AD6'
    const GREY_ICON = '#B1BED6'

    const showDaterange = () => {
      if (this.state.date.value.from) {
        if (this.state.date.value.from !== this.state.date.value.to) {
          return (
            <div>
              {moment(this.state.date.value.from).format('DD MMMM')} -{' '}
              {moment(this.state.date.value.to).format('DD MMMM')}
            </div>
          )
        } else {
          return <div>{moment(this.state.date.value.from).format('DD MMMM')}</div>
        }
      }
    }

    const orgId = this.props.match.params.organizationId

    const view_strategy: any = () => {
      return {
        list: (
          <>
            {eventList.map((item: any, key: number) => {
              const address = pick(item.opportunity, ['address', 'city', 'state', 'zip'])
              return (
                <div className="event_list_block_content_items" key={key}>
                  <EventCard
                    title={item.opportunity.name}
                    subtitle={item.organization.name}
                    address={address}
                    dateStart={item.opportunity.startsAt}
                    dateEnd={item.opportunity.endsAt}
                    description={item.opportunity.description}
                    timeShifts={item.opportunity.timeshifts}
                    timeZone={item.opportunity.organization.timeZone}
                    details={item.details}
                    eventId={item.id}
                    item={item}
                    isMyEvent={true}
                    orgId={orgId}
                  />
                </div>
              )
            })}
            {eventList.length > this.state.itemsPerPage ? (
              <PaginationComponent
                onChange={this.handlePageChange}
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.itemsPerPage}
                pageRangeDisplayed={this.state.pageRangeDisplayed}
                totalItemsCount={eventList.length}
              />
            ) : null}
          </>
        ),
        table: (
          <div className="event_list_block_content_items">
            <Calendar date={currentDate} events={eventList} isMyEvents={true} />
          </div>
        ),
      }
    }

    return !!eventList && !loading ? (
      <>
        <div className="event_list_wrapper">

          <Popup open={this.state.showPopup} clickOutside={false} width="580px" height="450px" radius="4px">
            <div className="event_list_wrapper_popup">
              <h1 className="event_list_wrapper_popup_title">Unauthorized</h1>
              <p className="event_list_wrapper_popup_content">Please sign in to see your events.</p>
              <Button
                label="Sign In"
                onClick={() => {
                  this.setState({ showPopup: false })
                  this.props.setOpenLogin(true)
                }}
                type="primary"
                colorShadow="none"
                className="event_list_wrapper_popup_button"
                isBold={true}
                fontSize="18px"
              />
            </div>
          </Popup>

          <div className="event_list_block">
          <div className="event_list_title"><b>My Events</b></div> 
            <div className="event_list_block_filters">
              <SearchFilters
                title=""
                onSearchAction={this.getEventsList}
                showMonthPagination={view === 'table'}
                onChangeSearch={this.onSearchChange}
                searchValue={this.state.searchValue}
                dateValue={this.state.date}
                onChangeDate={this.onDateChange}
                resetSearch={this.resetSearchValue}
                hideDatePicker={view === 'table'}
                hideResetButton={view === 'table'}
                hideInputSearch={view === 'table'}
                monthTitle={date}
              />
            </div>
            <div className="event_list_block_content">
              <div className="event_list_block_content_view_bar">
                {showDaterange()}
                <div className="event_list_block_content_view_bar_date">{view === 'table' && date}</div>
                <div className="event_list_block_content_view_bar_type">
                  <div
                    className="event_list_block_content_view_bar_list"
                    onClick={() => this.toggleView('list')}
                    style={{
                      background: view === 'list' ? GREY_ICON : BLUE_ICON,
                      pointerEvents: view === 'list' ? 'none' : 'all',
                    }}
                  />
                  <div
                    className="event_list_block_content_view_bar_table"
                    onClick={() => this.toggleView('table')}
                    style={{
                      background: view === 'table' ? GREY_ICON : BLUE_ICON,
                      pointerEvents: view === 'table' ? 'none' : 'all',
                    }}
                  />
                </div>
              </div>
              {view && eventList.length === 0 && view === 'list' ? (
                <p className="no-events">No Events Found</p>
              ) : (
                view_strategy()[view]
              )}
            </div>
          </div>
        </div>
      </>
    ) : (
      <Loader />
    )
  }
}

export default withFeatureEnabled(withEventListDispatch(VolunteersMyEventsListScene))
