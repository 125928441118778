import * as React from 'react'
import { Field } from 'formik'

import QuestionSkeleton from '../question-skeleton'
import { QuestionFormBaseProps } from '../../../../types'
import PhoneField from '../../../../../formik/components/PhoneField'

interface PhoneQuestionProps extends QuestionFormBaseProps {
  fieldName: string
  errorMessage?: string
}

const PhoneQuestion = (props: PhoneQuestionProps) => {
  const { item, error, fieldName, readonly } = props

  return (
    <QuestionSkeleton questionTitle={item.question} isRequired={item.isRequired} error={error}>
      <Field
        component={PhoneField}
        classes={{ root: 'custom-input-component' }}
        name={fieldName}
        disabled={readonly || item.disabled}
        variant="outlined"
        fullWidth
        size="small"
      />
    </QuestionSkeleton>
  )
}

export default PhoneQuestion
