import * as React from 'react'
import { QuestionFormBaseProps } from '../../../../types'
import { BaseSyntheticEvent } from 'react'
import QuestionSkeleton from '../question-skeleton'
import CheckboxComponent from '../../base/checkbox'
import InputComponent from '../../base/default-input'
import { OTHER_OPTION } from '../../../../utils'
import '../question-styles.scss'

interface CheckboxQuestionProps extends QuestionFormBaseProps {
  onChangeCheckbox: (values: any) => void
  onChangeOtherAnswer: (value: string) => void
  onBlur: () => void
}

const CheckboxQuestion = (props: CheckboxQuestionProps) => {
  const { item, onChangeCheckbox, onChangeOtherAnswer, error, onBlur, readonly } = props

  if (item.disabled) {
    return null
  }

  const optionsByIdx: { [index: string]: any } = item.options.reduce(
    (acc: { [index: string]: any }, option: any, index: number) => {
      acc[index] = option
      return acc
    },
    { [item.options.length]: OTHER_OPTION },
  )

  const indexByOption: { [index: string]: number } = item.options.reduce(
    (acc: { [index: string]: number }, option: any, index: number) => {
      acc[option] = index
      return acc
    },
    { [OTHER_OPTION]: item.options.length },
  )

  const handleChangeCheckbox = (idx: number) => () => {
    const selection = optionsByIdx[idx]
    const existingIndex = item.response.indexOf(selection)
    if (existingIndex === -1) {
      const newResponse = [...item.response, selection].sort((a, b) => indexByOption[a] - indexByOption[b])
      onChangeCheckbox(newResponse)
    } else {
      const newResponse = [...item.response]
      newResponse.splice(existingIndex, 1)
      onChangeCheckbox(newResponse)
    }
  }

  const handleChangeOtherAnswer = (event: BaseSyntheticEvent) => {
    onChangeOtherAnswer(event.target.value)
  }

  const showOtherAnswer = item.response.includes(OTHER_OPTION)

  return (
    <QuestionSkeleton questionTitle={item.question} error={error} isRequired={item.isRequired}>
      {item.options.map((option: any, index: number) => (
        <CheckboxComponent
          key={index + item.questionId}
          checked={!!item.response?.includes(option)}
          onChange={handleChangeCheckbox(index)}
          onBlur={onBlur}
          label={option}
          readonly={readonly}
        />
      ))}
      {item.includeOther && !item.options.includes(OTHER_OPTION) && (
        <CheckboxComponent
          key={OTHER_OPTION}
          checked={!!item.response?.includes(OTHER_OPTION)}
          onChange={handleChangeCheckbox(item.options.length)}
          onBlur={onBlur}
          label={OTHER_OPTION}
          readonly={readonly}
        />
      )}
      {showOtherAnswer && (
        <>
          <InputComponent value={item.otherAnswer} onChange={handleChangeOtherAnswer} />
          <div className="feedback-error-message">{(error && error.otherAnswer) || ''}</div>
        </>
      )}
    </QuestionSkeleton>
  )
}

export default CheckboxQuestion
