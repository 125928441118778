import React, { BaseSyntheticEvent, useState } from 'react'
import './styles.scss'
import Input from '../../components/input'
import { Button } from '../../components/button'
import { DatePickerComponent } from '../../components/date-picker'
import SelectComponent from '../../components/default-select'
import moment from 'moment'
import IconComponent from '../../components/icon'

interface ValueOption {
  from: string
  to: string
}

interface DateOptions {
  label: string
  value: ValueOption
}

interface Props {
  title?: string
  hideDatePicker?: boolean
  onSearchAction?: (value: string) => void
  hideInputSearch?: boolean
  onChangeDate?: (option: DateOptions) => void
  onChangeSearch?: (value: string) => void
  searchValue?: string
  dateValue?: DateOptions
  showMonthPagination?: boolean
  resetSearch?: () => void
  hideResetButton?: boolean
  monthTitle?: string // TODO added as hacky workaround to get date and month
}

function SearchFilters(props: Props) {
  const [showDatePicker, setViewDatePicker] = useState(false)
  const [showHideFilters, setShowHideFilters] = useState(true)

  const onChangeInputSearch = (event: BaseSyntheticEvent) => {
    props.onChangeSearch && props.onChangeSearch(event.target.value)
  }

  const onEnterPress = (event: any) => {
    if (event.key === 'Enter') {
      props.onSearchAction && props.onSearchAction(event.target.value)
    }
  }

  const selectOptions = [
    {
      value: {
        from: moment().format('L'),
        to: moment().format('L'),
      },
      label: 'Today',
    },
    {
      value: {
        from: moment()
          .add(1, 'days')
          .format('L'),
        to: moment()
          .add(1, 'days')
          .format('L'),
      },
      label: 'Tomorrow',
    },
    {
      value: {
        from: moment()
          .day(0)
          .format('L'),
        to: moment()
          .day(6)
          .format('L'),
      },
      label: 'This week',
    },
    {
      value: {
        from: moment()
          .weekday(7)
          .format('L'),
        to: moment()
          .weekday(13)
          .format('L'),
      },
      label: 'Next week',
    },
    {
      value: {
        from: moment(moment()
          .add(1, 'month'))
          .startOf('month')
          .format('L'),
        to: moment(moment()
          .add(1, 'month'))
          .endOf('month')
          .format('L'),
      },
      label: 'Next month',
    },
    {
      value: { from: '', to: '' },
      label: 'All time',
    },
    {
      value: 'customDate',
      label: 'Custom date',
    },
  ]

  const changeViewDatePicker = (event: any) => {
    props.onChangeDate && props.onChangeDate(event)

    if (event.value === 'customDate') {
      setViewDatePicker(true)
    }
  }

  const onChangeDateValue = (event: any) => {
    const date = {
      label: moment(event).format('L'),
      value: {
        from: moment(event).format('L'),
        to: moment(event).format('L'),
      },
    }
    
    props.onChangeDate && props.onChangeDate(date)
    setViewDatePicker(false)
  }

  const onChangeMonth = (value: string) => {
    const currentDate = props.dateValue && props.dateValue.value.from
    let dateValue: string = moment().format('L')

    if (value === 'prev') {
      dateValue = moment(currentDate || new Date())
        .subtract(1, 'month')
        .format('L')
    }
    
    if (value === 'next') {
      dateValue = moment(currentDate || new Date())
        .add(1, 'month')
        .format('L')
    }
    
    const date = {
      label: dateValue,
      value: {
        from: moment(dateValue)
          .startOf('month')
          .format('L'),
        to: moment(dateValue)
          .endOf('month')
          .format('L'),
      },
    }
    
    props.onChangeDate && props.onChangeDate(date)
  }

  return (
    <div className="search_filters_wrapper">
      <div className="search_filters_block">
        <div className="search_filters_block_title">
          {/* <span>{props.title}</span> */}
          <span className="close-filters" onClick={() => setShowHideFilters(!showHideFilters)}>
            {showHideFilters ? 'Close Filters' : 'Filters'}
            <IconComponent height={'12px'} width={'18px'} name={'close_filter_icon'} />
          </span>
        </div>
        {showHideFilters ? (
          <>
            {!props.hideInputSearch && (
              <>
                <div className="search_filters_block_input">
                  <Input
                    name="search"
                    onChange={(event: BaseSyntheticEvent) => onChangeInputSearch(event)}
                    value={props.searchValue}
                    onKeyPress={(event: any) => onEnterPress(event)}
                    placeholder="Search"
                  />
                </div>
                <div className="search_filters_block_button">
                  <Button label="Search" onClick={props.onSearchAction} />
                </div>
              </>
            )}
            {!props.hideDatePicker && (
              <>
                {showDatePicker && (
                  <div className="search_filters_block_date">
                    <DatePickerComponent onChange={onChangeDateValue} date={new Date()} />
                  </div>
                )}
                {!showDatePicker && (
                  <div className="search_filters_block_date">
                    <SelectComponent
                      value={props.dateValue || { value: '', label: '' }}
                      options={selectOptions}
                      onChange={changeViewDatePicker}
                    />
                  </div>
                )}
              </>
            )}
            {props.showMonthPagination && (<div className="month_pagination">
              <div className="month_pagination_toolbar">
                <div className="month_pagination_prev" onClick={() => onChangeMonth('prev')} />
                <div className="month_pagination_title">{props.monthTitle}</div>
                <div className="month_pagination_next" onClick={() => onChangeMonth('next')} />
              </div>
              <div className="month_pagination_today" onClick={() => onChangeMonth('today')}>
                Today
              </div>
            </div>)}
            {!props.hideResetButton && (
              <div className="search_filters_block_clear">
                <Button onClick={props.resetSearch} label="Clear Filters" />
              </div>
            )}
          </>
        ) : null}
      </div>
    </div>
  )
}

export default SearchFilters
