import * as React from 'react'
import { QuestionFormBaseProps } from '../../../../types'
import RadioButtonComponent from '../../base/radio-button'
import QuestionSkeleton from '../question-skeleton'

interface RadioQuestionProps extends QuestionFormBaseProps {
  onChange: (selectedOption: string) => void
  onBlur: () => void
}

const RadioQuestion = (props: RadioQuestionProps) => {
  const { item, onChange, error, onBlur, readonly } = props

  const handleChangeOption = (option: string) => () => onChange(option)

  if (item.disabled) {
    return null
  }

  return (
    <QuestionSkeleton questionTitle={item.question} error={error} isRequired={item.isRequired}>
      {item.options.map((option: string, index: number) => {
        return (
          <RadioButtonComponent
            key={index}
            id={`${item.questionId}${index}radio`}
            onChange={handleChangeOption(option)}
            name={`${item.questionId}radioGroup`}
            label={option}
            value={option}
            selectedValue={item.response}
            onBlur={onBlur}
            readonly={readonly}
          />
        )
      })}
    </QuestionSkeleton>
  )
}

export default RadioQuestion
