const SET_OPEN_LOGIN = 'SET_OPEN_LOGIN'

const initialState = {
  currentUser: null,
  openLogin: false,
}

export function setOpenLogin(isOpenLogin: boolean) {
  return { type: SET_OPEN_LOGIN, isOpenLogin }
}

export default function volunteerMyEventsReducer(state = initialState, action: any) {

  if (SET_OPEN_LOGIN) {
    return {
      ...state,
      openLogin: action.isOpenLogin,
    }
  }
  return state

}
