import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch/Switch'

export const BlueSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#5c8de8',
    },
    '&$checked + $track': {
      backgroundColor: '#5c8de8',
    },
  },
  checked: {},
  track: {},
})(Switch)
