import React from 'react'
import classNames from 'classnames'
import { Button, makeStyles } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'

interface Props {
  className?: string
  disabled?: boolean
  title?: string | React.ReactNode
  icon?: React.ReactNode
  onClick: Function
}

const useStyles = makeStyles(theme => ({
  addButton: {
    height: 36,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  icon: {
    marginRight: 5,
  },
}))

export const AddButton = (props: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Button
      className={classNames(classes.addButton, props.className)}
      disabled={props.disabled}
      onClick={() => props.onClick()}
      variant="contained"
    >
      {props.icon || <AddCircleIcon className={classes.icon} />}
      {props.title || 'Add'}
    </Button>
  )
}
