import { API } from 'aws-amplify'
import { ConsoleLogger as Logger, LOG_TYPE } from '@aws-amplify/core/lib/Logger'
import { backOff } from 'exponential-backoff'

export const MAX_DELAY_IN_MS = 2 * 60 * 1000
export const NUM_OF_ATTEMPTS = 15

const getConfig = (key, defaultValue) => window[key] || localStorage[key] || defaultValue

const logger = new Logger('API', getConfig('logLevel', LOG_TYPE?.error || 'error'))

export function requestWithRetry({ method = 'get', url, config = {}, api = 'civicChampsApi' }) {
  const numOfAttempts = getConfig('numOfAttempts', NUM_OF_ATTEMPTS)
  const maxDelay = getConfig('maxDelay', MAX_DELAY_IN_MS)

  return backOff(() => API[method](api, url, config), {
    delayFirstAttempt: false,
    numOfAttempts,
    maxDelay,
    retry: (e, attemptNumber) => {
      logger.error('Network error:', JSON.stringify(e))
      logger.debug(`Retring ${attemptNumber} time`)
      return e.message === 'Network Error' && attemptNumber < numOfAttempts
    },
  })
}

export default requestWithRetry
