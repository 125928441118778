import * as React from 'react'
import './styles.scss'
import Label from '../label'
import IconComponent from '../icon'
import moment from 'moment-timezone'
import { BROWSER_TIMEZONE } from '../../../constants/timezone'

interface Props {
  dateStart: string
  dateEnd: string
  timeZone: string
  address: string
  state?: string
  zip?: string
  city?: string
  isMyEvent?: boolean
}

export const TimeAndPlace = (props: Props) => {
  const { dateStart, dateEnd, address, state, zip, city, timeZone, isMyEvent } = props

  // const fullAddress = [address, city, [state, zip].join(' ')].join(', ')
  const fullAddress = city!=null && address.includes(city) ? address : [address, city, [state, zip].join(' ')].join(', ')

  const timezone = timeZone || BROWSER_TIMEZONE

  return (
    <div className="time_and_place_wrapper">
      <div className="time_and_place_block">
        <div className="time_and_place_block_row">
          <Label
            className="where_label"
            title="WHERE"
            color="secondary"
            icon={<IconComponent name="locationMark" color="white" width="11px" height="15px" />}
          />
          <div className="time_and_place_block_row_value">{fullAddress}</div>
        </div>
        <div className="time_and_place_block_row">
          <Label
            className="when_label"
            title="WHEN"
            color="secondary"
            icon={<IconComponent name="time" color="white" width="18px" height="18px" />}
          />
          {isMyEvent ? (
            <div className="time_and_place_block_row_value">
              {`${moment(dateStart)
                .tz(timezone)
                .format('dddd, MMMM Do')} at
              ${moment(dateStart)
                .tz(timezone)
                .format('h:mm a')} -
              ${moment(dateEnd)
                .tz(timezone)
                .format('h:mm a z')}`}
            </div>
          ) : (
            <div className="time_and_place_block_row_value">
              {}
              {`${moment(dateStart)
                .tz(timezone)
                .format('dddd, MMMM Do')} at
                ${moment(dateStart)
                  .tz(timezone)
                  .format('h:mm a')} -
                ${moment(dateEnd)
                  .tz(timezone)
                  .format('h:mm a z')}`}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
