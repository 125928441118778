import { makeStyles } from '@material-ui/styles'
import { Styles } from '@material-ui/styles/withStyles'

/* We can't use arguments like `label = ({variant}) => ({ ...styles })`
 because it doesn't allow using sub styles `label: {
    '&$focused, &$filled, &$withPlaceholder': {
      ...styles
    }
  }`
 */

const genericStyles: Styles<any, any> = {
  inputWrapper: {
    padding: '2px 12px 2px 12px',
    borderRadius: '4px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    position: 'relative',
    '&$focused:not($disabled), &:hover:not($disabled)': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&$disabled': {
      background: 'rgba(0,0,0,0.08)',
    },
    '&$withAdornment': {
      paddingRight: '48px',
    },
    width: '100%',
    '&$error': {
      borderColor: '#f44336',
    },
  },
  disabled: {},
  input: {
    border: 0,
    width: '100%',
    background: 'transparent',
    fontFamily: 'Nunito, sans-serif',
    padding: 0,
    '&$error': {
      '&::placeholder': {
        color: 'rgba(244, 67, 54, 0.54)',
      },
    },
    '&::placeholder': {
      position: 'absolute',
      fontFamily: 'Nunito, sans-serif',
      opacity: 1,
    },
  },
  label: {
    fontSize: '16px',
    minHeight: '45px',
    lineHeight: '45px',
    position: 'absolute',
    fontFamily: 'Nunito, sans-serif',
    color: 'rgba(0, 0, 0, 0.54)',
    borderRadius: '24px',
    pointerEvents: 'none',
    transition: 'all 0.5s',
    '&>span': {
      position: 'relative',
      zIndex: 2,
    },
    '&$focused, &$filled, &$withPlaceholder': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '13px',
      lineHeight: '13px',
      minHeight: '13px',
      left: '8px',
      top: '-7px',
      padding: '0 2px 0 6px',
      '&:before': {
        content: "''",
        width: '100%',
        background: 'white',
        display: 'block',
        height: '3px',
        position: 'absolute',
        top: 'calc(50% - 1px)',
        zIndex: 1,
        left: 0,
      },
    },
    '&$error': {
      color: '#f44336',
    },
  },
  withPlaceholder: {},
  adornment: {
    position: 'absolute',
    right: '12px',
    height: '36px',
    width: '36px',
    top: 0,
  },
  focused: {},
  filled: {},
  withAdornment: {},
  clearDiv: {
    top: '0',
    right: '6px',
    position: 'absolute',
    width: '24px',
    height: '100%',
    cursor: 'pointer',
    opacity: 0,
    transition: 'opacity 0.2s',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      opacity: 1,
    },
    '&$withAdornment': {
      right: '42px',
    },
    flexDirection: 'row-reverse',
  },
  clearIcon: {
    width: '16px',
    height: '16px',
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: '8px',
  },
  error: {},
  errorText: {
    fontSize: '12px',
    lineHeight: '12px',
    color: '#f44336',
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 400,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '12px',
  },
}

export const useFormStyles = makeStyles({
  ...genericStyles,
  input: {
    ...genericStyles.input,
    fontSize: '16px',
    minHeight: '50.4px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.87)',
    '&::placeholder': {
      fontSize: '16px',
      minHeight: '50.4px',
      lineHeight: '50.4px',
      ...(genericStyles.input as any)['&::placeholder'],
      color: '#A9ABB4',
    },
  },
  adornmentIconButton: {
    padding: '6px',
    margin: '9.2px 6px',
  },
})

export const useFilterStyles = makeStyles({
  ...genericStyles,
  label: {
    ...genericStyles.label,
    fontSize: '14px',
    minHeight: '34px',
    lineHeight: '34px',
    '&$focused, &$filled, &$withPlaceholder': {
      ...(genericStyles.label as any)['&$focused, &$filled, &$withPlaceholder'],
      top: '-15px',
      color: '#A9ABB4',
      fontSize: '10.5px',
      background: 'transparent',
    },
  },
  inputWrapper: {
    ...genericStyles.inputWrapper,
    '&$withAdornment': {
      paddingRight: '36px',
    },
  },
  clearDiv: {
    ...genericStyles.clearDiv,
    '&$withAdornment': {
      right: '30px',
    },
  },
  input: {
    ...genericStyles.input,
    fontSize: '14px',
    minHeight: '34px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    '&::placeholder': {
      ...(genericStyles.input as any)['&::placeholder'],
      fontSize: '14px',
      minHeight: '34px',
      lineHeight: '34px',
      color: '#A9ABB4',
    },
  },
  adornment: {
    ...genericStyles.adornment,
    width: '20px',
    display: 'flex',
  },
  adornmentIconButton: {
    padding: '0',
    margin: '0',
    '&:hover': {
      background: 'none',
    },
  },
})
