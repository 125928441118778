import React from 'react'
import { Field } from 'formik'

import QuestionSkeleton from '../question-skeleton'
import { QuestionFormBaseProps } from '../../../../types'

import { AddressField } from '../../../../../formik/components/AddressField'

interface LocationQuestionProps extends QuestionFormBaseProps {
  fieldName: string
}

const LocationQuestion = (props: LocationQuestionProps) => {
  const { item, error, fieldName, readonly } = props

  return (
    <QuestionSkeleton questionTitle={item.question} error={error} isRequired={item.isRequired}>
      <Field
        component={AddressField}
        name={`${fieldName}.location.address`}
        disabled={readonly || item.disabled}
        hideMap
        placeholder="Street Address, City, State, Zip"
        label="Address"
      />
    </QuestionSkeleton>
  )
}

export default LocationQuestion
