import React, { useCallback } from 'react'
import { useSnackbar } from 'notistack'
import Success from '../../core/snackbars/Success'
//import useChatWidget from '../../utils/useChatWidget'

const useSuccessNotification = (isNew = false, isMultiline = false) => {
  const { enqueueSnackbar } = useSnackbar()
  //const { showWidget, hideWidget } = useChatWidget()

  return useCallback(
    msg => {
      // temporarily removing this from shared so it doesn't cause issues in onboarding site
      /*
      if (isNew) {
        hideWidget()
      }
      */

      return enqueueSnackbar(msg, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: isNew ? 'right' : 'center',
        },
        ...(isNew
          ? {
              // @ts-ignore
              content: (key, message) => <Success id={key} message={message} multiline={isMultiline} />,
            }
          : {}),
        onClose: () => {
          // temporarily removing this from shared so it doesn't cause issues in onboarding site
          /*
          if (isNew) {
            showWidget()
          }
          */
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, isNew],
  )
}

export default useSuccessNotification
