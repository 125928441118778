import React, { FC, useState } from 'react'
import { Field } from 'formik'

import './styles.scss'
import { ContactFormProps } from './types'
import { SelectComponent } from '../../../../../core/'
import { InputTextField, PhoneField } from '../../../../../formik/components'

type PhoneType = 'mobile' | 'home' | 'work'

const phoneTypeToField: Record<PhoneType, string> = {
  mobile: 'phoneNumber',
  home: 'homePhoneNumber',
  work: 'workPhoneNumber',
}

export const ContactForm: FC<ContactFormProps> = props => {
  const { errors, fieldName, readonly } = props
  const [phoneType, setPhoneType] = useState<PhoneType>('mobile')

  const phoneTypes = [
    { value: 'mobile', label: 'Mobile' },
    { value: 'home', label: 'Home' },
    { value: 'work', label: 'Work' },
  ]

  return (
    <div className="contact-form">
      <div className="row">
        <Field
          component={InputTextField}
          name={`${fieldName}.givenName`}
          label="First Name"
          error={!!errors?.givenName}
          helperText={errors?.givenName}
          disabled={readonly}
        />
        <Field
          component={InputTextField}
          name={`${fieldName}.familyName`}
          label="Last Name"
          error={!!errors?.familyName}
          helperText={errors?.familyName}
          disabled={readonly}
        />
      </div>
      <div className="row">
        <Field
          component={InputTextField}
          name={`${fieldName}.relationship`}
          label="Relationship (Spouse, Parent, Child, Friend, etc.)"
          error={!!errors?.relationship}
          helperText={errors?.relationship}
          disabled={readonly}
        />
      </div>
      <div className="row">
        <SelectComponent
          className="phoneType"
          options={phoneTypes}
          value={phoneTypes.find(({ value }) => value === phoneType)}
          onChange={({ value }) => setPhoneType(value)}
          disabled={readonly}
        />
        <Field
          component={PhoneField}
          classes={{ root: 'custom-input-component' }}
          name={`${fieldName}.${phoneTypeToField[phoneType]}`}
          label="Phone"
          variant="outlined"
          fullWidth
          size="small"
          disabled={readonly}
        />
      </div>
      <div className="row">
        <Field
          component={InputTextField}
          name={`${fieldName}.email`}
          type="email"
          label="Email"
          error={!!errors?.email}
          helperText={errors?.email}
          disabled={readonly}
        />
      </div>
    </div>
  )
}
