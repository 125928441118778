import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

interface AnchorProps {
  anchorText: string
  onClick?: any
  disabled?: boolean
}

const useStyles = makeStyles(theme => ({
  anchor: {
    fontSize: '.8rem',
    padding: '5px',
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  disabled: {
    opacity: '0.5',
    // cursor: 'not-allowed',
    pointerEvents: 'none'
  }
}))

const AnchorResendCode = (props: AnchorProps) => {
  const {
    anchorText,
    disabled,
    onClick
  } = props

  const classes = useStyles()

  return (
    <a
      onClick={onClick}
      className={
        cn(classes.anchor,
          {[classes.disabled]: disabled},
        )}>
      {anchorText}
    </a>
  )
}

export default AnchorResendCode
