import cn from 'classnames'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button, { ButtonProps } from '@material-ui/core/Button/Button'

const useStyles = makeStyles({
  button: {
    color: '#0F5DB5',
    textTransform: 'none',
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    borderRadius: '100px',
    borderColor: '#0F5DB5',
    padding: '7px 23px',
    '&.borderless': {
      border: '0px',
      padding: '8px 12px',
    },
  },
  buttonRightMargin: {
    marginRight: '8px',
  },
  icon: {
    color: '#0F5DB5',
    fontSize: '18px !important',
  },
  disabledIcon: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
})

export interface OutlinedButtonProps extends Omit<ButtonProps, 'variant'> {
  iconClassName?: string
  rightMargin?: boolean
  borderless?: boolean
}

export const OutlinedButton: React.FC<OutlinedButtonProps> = ({
  children,
  className,
  disabled,
  rightMargin,
  borderless,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Button
      variant="outlined"
      className={cn(classes.button, { [classes.buttonRightMargin]: rightMargin, borderless: borderless }, className)}
      disabled={disabled}
      classes={{
        ...props.classes,
        startIcon: cn(classes.icon, props.classes?.startIcon, {
          [classes.disabledIcon]: disabled,
        }),
        endIcon: cn(classes.icon, props.classes?.endIcon, {
          [classes.disabledIcon]: disabled,
        }),
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default OutlinedButton
