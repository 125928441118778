import * as React from 'react'
import { BaseSyntheticEvent } from 'react'
import SelectComponent from '../../base/default-select'
import { QuestionFormBaseProps } from '../../../../types'
import { Question } from '../../../../types'
import QuestionSkeleton from '../question-skeleton'

interface SelectQuestionProps extends QuestionFormBaseProps {
  onChange: (event: BaseSyntheticEvent, item: Question) => void
  onBlur: () => void
}

const SelectQuestion = (props: SelectQuestionProps) => {
  const { item, onChange, error, onBlur, readonly } = props

  const handleChange = (item: Question) => (event: { value: any; label: any }) => {
    onChange(event.value, item)
  }

  if (item.disabled) {
    return null
  }

  return (
    <QuestionSkeleton questionTitle={item.question} error={error} isRequired={item.isRequired}>
      <SelectComponent
        options={item.options.map(option => ({ value: option, label: option }))}
        value={{ value: item.response, label: item.response }}
        onChange={handleChange(item)}
        onBlur={onBlur}
        readonly={readonly}
      />
    </QuestionSkeleton>
  )
}

export default SelectQuestion
