import React, { useState } from 'react'
import { PALETTE_COLORS } from '../../../constants/colors'
import IconComponent from '../../components/icon'
import VolunteerRegisterForm from './register-form'
import CongratsPopup from './congrats-popup'
import './style.scss'
import { EventModel, RoleModel, Shift } from './model'
import moment from 'moment-timezone'
import ENDPOINT_ROUTES from '../../../constants/APIs'
import ErrorPopup from './error-popup'
import BaseService from '../../../services/base.service'
import { BROWSER_TIMEZONE } from '../../../constants/timezone'
import { ROUTE_NAMES } from '../../../constants/route-names'
import { RequirementsModal } from '../../../civic-champs-shared/question-sets/components'
import { useShowPrompt } from '../../../civic-champs-shared/core/modal/hooks'
import { PersonPayload, PrerequisitesAction } from '../../../civic-champs-shared/question-sets/types'
import { omitBy, isNil } from 'lodash'
import { isRNWebView } from 'civic-champs-shared/constants/system'

interface Props {
  event: EventModel
  roles: RoleModel[]
  registrations: []
  isEditMode: boolean
}

export function cognitoAttrsToCognitoUser(attrs: any): PersonPayload {
  const allAttributes = {
    phoneNumber: attrs.phone_number || attrs['custom:alt_phone_number'],
    cognitoSub: attrs.sub,
    email: attrs.email,
    givenName: attrs.given_name,
    familyName: attrs.family_name,
    emailVerified: attrs.email_verified,
    phoneNumberVerified: attrs.phone_number_verified,
  }
  // @ts-ignore
  return omitBy(allAttributes, isNil)
}

const baseService = new BaseService()

export default function VolunteerRegistrationModule(props: Props | any) {
  const [registration, setRegistration] = useState<any | null>(null)
  const [congratsPopup, setCongratsPopup] = useState(false)
  const [errorPopup, setErrorPopup] = useState(false)
  const { event, isEditMode, match } = props
  const [isLoading, setIsLoading] = useState(false)
  const encodedOccurrence = match.params.eventId

  const eventName = isEditMode ? event.opportunity.name : event.name
  const orgName = isEditMode ? event.opportunity.organization.name : event.organization.name
  const website = isEditMode ? event.opportunity.organization.website : event.organization.website
  const startTime = isEditMode ? event.opportunity.startsAt : event.startsAt
  const timeZone = isEditMode
    ? event.opportunity.organization.timeZone || BROWSER_TIMEZONE
    : event.organization.timeZone || BROWSER_TIMEZONE

  const orgWebsite = website ? (website.substring(0, 8) === 'https://' ? website : `https://${website}`) : ''

  const organizationId = props.match.params.organizationId

  const fulfillRequirementsPrompt = useShowPrompt(RequirementsModal)

  const handleRequirements = async (user: PersonPayload) => {
    try {
      const done = await fulfillRequirementsPrompt({
        person: user,
        filters: {
          associatedEntity: { encodedOccurrence },
          action: PrerequisitesAction.EVENT_REGISTRATION,
          freshAnswerSet: false,
          excludeCompletedAnswerSets: true,
        },
        continueEvenIfError: false,
        organizationId,
      })
      return done
    } catch (error) {
      console.error(error)
      setErrorPopup(true)
      return false
    }
  }

  const register = async () => {
    props.validate()
    const shiftsErrors = props.data.shifts.some((item: Shift) => item.error)
    const noShiftError = props.data.shiftError

    if (shiftsErrors || noShiftError) {
      return
    }

    try {
      setIsLoading(true)
      const requirementsFulfilled = await handleRequirements(cognitoAttrsToCognitoUser(props.currentUser))
      // TODO: confirm we we want to still continue https://civicchamps.atlassian.net/browse/CCA-1369
      if (!requirementsFulfilled) {
        setIsLoading(false)
        return
      }
      const endpoint = ENDPOINT_ROUTES.EventRegistration.registerOccurrenceRefactor(encodedOccurrence)

      const response = await baseService.postJSON(endpoint, {
        userId: props.currentUser.sub,
        ...getEventRegistrationData(),
      })

      if (!response.id) {
        setErrorPopup(true)
      } else {
        setRegistration(response)
        setCongratsPopup(true)
        props.clear()
      }
    } catch (error) {
      console.log('error ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const updateRegistration = async () => {
    props.validate()

    setIsLoading(true)
    try {
      // TODO: MAKE THIS ENTIRE THING WORK...RIGHT NOW, EDITING REGISTRATIONS IS DISABLED ANYWAYS
      const response = await baseService.putJSON(ENDPOINT_ROUTES.EventRegistration.updateRegistration(event.id), {
        userId: props.currentUser.sub,
        ...getEventRegistrationData(),
      })
      if ((response && response.error) || response.message || !response.id) {
        setErrorPopup(true)
      } else {
        setRegistration(response)
        setCongratsPopup(true)
      }
    } catch (error) {
      console.log('error ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getEventRegistrationData = () => {
    const details = props.data.shifts.map((shift: Shift) => ({
      timeshift_id: shift.id,
      role_id: shift.roleId,
    }))
    // TODO: MAKE THIS ENTIRE THING WORK for 'isEditMode'...RIGHT NOW, EDITING REGISTRATIONS IS DISABLED ANYWAYS
    return { details }
  }

  const handleBackToEvents = () => {
    props.clear()
    props.history.push(ROUTE_NAMES.allEvents(organizationId))
  }

  const onCloseCongratsPopup = (value: boolean) => {
    setCongratsPopup(value)

    if (isRNWebView) {
      //@ts-ignore
      window.ReactNativeWebView.postMessage('close mobile webview', '*')
    }

    registration.id && props.history.push(ROUTE_NAMES.myEventDetails(organizationId, registration.id))
  }

  return (
    <div className="volunteer-registration-page">
      {!isRNWebView && (
        <div className="header">
          <div
            className="header__back"
            style={{ color: PALETTE_COLORS.textColors.secondaryLighten }}
            onClick={handleBackToEvents}
          >
            <IconComponent
              name="polygon"
              width={14}
              height={14}
              customColor={PALETTE_COLORS.textColors.secondaryLighten}
              style={{ marginRight: 10 }}
            />
            Back to Events
          </div>
        </div>
      )}
      <div className="container">
        <div className="info" style={{ color: PALETTE_COLORS.textColors.default }}>
          <div className="title">{props.isEditMode ? 'Edit Registration' : 'Register'}</div>
          <div className="name">{eventName}</div>
          <div className="date">
            {moment(startTime)
              .tz(timeZone)
              .format('dddd, MMMM Do, YYYY')}
          </div>
          <a
            className="organization"
            style={{ color: PALETTE_COLORS.textColors.secondary }}
            href={orgWebsite}
            target="_blank"
            rel="noopener noreferrer"
          >
            {orgName}
          </a>
        </div>
        <VolunteerRegisterForm
          isEditMode={props.isEditMode}
          shifts={event.timeshifts}
          roles={props.roles}
          registrations={props.registrations}
          register={isEditMode ? updateRegistration : register}
          isLoading={isLoading}
          disableUpdate={false}
          {...props}
        />
      </div>
      {
        <CongratsPopup
          isOpen={congratsPopup}
          event={event}
          isEditMode={isEditMode}
          onClosePopup={onCloseCongratsPopup}
        />
      }
      {<ErrorPopup isOpen={errorPopup} onClosePopup={(value: boolean) => setErrorPopup(value)} />}
    </div>
  )
}
