import { Shift } from '../../kit/modules/volunteer-registration/model'

const ADD_SHIFT = 'ADD_SHIFT'
const REMOVE_SHIFT = 'REMOVE_SHIFT'
const ADD_ROLE = 'ADD_ROLE'

const VALIDATE = 'VALIDATE'

const CLEAR = 'CLEAR'

const initialState: any = {
  shifts: [],
  shiftError: '',
}

export function validate() {
  return { type: VALIDATE }
}

export function addShift(shift: Shift) {
  return {
    type: ADD_SHIFT,
    shift,
  }
}

export function addRole(shiftId: number, roleId: number) {
  return {
    type: ADD_ROLE,
    shiftId,
    roleId,
  }
}

export function removeShift(shiftId: number) {
  return {
    type: REMOVE_SHIFT,
    shiftId,
  }
}

export function clear() {
  return {
    type: CLEAR,
  }
}

export default function volunteerRegisterReducer(state = initialState, action: any) {
  const isShifts = state.shifts && state.shifts.length

  switch (action.type) {
    case VALIDATE:
      return {
        shifts: isShifts
          ? state.shifts.map((it: Shift) => {
              if (it.id && !it.roleId) {
                it.error = 'Field is required'
              }
              return it
            })
          : [],
        shiftError: !isShifts ? 'Please choose at lease one shift' : '',
      }
    case ADD_SHIFT:
      return {
        ...state,
        shiftError: '',
        shifts: state.shifts.some((shift: Shift) => shift.id === action.shift.id)
          ? state.shifts
          : [...state.shifts, action.shift],
      }
    case REMOVE_SHIFT:
      return {
        ...state,
        shifts: isShifts ? state.shifts.filter((shift: Shift) => shift.id !== action.shiftId) : [],
      }
    case ADD_ROLE:
      return {
        ...state,
        shifts: isShifts
          ? state.shifts.map((shift: Shift) =>
              shift.id === action.shiftId
                ? {
                    ...shift,
                    roleId: action.roleId,
                    error: '',
                  }
                : shift,
            )
          : [],
      }
    case CLEAR:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
