export type MainColorType =
  | 'primary'
  | 'secondary'
  | 'secondaryLight'
  | 'third'
  | 'thirdLight'
  | 'success'
  | 'error'
  | 'dark'
  | 'warning'
  | 'default'
export type TextColorType =
  | 'primary'
  | 'primaryLight'
  | 'secondary'
  | 'secondaryLight'
  | 'secondaryLighten'
  | 'third'
  | 'thirdLight'
  | 'error'
  | 'errorLight'
  | 'default'
  | 'defaultLight'
  | 'white'
  | 'gray'
export type BoxShadowColorType = 'primary' | 'secondary'
export type BackgroundColorType = 'primary' | 'secondary'

export type MainColors = { [key in MainColorType]: string }
export type TextColors = { [key in TextColorType]: string }
export type BoxShadowColors = { [key in BoxShadowColorType]: string }
export type BackgroundColors = { [key in BackgroundColorType]: string }

export interface Colors {
  mainColors: MainColors
  textColors: TextColors
  boxShadowColors: BoxShadowColors
  backgroundColors: BackgroundColors
}
export const PALETTE_COLORS: Colors = Object.freeze<Colors>({
  mainColors: {
    primary: '#5C8DE8',
    secondary: '#FF9900',
    secondaryLight: '#FFCD00',
    third: '#E6E9EC',
    thirdLight: '#EEF6FC',
    success: '#5CE872',
    error: '',
    dark: '#3A3D3D',
    warning: '#EE875B',
    default: '#ffffff',
  },
  textColors: {
    primary: '#212121',
    primaryLight: '#3A3D3D',
    secondary: '#386FD4',
    secondaryLight: '#4176D9',
    secondaryLighten: '#5C8DE8',
    third: '#394D69',
    thirdLight: '#7387AC',
    error: '#FF0000',
    errorLight: '#FF6F3E',
    default: '#000000',
    defaultLight: '#090909',
    white: '#FFFFFF',
    gray: '#A5B0C0'
  },
  boxShadowColors: {
    primary: '0px 4px 15px rgba(0, 0, 0, 0.25)', // Login popup
    secondary: '0px 4px 7px rgba(100, 124, 142, 0.18)', // Cards
  },
  backgroundColors: {
    primary: '#F8F8F8',
    secondary: ''
  }
})
