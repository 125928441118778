import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { withFormik, FormikProps, FormikValues, FormikBag } from 'formik'
import { object, string } from 'yup'
import Popup from '../../../../components/popup/index'
import { Button } from '../../../../components/button'
import { PALETTE_COLORS } from '../../../../../constants/colors'
import IconComponent from '../../../../components/icon'
import { passwordValidationSchema } from '../../../../../helpers/validation'
import Input from '../../../../components/input'

import './style.scss'

const resetPasswordValidationSchema = object().shape({
  firstPass: passwordValidationSchema,
  verificationCode: string().required('Please enter the code that was sent to your contact method.'),
})

const initialFormValues = {
  firstPass: '',
  verificationCode: '',
}

interface FormValues {
  firstPass: string
  verificationCode: string
}

interface FormErrors {
  firstPass?: string
  verificationCode?: string
}

interface PopupProps {
  username: string
  isOpen: boolean
  closePopup: (action: boolean) => void
  onResetPassword: () => void
}

interface FormProps {
  username: string
  isOpen: boolean
  closePopup: (action: boolean) => void
}

const ResetPasswordForm = (props: FormProps & FormikProps<FormValues>) => {
  const {
    closePopup,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isOpen,
    isSubmitting,
    isValid,
    resetForm,
    setFieldError,
    setSubmitting,
    touched,
    username,
    values,
  } = props
  const [firstPassSecurity, setFirstPassSecurity] = useState(false)

  const hasError = (keyName: keyof FormErrors) => (errors[keyName] && touched[keyName] ? true : false)
  const getErrorMessage = (keyName: keyof FormErrors) => (hasError(keyName) ? errors[keyName] : '')

  const resendCode = async () => {
    setSubmitting(true)

    try {
      await Auth.forgotPassword(username)
    } catch (error) {
      setFieldError('verificationCode', error.message)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Popup
      closeIcon={true}
      height="555px"
      width="498px"
      radius={'4px'}
      open={isOpen}
      clickOutside={true}
      closePopup={() => {
        resetForm()
        closePopup(false)
      }}
    >
      <form className="reset-popup">
        <IconComponent name={'logo'} width={176} height={60} customColor={PALETTE_COLORS.mainColors.primary} />
        <div className="reset-popup__title" style={{ color: PALETTE_COLORS.textColors.default }}>
          Reset Password
        </div>
        <div className="reset-popup__text" style={{ color: PALETTE_COLORS.textColors.default }}>
          Enter verification code and a new password below
        </div>
        <div className="reset-popup__form">
          <div className="reset-popup__form__input reset-popup__form__input_custom">
            <Input
              hasError={hasError('verificationCode')}
              errorMessage={getErrorMessage('verificationCode')}
              tag={'input'}
              placeholder={'Verification code'}
              onChange={handleChange}
              onBlur={handleBlur}
              name={'verificationCode'}
              type={'text'}
              value={values.verificationCode}
            >
              <div className="input_button" onClick={() => resendCode()}>
                <Button label="Resend" type="secondary" labelColor="#FFFFFF" width="123px" height="35px" />
              </div>
            </Input>
          </div>
          <div className="reset-popup__form__input">
            <Input
              hasError={hasError('firstPass')}
              errorMessage={getErrorMessage('firstPass')}
              tag={'input'}
              placeholder={'Password'}
              onChange={handleChange}
              onBlur={handleBlur}
              name={'firstPass'}
              type={firstPassSecurity ? 'text' : 'password'}
              value={values.firstPass}
              children={
                <div onClick={() => setFirstPassSecurity(!firstPassSecurity)}>
                  <IconComponent
                    height={!firstPassSecurity ? 15 : 23.3}
                    width={22}
                    style={{ marginTop: 7 }}
                    customColor={'#AFBCD5'}
                    name={!firstPassSecurity ? 'open_eye' : 'closed_eye'}
                  />
                </div>
              }
            />
          </div>
          <div className="reset-popup__form__btn">
            <Button
              label={'Reset Password'}
              type={'secondary'}
              height={'46px'}
              width={'auto'}
              fontSize={'16px'}
              labelColor={PALETTE_COLORS.textColors.white}
              onClick={handleSubmit}
              style={{ background: isValid ? '#5C8DE8' : '#B1BED6' }}
              disabled={!isValid}
              isBold={true}
              loading={isSubmitting}
            />
          </div>
        </div>
      </form>
    </Popup>
  )
}

const handleResetPassword = async (
  values: FormikValues,
  formikBag: FormikBag<PopupProps, FormValues>,
): Promise<void> => {
  const { firstPass, verificationCode } = values
  const { props, setFieldError, setSubmitting } = formikBag

  try {
    await Auth.forgotPasswordSubmit(props.username, verificationCode.trim(), firstPass)
    await Auth.signIn(props.username, firstPass)

    props.onResetPassword()
  } catch (error) {
    setFieldError('verificationCode', error.message)
  } finally {
    document.body.classList.remove('disable-scroll')
    setSubmitting(false)
  }
}

const ResetPassword = withFormik<PopupProps, FormValues>({
  mapPropsToValues: () => initialFormValues,
  validationSchema: resetPasswordValidationSchema,
  handleSubmit: handleResetPassword,
})(ResetPasswordForm)

export default ResetPassword
