import * as React from 'react'

import ErrorFallback from '../../kit/components/error-boundary/error-fallback'

interface Props {
  message?: string
}

export default function NotFound(props: Props | any): JSX.Element {
  return <ErrorFallback {...props} />
}
