import React from 'react'
import './styles.scss'
import { TimeAndPlace } from '../../../components/time-and-place-block'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ROUTE_NAMES } from '../../../../constants/route-names'

interface EventCardProps {
  eventId: string | number
  orgId: string | number
  title?: string
  subtitle?: string
  address?: any
  dateStart?: string
  dateEnd?: string
  timeZone?: string
  description?: string
  timeShifts?: any
  details?: any
  isMyEvent?: boolean
  item?: any
  link?: string
}

export class EventCard extends React.Component<EventCardProps> {
  render() {
    let availableSlotsValue: number = 0
    // TODO we should really return address as { street, city, state, zip }
    const {
      eventId,
      orgId,
      title,
      timeShifts,
      description,
      dateStart,
      dateEnd,
      address,
      isMyEvent,
      item,
      details,
      timeZone
    } = this.props

    const DESCRIPTION_LENGTH = 300
    const descriptionText =
      description && description.length > DESCRIPTION_LENGTH
        ? `${description.substring(0, DESCRIPTION_LENGTH) + '...'}`
        : description
    if (timeShifts.length) {
      const roles = timeShifts.map((item: any) => {
        return item.roles
      })
      const availableSlotsArr = roles.flat()
      const availableSlotsArrValue = availableSlotsArr.map((item: any) => {
        return item.available
      })
      availableSlotsValue = availableSlotsArrValue.reduce((a: number, b: number) => {
        return a + b
      }, 0)
      availableSlotsValue = availableSlotsValue <= 0 ? 0 : availableSlotsValue
    }
    const pastEvent = moment().isAfter(moment(dateEnd))
    const eventCardClasses = classNames('event_card_wrapper', {
      event_card_full: availableSlotsValue <= 0,
      event_card_past: pastEvent,
    })
    const buttonText = pastEvent
      ? 'Past Event'
      : availableSlotsValue <= 0
        ? 'This event is full'
        : !isMyEvent
          ? 'Learn More & Register'
          : 'View Details'

    const urlLink = this.props.link
      ? this.props.link
      : this.props.isMyEvent
        ? ROUTE_NAMES.myEventDetails(orgId, eventId)
        : ROUTE_NAMES.eventDetails(orgId, eventId)

    const createMarkup = (html: string) => {
      return { __html: html }
    }

    return (
      <div className={eventCardClasses}>
        <div className="event_card_block">
          <div className="event_card_info">
            <div className="event_card_info_left">
              <div className="event_card_info_left_title">{title}</div>
              {isMyEvent ? (
                <div className="event_card_info_left_subtitle">
                  <a href={item.opportunity.organization.website}>{item.opportunity.organization.name}</a>
                </div>
              ) : (
                <div style={{ marginBottom: '24px' }} />
              )}
              <TimeAndPlace
                {...address}
                dateStart={dateStart || ''}
                dateEnd={dateEnd || ''}
                timeZone={timeZone}
                isMyEvent={isMyEvent}
              />
              <div className="event_card_description">
                <div dangerouslySetInnerHTML={createMarkup(descriptionText as any)} />
                {description && description.length > DESCRIPTION_LENGTH && <Link to={urlLink}>more</Link>}
              </div>
            </div>
            <div className="event_card_info_right">
              <div className="event_card_info_right_slots">
                {!isMyEvent ? (
                  <>
                    <Link to={urlLink} className="event_card_info_right_button">
                      {buttonText}
                    </Link>
                    <div className="event_card_info_right_slots_available_block">
                      <div className="event_card_info_right_slots_icon" />
                      <div className="event_card_info_right_slots_available">
                        <span>{availableSlotsValue} Slots Available</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="event_card_info_right_slots_wrapper">
                    {details.map((item: any, index: number) => {
                      return (
                        <div className="event_card_info_right_slots_block" key={index}>
                          <div className="event_card_info_right_slots_single_icon" />
                          <div>
                            <div>{item.timeshift.time_start + ' - ' + item.timeshift.time_end}</div>
                            <div>
                              {' '}
                              Your role is <b>{item.role.name}</b>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div className="event_card_info_right_slots_block_text">
                      View day-of-event instructions, edit your registration, and more.
                    </div>
                    <Link to={urlLink} className="event_card_info_right_button">
                      {buttonText}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
