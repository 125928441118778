import React from 'react'
import './style.scss'
import Popup from '../../../components/popup'
import IconComponent from '../../../components/icon'
import { PALETTE_COLORS } from '../../../../constants/colors'
import { Button } from '../../../components/button'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import { EventModel } from '../model'
import FeatureEnabled from '../../features/FeatureEnabled'

interface Props {
  event: EventModel
  isOpen: boolean
  onClosePopup: (value: boolean) => void
  isEditMode: boolean
}

export default function CongratsPopup(props: Props) {
  const { isEditMode, isOpen, onClosePopup, event } = props
  const pathName = `${window.location.origin}/opportunities/${event.id}`
  const shareMessage = `I just signed up to volunteer and help ${event.organization &&
    event.organization.name} with their event ${event.name}. Join me!`
  return (
    <Popup
      closeIcon={true}
      open={isOpen}
      closePopup={() => props.onClosePopup(false)}
      height={isEditMode ? '245px' : 'fit-content'} // 435px
      width="583px"
      radius={'4px'}
    >
      <div className="congrats-popup">
        <div className="title" style={{ color: PALETTE_COLORS.textColors.default }}>
          {isEditMode ? 'Changes Saved' : 'Congratulations!'}
        </div>
        {!isEditMode && (
          <div className="text" style={{ color: PALETTE_COLORS.textColors.defaultLight }}>
            You're attending {event.name}
          </div>
        )}
        <FeatureEnabled code="SocialSharing">
          <div className="share" style={{ color: PALETTE_COLORS.textColors.defaultLight }}>
            <div className="share__title">Share</div>
            <div className="share__items">
              <div className="item">
                <FacebookShareButton url={pathName} quote={shareMessage}>
                  <IconComponent height={'47px'} width={'47px'} name={'facebook'} />
                </FacebookShareButton>
              </div>
              <div className="item">
                <TwitterShareButton url={pathName} title={shareMessage}>
                  <IconComponent height={'47px'} width={'47px'} name={'twitter'} />
                </TwitterShareButton>
              </div>
            </div>
          </div>
        </FeatureEnabled>
        <Button
          label={'Done'}
          height={'56px'}
          width={'100%'}
          type={'secondary'}
          isBold={true}
          labelColor={PALETTE_COLORS.textColors.white}
          disabled={false}
          onClick={() => {
            onClosePopup(false)
            document.body.classList.remove('disable-scroll')
          }}
        />
      </div>
    </Popup>
  )
}
