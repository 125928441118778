import moment, { Moment, unitOfTime } from 'moment'
import { stringify } from 'query-string'
import React from 'react'
import cn from 'classnames'
import { generatePath, match } from 'react-router-dom'
import * as H from 'history'
import { get, map } from 'lodash'
import { MenuItem, Select } from '@material-ui/core'
import { Hub } from 'aws-amplify'

import './styles.scss'
import styles from './styles.module.scss'
import { Loader } from '../../kit/components/loader'
import ENDPOINT_ROUTES from '../../constants/APIs'
import BaseService from '../../services/base.service'
import { EventListDispatch, withEventListDispatch } from '../../contexts/EventListContext'
import { FeatureEnabledDispatch, FeatureEnabledState, withFeatureEnabled } from '../../contexts/FeatureContext'
import { MonthView } from './MonthView'
import SearchFilters from './filter-search'
import { viewTypes } from './viewTypes'
import { WeekView } from './WeekView'
import { DayView } from './DayView'
import { paths } from '../../config/paths'
import { ValueOf } from '../../utils/typeUtils'

interface MatchParams {
  organizationId: string
  viewType?: string
}

export interface RouteComponentProps<P> {
  match: match<P>
  location: H.Location
  history: H.History
  staticContext?: any
}

interface Props extends RouteComponentProps<MatchParams> {
  // orgId: string
  // getJSON?: (api: string) => void // TODO we will want a better solution than this.  Weird
  eventListDispatch: EventListDispatch
  featureEnabledDispatch: FeatureEnabledDispatch
  featureEnabledState: FeatureEnabledState
}

interface EventListType {
  events: any
  date: Moment
  loading: boolean
  orgId: string
  organization: object | null
  viewType: ValueOf<typeof viewTypes>
}

const viewTypeToComponentMap = {
  [viewTypes.DAY]: DayView,
  [viewTypes.WEEK]: WeekView,
  [viewTypes.MONTH]: MonthView,
}

const STORAGE_KEY = 'volunteerEvents:viewType'

// Created this component to help fix the query length issue for requesting all events
// Original implementation is at ../volunteers-events-list
class VolunteersEventsScene extends React.Component<Props, EventListType> {
  request: BaseService

  constructor(props: Props) {
    super(props)
    this.request = new BaseService()

    const paramsViewType = Object.values(viewTypes).includes(String(this.props.match.params.viewType))
      ? this.props.match.params.viewType
      : null

    const viewType = paramsViewType || sessionStorage.getItem(STORAGE_KEY) || viewTypes.MONTH

    if (!paramsViewType) {
      this.props.history.replace(this.createViewUrl(sessionStorage.getItem(STORAGE_KEY) || viewTypes.MONTH))
    } else {
      sessionStorage.setItem(STORAGE_KEY, viewType)
    }

    this.state = {
      date: moment()
        .startOf(viewType as unitOfTime.StartOf)
        .startOf('day'), // TODO timezone
      events: null,
      loading: false,
      orgId: this.props.match.params.organizationId,
      organization: null,
      viewType,
    }
  }

  createViewUrl(viewType: string) {
    return generatePath(paths.CALENDAR, {
      organizationId: this.props.match.params.organizationId,
      viewType,
    })
  }

  onDateChange = (date: Moment) => {
    return this.setState({ date }, () => this.getEventsList(this.state.viewType))
  }

  getOrganization = async () => {
    const url = ENDPOINT_ROUTES.Organization.getOrganization(this.state.orgId)
    const organization = await this.request.getJSON(url)
    this.setState({ organization })
  }

  getEventsList = async (period: any = viewTypes.WEEK) => {
    this.setState({ loading: true })

    const dayQuery = {
      startDate: this.state.date.clone().startOf(period).startOf('day').format(),

      endDate: this.state.date.clone().endOf(period).endOf('day').format(),

      includeMissingRequiredMemberships: true,
    }

    const query = {
      startDate: this.state.date.clone().startOf(period).weekday(0).subtract(1, 'day').startOf('day').format(),

      endDate: this.state.date.clone().endOf(period).weekday(6).add(1, 'day').endOf('day').format(),

      includeMissingRequiredMemberships: true,
    }

    const apiUrl = ENDPOINT_ROUTES.Events.getOccurrenceRefactorEvents(
      this.state.orgId,
      stringify(period === viewTypes.DAY ? dayQuery : query),
    )

    try {
      const events = await this.request.getJSON(apiUrl)
      this.setState({ events: events.list })
    } finally {
      this.setState({ loading: false })
    }
  }

  onViewTypeChange(viewType: ValueOf<typeof viewTypes>) {
    this.setState({ viewType })
    this.getEventsList(viewType)

    sessionStorage.setItem(STORAGE_KEY, viewType)

    this.props.history.push(this.createViewUrl(viewType))
  }

  componentDidMount() {
    this.getEventsList(this.state.viewType)
    this.getOrganization()

    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
        case 'signUp':
          this.getEventsList(this.state.viewType)
          break
        default:
      }
    })
  }

  render() {
    const { date, events, loading, organization } = this.state

    const ViewComponent = viewTypeToComponentMap[this.state.viewType]

    return (
      <div>
        <div className="event_list_title">
          <b>{get(organization, 'name')}</b>
        </div>

        <div className="event_list_block_filters">
          <SearchFilters
            dateValue={date}
            onChangeDate={this.onDateChange}
            viewType={this.state.viewType as unitOfTime.DurationConstructor}
          />

          <Select
            variant="outlined"
            value={this.state.viewType}
            className={cn(styles.viewTypeSelect, {
              [styles.viewTypeSelectWeekView]: this.state.viewType === viewTypes.WEEK,
            })}
            onChange={ev => this.onViewTypeChange(ev.target.value as string)}
          >
            {map(viewTypes, (value, key) => (
              <MenuItem className={styles.viewTypeSelectItem} key={key} value={viewTypes[key]}>
                {viewTypes[key]}
              </MenuItem>
            ))}
          </Select>
        </div>

        {!events || !organization || loading ? (
          <Loader height="50vh" />
        ) : (
          <ViewComponent date={date} events={events} organization={organization} onDateChange={this.onDateChange} />
        )}
      </div>
    )
  }
}

export default withFeatureEnabled(withEventListDispatch(VolunteersEventsScene))
