import { makeStyles } from '@material-ui/core'
import { getBasicTextFieldStyles } from '../add-person/PersonAutocomplete'

export const useAutocompleteStyles = makeStyles({
  option: {
    color: '#74777F',
    padding: '4px 12px',
    fontFamily: 'Nunito',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    fontWeight: 400,
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
  groupLabel: {
    // @ts-ignore
    color: '#001B3F',
    fontWeight: 400,
    padding: '4px 12px',
    fontFamily: 'Nunito',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  listbox: {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#74777F',
      borderRadius: '4px',
    },
    scrollbarColor: '#74777F transparent',
    scrollbarWidth: 'thin',
  },
  autoComplete: {
    width: '100%',
  },
  addNewItem: {
    display: 'flex',
    alignItems: 'center',
    color: '#74777F',
    padding: '4px 12px 18px 12px',
    fontFamily: 'Nunito',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    fontWeight: 400,
    cursor: 'pointer',
    borderTop: '1px solid #C4C6CF',
    '&:hover': {
      background: '#EBEBEB',
    },
  },
  textField: ({ legendWidthFixture }: any) => getBasicTextFieldStyles(legendWidthFixture),
})
