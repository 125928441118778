const customBaseOptions = {
  control: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: '400',
    width: '100%',
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    minHeight: '45px',
    borderColor: state.isFocused ? '#616882' : '#AFBCD5',
    '&:hover': {
      borderColor: state.isFocused ? '#616882' : '#AFBCD5',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '26px',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    backgroundColor: state.isSelected ? 'rgb(38, 132, 255)' : 'transparent',
    '&:hover': {
      backgroundColor: state.isSelected ? 'rgb(38, 132, 255)' : 'rgb(242, 242, 242)',
    },
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: '16px',
    color: '#212121',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#626262',
    fontSize: '16px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
}

export const CustomBaseSelectStyles = {
  ...customBaseOptions,
}
