import { connect } from 'react-redux'
import PageHeader from '../../kit/modules/page-header'
import { setOpenLogin } from '../reducers/EventDetail'

function MSTPEventDetail(state: any) {
  return {
    data: state.VolunteerEventDetail,
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    setOpenLogin: (isOpenLogin: boolean) => dispatch(setOpenLogin(isOpenLogin)),
  }
}

export default connect(MSTPEventDetail, mapDispatchToProps)(PageHeader)
