import React from 'react'
import { InputLabel } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

export const CredentialSelect = props => {
  return (
    <>
      <InputLabel id="select-type-label">Identification Type</InputLabel>
      <FormControl variant="outlined" className={props.classes.fullWidth}>
        <Select
          placeholder="Choose ID Type"
          value={props.selectedCredentialType}
          onChange={e => {
            props.handleTypeChange(e.target.value, props.form)
          }}
          className={props.classes.fullWidth}
        >
          {props.credentialIDTypes.map(type => (
            <MenuItem value={type}>{type.label}</MenuItem>
          ))}
        </Select>
        <div className={props.classes.errorText}>{props.form.errors.selectedCredentialTypeID}</div>
      </FormControl>
    </>
  )
}
