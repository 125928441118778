import React from 'react'
import { MainColorType, PALETTE_COLORS } from '../../../constants/colors'
import classNames from 'classnames'
import styled from 'styled-components'

import './index.scss'

interface LabelProps {
  title: string
  color?: MainColorType
  customTextColor?: string
  customBackgroundColor?: string
  className?: string
  icon?: JSX.Element
  children?: JSX.Element
}

const Label = (props: LabelProps) => {
  const {
    title,
    className,
    icon,
    customBackgroundColor,
    customTextColor,
    color = 'primary',
    children
  } = props

  const paletteMain = PALETTE_COLORS.mainColors
  const paletteText = PALETTE_COLORS.textColors

  const classNameList = classNames('label-component', {
    [`${className}`]: className,
    'label-component--icon': !!icon,
  })
  const setDefaultColor = color === 'thirdLight' ? paletteText.thirdLight : paletteText.white
  const setDefaultBackgroundColor = paletteMain[color]

  const labelColorStyle: { [key: string]: string } = {
    textColor: customTextColor ? customTextColor : setDefaultColor,
    backgroundColor: customBackgroundColor ? customBackgroundColor : setDefaultBackgroundColor,
  }

  const StyledLabel = styled('span')`
    background: ${labelColorStyle['backgroundColor']}
    color: ${labelColorStyle['textColor']}
  `

  return (
    <StyledLabel className={classNameList}>
      {icon ? icon : null}
      {title}
      {children ? children : null}
    </StyledLabel>
  )
}

export default Label
