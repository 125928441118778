import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import classNames from 'classnames'
import './style.scss'
import { BaseSelectStyles } from '../../../constants/default-select-styles'

interface ValueType {
  value: string
  label: string
}

interface ValueObjectType {
  value: {
    from: string
    to: string
  }
  label: string
}

interface SelectProps {
  children?: ReactNode
  isMulti?: boolean
  placeholder?: string
  disabled?: boolean
  required?: boolean
  hasError?: boolean
  customErrorMessage?: string
  name?: string
  value: ValueType | ValueObjectType
  onChange?: (event: any) => void
  onBlur?: (event: any) => void
  isSearchable?: boolean
  options: any[]
  styles?: { [prop: string]: (provided?: any, state?: any) => {} }
  label?: string
  className?: string
}

export default function SelectComponent(props: SelectProps) {

  const {
    disabled,
    customErrorMessage,
    label,
    name,
    isMulti,
    value,
    isSearchable,
    onBlur,
    onChange,
    placeholder,
    options,
    styles,
    className,
    hasError,
  } = props

  const SelectWrapper = styled('div')`
     width: 100%;
     border: 1px solid ${disabled ? 'rgba(175, 188, 213, 0.5)' : 'rgba(175, 188, 213)'};
  `
  const SelectLabel = styled.label`
    color: ${hasError ? '#FF6F3E' : '#A4A4A4'};
  `
  const selectClasses = classNames('select_container', {
    select_error: hasError,
    [`${className}`]: className,
  })
  const selectStyles = styles || BaseSelectStyles
  return (
    <SelectWrapper className={'select_wrapper'}>
      <SelectLabel>{label}</SelectLabel>
      <Select
        className={selectClasses}
        name={name}
        isDisabled={disabled}
        isMulti={isMulti}
        onChange={onChange}
        onBlur={onBlur}
        isSearchable={isSearchable}
        options={options}
        value={value}
        styles={selectStyles}
        placeholder={placeholder}
      />
      {hasError && <div className="select_error_message">{customErrorMessage}</div>}
    </SelectWrapper>
  )
}
