import DialogTitle from '@material-ui/core/DialogTitle'
import { Grid, Typography } from '@material-ui/core'
import cn from 'classnames'
import { ErrorOutline as ErrorOutlineIcon, ReportProblemOutlined as WarningIcon } from '@material-ui/icons'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DraggablePaper from './DraggablePaper'
import { Form, Formik } from 'formik'

export const useStyles = makeStyles({
  title: { cursor: 'move', lineHeight: '36px', padding: '20px 20px 16px 20px' },
  icon: { fontSize: '40px', display: 'block', marginRight: '12px', color: '#F4BF2C' },
  warningIcon: { color: '#DD3730' },
  caption: { flexWrap: 'nowrap' },
  text: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2D3038',
    padding: '8px 0',
    textAlign: 'justify',
    '&$noGap': {
      padding: 0,
      margin: 0,
    },
  },
  noGap: {},
  content: {
    padding: '0 20px',
  },
  boldText: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2D3038',
  },
  button: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 700,
    borderRadius: '100px',
    color: '#191C1D',
    padding: '8px 24px',
    backgroundColor: '#F4BF2C',
    textTransform: 'none',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  warning: {
    color: 'white',
    backgroundColor: '#BA1B1B',
    '&:hover': {
      backgroundColor: '#991414',
    },
  },
  cancelButton: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 700,
    color: '#74777F',
    borderRadius: '100px',
    padding: '8px 24px',
    textTransform: 'none',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  actions: {
    padding: '8px 20px 20px',
  },
})

export interface ConfirmDialogProps {
  showing: boolean
  complete: (value: any) => void
  close: () => void
  children?: React.ReactNode
  cancelText?: string
  confirmText: string
  confirmDisabled?: boolean
  text?: string
  id?: string
  title: string
  type?: 'default' | 'warning'
  initialValues?: Record<string, any>
  noTextGap?: boolean
}

const DialogIcon = (props: any) => {
  const { type, classes, ...rest } = props
  return type === 'warning' ? (
    <WarningIcon {...rest} classes={{ root: cn(classes.icon, classes.warningIcon) }} />
  ) : (
    <ErrorOutlineIcon {...rest} classes={{ root: classes.icon }} />
  )
}

export function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    showing,
    complete,
    close,
    title,
    cancelText,
    confirmText,
    confirmDisabled,
    text,
    children,
    id = 'draggable-dialog-title',
    type,
    initialValues = {},
    noTextGap,
  } = props
  const classes = useStyles()

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      onClose={() => close()}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#${id}` }}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
    >
      <Formik initialValues={initialValues} onSubmit={complete}>
        <Form>
          <DialogTitle classes={{ root: classes.title }} disableTypography={true} id={id}>
            <Grid container direction="row" alignItems="center" classes={{ root: classes.caption }}>
              <Grid item>
                <DialogIcon classes={classes} type={type} />
              </Grid>
              <Grid item>
                <Typography classes={{ root: classes.boldText }}>{title}</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent classes={{ root: classes.content }}>
            {text && (
              <DialogContentText classes={{ root: cn(classes.text, { [classes.noGap]: noTextGap }) }}>
                {text}
              </DialogContentText>
            )}
            {children}
          </DialogContent>
          <DialogActions className={classes.actions}>
            {cancelText ? (
              <Button className={classes.cancelButton} autoFocus onClick={close}>
                {cancelText}
              </Button>
            ) : (
              <div />
            )}
            <Button
              className={cn(classes.button, { [classes.warning]: type === 'warning' })}
              variant="contained"
              color="primary"
              disabled={!!confirmDisabled}
              type="submit"
            >
              {confirmText}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default ConfirmDialog
