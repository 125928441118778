import React, { useCallback, useMemo, useState } from 'react'
import StepModalContainerOld from '../StepModalContainer.old'
import FillPersonDataStep from './FillPersonDataStep'
import usePersonSearch from './hooks/useSearchPerson'
import filter from 'lodash/filter'
import PersonCheckStep from './PersonCheckStep'
import { Person } from '../../question-sets/types'
import PersonOverviewStep from './PersonOverviewStep'
import find from 'lodash/find'

export enum AddMemberType {
  ExistingUser = 'EXISTING_USER',
  NewUser = 'NEW_USER',
}

export interface AddPersonPayload {
  id: number | null
  sendInvite: boolean
  userData: UserData
}

export interface UserData {
  givenName?: string
  familyName?: string
  email?: null | string
  phoneNumber?: null | string
  homePhoneNumber?: null | string
  birthday?: null | Date
  sendInvite?: boolean
}

export interface AddPersonFlowProps {
  showing: boolean
  submit: (input: AddPersonPayload) => Promise<any>
  cancel: () => void
  personName?: string
  emailOrPhoneRequired?: boolean
  initialValues?: UserData
}

export function AddPersonFlow(props: AddPersonFlowProps) {
  const {
    showing,
    submit,
    cancel,
    personName = 'Person',
    emailOrPhoneRequired = false,
    initialValues = {
      givenName: '',
      familyName: '',
      email: null,
      phoneNumber: null,
      homePhoneNumber: null,
      birthday: null,
      sendInvite: true,
    },
  } = props
  const steps = useMemo(() => [`Add New ${personName}`, `${personName} Check`, 'Confirm'], [personName])
  const [activeStep, setActiveStep] = useState(0)
  const [userData, setUserData] = useState<UserData>(initialValues)
  const [existingUserId, setExistingUserId] = useState<number | null>(null)
  const [updateUserData, setUpdateUserData] = useState<UserData>({})
  const [persons, setPersons] = useState<Person[]>([])
  const [emailExactMatch, setEmailExactMatch] = useState<boolean>(false)
  const { searchPerson } = usePersonSearch()

  const handleSetPersonData = useCallback(
    async ({ givenName, familyName, email, phoneNumber, homePhoneNumber, birthday }, { setSubmitting }) => {
      setUserData({
        givenName,
        familyName,
        email,
        phoneNumber,
        homePhoneNumber,
        birthday,
      })
      const foundPersons = await searchPerson(email, phoneNumber, givenName, familyName)
      if (foundPersons.length) {
        const filteredPersons = filter(foundPersons, person => person.email === email)
        setEmailExactMatch(filteredPersons.length !== 0)
        setPersons(filteredPersons.length ? [...filteredPersons] : [...foundPersons])
        setActiveStep(currentStep => currentStep + 1)
      } else {
        setActiveStep(currentStep => currentStep + 2)
      }
      setSubmitting(false)
    },
    [searchPerson],
  )

  const handlePersonCheck = useCallback(
    async ({ personId }, { setSubmitting }) => {
      if (personId !== '0') {
        const id = parseInt(personId)
        setExistingUserId(id)
        const person = find(persons, 'id')
        const updatePersonData: UserData = {}
        if (!person?.email && userData.email) updatePersonData.email = userData.email
        if (!person?.phoneNumber && userData.phoneNumber) updatePersonData.phoneNumber = userData.phoneNumber
        setUpdateUserData(updatePersonData)
      }
      setActiveStep(currentStep => currentStep + 1)
      setSubmitting(false)
    },
    [persons, userData.email, userData.phoneNumber],
  )
  const handleSubmit = useCallback(
    async ({ sendInvite }: { sendInvite: boolean }, { setSubmitting }) => {
      return submit({ id: existingUserId, sendInvite, userData: existingUserId ? updateUserData : userData })
        .then(() => {
          cancel()
        })
        .finally(() => setSubmitting(false))
    },
    [cancel, existingUserId, submit, updateUserData, userData],
  )

  const getStepHeader = useCallback(
    activeStep => {
      switch (activeStep) {
        case 1:
          return emailExactMatch ? 'Email already in use' : `New ${personName} Check`
        case 2:
          return 'Review'
        default:
          return steps[activeStep]
      }
    },
    [steps, emailExactMatch, personName],
  )

  const getStepSubHeader = useCallback(
    activeStep => {
      switch (activeStep) {
        case 1:
          return emailExactMatch
            ? `The contact information you've entered is already in use from another ${personName}`
            : `Are you sure you don't mean one of this existing ${personName}s:`
        case 2:
          return `Please review the details of newly added ${personName}`
        default:
          return null
      }
    },
    [emailExactMatch, personName],
  )

  return (
    <StepModalContainerOld
      showing={showing}
      cancel={cancel}
      steps={steps}
      activeStep={activeStep}
      getStepHeader={getStepHeader}
      getStepSubHeader={getStepSubHeader}
    >
      {
        [
          <FillPersonDataStep
            cancel={cancel}
            handleSubmit={handleSetPersonData}
            initialValues={userData}
            emailOrPhoneRequired={emailOrPhoneRequired}
          />,
          <PersonCheckStep
            cancel={() => setActiveStep(0)}
            handleSubmit={handlePersonCheck}
            persons={persons}
            emailExactMatch={emailExactMatch}
            personName={personName}
          />,
          <PersonOverviewStep
            cancel={() => setActiveStep(1)}
            person={userData}
            personName={personName}
            handleSubmit={handleSubmit}
          />,
        ][activeStep]
      }
    </StepModalContainerOld>
  )
}

export default AddPersonFlow
