import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { withFormik, FormikProps, FormikValues, FormikBag } from 'formik'
import { object, string } from 'yup'
import InputMask from 'react-input-mask'
import uuid from 'uuid/v4'
import { Button } from '../../../../components/button'
import Input, { Props } from '../../../../components/input/index'
import Popup from '../../../../components/popup/index'
import IconComponent from '../../../../components/icon'
import {
  parseUsername,
  dateValidationSchema,
  emailValidationSchema,
  passwordValidationSchema,
  validatePhoneNumber,
  formatPhoneNumberForDatabase,
  PHONE_NUMBER_REGEX,
} from '../../../../../helpers/validation'
import { PALETTE_COLORS } from '../../../../../constants/colors'
import SocialLogin from '../../social-login'
import FeatureEnabled from '../../../features/FeatureEnabled'

import './style.scss'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'

const requiredMessage = 'This field is required.'

const signUpValidationSchema = object().shape({
  firstName: string().required(requiredMessage),
  lastName: string().required(requiredMessage),
  email: emailValidationSchema,
  phone: string()
    .transform((value: any) => formatPhoneNumberForDatabase(value))
    .test(
      'phone-validator',
      'Please enter a 10 digit phone number.',
      (value: any) => !value || PHONE_NUMBER_REGEX.test(value),
    )
    .nullable(),
  date: dateValidationSchema,
  password: passwordValidationSchema,
})

interface FormValues {
  firstName: string
  lastName: string
  email: string
  phone: string
  date: string
  password: string
  message?: string
}

interface FormErrors {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  date?: string
  password?: string
  message?: string
}

const initialFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  date: '',
  password: '',
}

interface PopupProps {
  onSignUp: (username: string, email: string, password: string, phone: string, sub?: string) => Promise<void>
  isOpen: boolean
  closePopup: (action: boolean) => void
  onSignInClick: () => void
}

interface FormProps {
  isOpen: boolean
  closePopup: (action: boolean) => void
  onSignInClick: () => void
}

const extractObjectFromText = (text: string) => {
  const regex = /\{(.+?)\}/g
  const matches = text.match(regex)

  return  matches ? JSON.parse(matches[0]) : {}
}

const SignUpPopupForm = (props: FormProps & FormikProps<FormValues>) => {
  const {
    closePopup,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isOpen,
    isSubmitting,
    isValid,
    values,
    onSignInClick,
    resetForm,
    setFieldValue,
    touched,
  } = props
  const [security, setSecurity] = useState(false)

  const showError = useErrorNotification()
  const hasError = (keyName: keyof FormErrors) => (errors[keyName] && touched[keyName] ? true : false)
  const getErrorMessage = (keyName: keyof FormErrors) => (hasError(keyName) ? errors[keyName] : '')

  useEffect(() => {
    if (validatePhoneNumber(values.phone)) {
      setFieldValue('phone', parseUsername(values.phone))
    }
  }, [setFieldValue, values.phone])

  useEffect(() => {
    if (Object.keys(errors).length > 0 && errors?.message) {
      const messageErrorResponse = extractObjectFromText(errors?.message ? errors.message : '')?.message
      showError(messageErrorResponse, errors.message)
    }
  }, [errors?.message])

  return (
    <Popup
      width={'498px'}
      height={'976px'}
      radius={'4px'}
      open={isOpen}
      closeIcon={true}
      clickOutside={true}
      closePopup={() => {
        resetForm()
        closePopup(false)
      }}
    >
      <div className="sign-up-wrap">
        <IconComponent
          name={'logo'}
          width={176}
          height={60}
          customColor={PALETTE_COLORS.mainColors['primary']}
          className={'sign-up-wrap_icon'}
        />
        <form className="sign-up-wrap-input">
          <span className={'sign-up_text'}>Sign Up</span>
          <div className={'sign-up'}>
            <Input
              style={{ marginBottom: 31 }}
              tag={'input'}
              placeholder={'First Name'}
              name={'firstName'}
              type={'text'}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={hasError('firstName')}
              errorMessage={getErrorMessage('firstName')}
            />
            <Input
              style={{ marginBottom: 31 }}
              className={'last-name'}
              tag={'input'}
              placeholder={'Last Name'}
              name={'lastName'}
              type={'text'}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={hasError('lastName')}
              errorMessage={getErrorMessage('lastName')}
            />
          </div>
          <Input
            style={{ marginBottom: 31 }}
            tag={'input'}
            placeholder={'Email Address'}
            name={'email'}
            type={'text'}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            hasError={hasError('email')}
            errorMessage={getErrorMessage('email')}
          />
          <Input
            style={{ marginBottom: 31 }}
            tag={'input'}
            placeholder={'Phone Number'}
            name={'phone'}
            type={'text'}
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            hasError={hasError('phone')}
            errorMessage={getErrorMessage('phone')}
          />
          <InputMask
            alwaysShowMask={true}
            placeholder={'Date of Birth'}
            mask={'99/99/9999'}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.date}
          >
            {(inputProps: Props) => (
              <Input
                {...inputProps}
                style={{ marginBottom: 31 }}
                tag={'input'}
                name={'date'}
                type={'text'}
                hasError={hasError('date')}
                errorMessage={getErrorMessage('date')}
              >
                {!values.date && <span className={'mask-label'}>MM/DD/YYYY</span>}
              </Input>
            )}
          </InputMask>

          <Input
            hasError={hasError('password')}
            errorMessage={getErrorMessage('password')}
            style={{ marginBottom: 25 }}
            tag={'input'}
            placeholder={'Password'}
            onChange={handleChange}
            onBlur={handleBlur}
            name={'password'}
            type={security ? 'text' : 'password'}
            value={values.password}
            children={
              <div onClick={() => setSecurity(!security)}>
                <IconComponent
                  height={!security ? 15 : 23.3}
                  width={22}
                  style={{ marginTop: 7 }}
                  customColor={'#AFBCD5'}
                  name={!security ? 'open_eye' : 'closed_eye'}
                />
              </div>
            }
          />
          <div className={'sign-up_text-wrap'}>
            <span className={'sign-up_terms'}>
              {'By clicking Sign Up below, or signing up with Google or Facebook \n' + 'you agree to our '}
            </span>
            <span
              onClick={() => window.open('https://civicchamps.com/privacypolicy/')}
              className={'sign-up_terms-link'}
            >
              {'Terms of Service'}
            </span>
            <span className={'sign-up_terms'}>?</span>
          </div>
          <Button
            className={'sign-up_button'}
            label={'Sign Up'}
            type={'secondary'}
            fontSize={'16px'}
            style={{ background: isValid ? '#5C8DE8' : '#B1BED6' }}
            labelColor={'#FFFFFF'}
            onClick={handleSubmit}
            isBold={true}
            loading={isSubmitting}
            disabled={!isValid}
          />
          <FeatureEnabled code="SocialSignIn">
            <div className={'sign-up_line-wrap'}>
              <span className={'sign-up_line'} />
              <span className={'sign-up_line-text'}>or</span>
              <span className={'sign-up_line'} />
            </div>
            <SocialLogin />
          </FeatureEnabled>
          <span className={'sign-up_have-account'}>
            Already have an account?{' '}
            <span className={'sign-up_terms-link'} onClick={onSignInClick}>
              Log In
            </span>
          </span>
        </form>
      </div>
    </Popup>
  )
}

const signUp = async (values: FormikValues, formikBag: FormikBag<PopupProps, FormValues>): Promise<void> => {
  const { firstName, lastName, email, phone, date, password } = values
  const lowerCasedEmail = email.toLowerCase()
  const parsedPhone = parseUsername(phone)
  const { props, setFieldError, setSubmitting, setErrors } = formikBag

  try {
    const username = uuid()
    const result = await Auth.signUp({
      username,
      password,
      attributes: {
        email: lowerCasedEmail,
        given_name: firstName,
        family_name: lastName,
        'custom:alt_phone_number': parsedPhone, // use a custom field to ensure an email is sent
        phone_number: !email ? parsedPhone : '',
        birthdate: date,
      },
    })
    props.onSignUp(username, email, password, phone, result.userSub)
    props.closePopup(true)
  } catch (error) {
    setErrors(error)
    const messageErrorResponse = extractObjectFromText(error?.message ? error.message : '')?.message

    switch (error.code) {
      case 'UsernameExistsException':
        setFieldError('email', error.message)
        break
      case 'UserLambdaValidationException':
        setFieldError('email', messageErrorResponse)
        break

      default:
    }
  } finally {
    setSubmitting(false)
  }
}

const SignUpPopup = withFormik<PopupProps, FormValues>({
  mapPropsToValues: () => initialFormValues,
  validationSchema: signUpValidationSchema,
  handleSubmit: signUp,
})(SignUpPopupForm)

export default SignUpPopup
