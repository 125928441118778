import * as React from 'react'
import { BaseSyntheticEvent } from 'react'
import QuestionSkeleton from '../question-skeleton'
import { Question, QuestionFormBaseProps } from '../../../../types'
import FileInputComponent from 'civic-champs-shared/question-sets/components/question-set-answering/base/default-file'

interface DocumentQuestionProps extends QuestionFormBaseProps {
  onChange: (value: string, item: Question) => void
  fieldName: string
  organizationId: number
}

const DocumentQuestion = (props: DocumentQuestionProps) => {
  const { item, onChange, error, fieldName, organizationId } = props

  if (item.disabled) {
    return null
  }

  const handleChange = (item: Question) => (file: any) => {
    onChange(file, item)
  }

  return (
    <QuestionSkeleton questionTitle={item.question} error={error} isRequired={item.isRequired}>
      <FileInputComponent
        value={item.response}
        name={fieldName}
        onChange={handleChange(item)}
        documentTypeId={Number(item.options[0])}
        organizationId={organizationId}
      />
    </QuestionSkeleton>
  )
}

export default DocumentQuestion
