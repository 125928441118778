import { useCallback, useMemo } from 'react'
import { useFetch } from '../../api/hooks'

const getUrlAndMethod = questionSetPreReq => {
  const { answerSetId, associatedEntity } = questionSetPreReq
  const { groupId, encodedOccurrence, messageUuid, organizationId } = associatedEntity
  const { method, suffix } = answerSetId ? { method: 'put', suffix: `/${answerSetId}` } : { method: 'post', suffix: '' }
  // this url is for 'global' user profiles (which are not associated with any specific entity)
  let url = `/user-profile-answer-set${suffix}`
  if (groupId) {
    url = `/groups/${groupId}/answer-set${suffix}`
  } else if (messageUuid) {
    url = `/notification-blasts/${messageUuid}/answer-set${suffix}`
  } else if (encodedOccurrence) {
    url = `/opportunity_instances/${encodedOccurrence}/answer-set${suffix}`
  } else if (organizationId) {
    url = `/organizations/${organizationId}/user-profile-answer-set${suffix}`
  }
  return { method, url }
}

export const useAnswerQuestionSet = (onSuccess, onError) => {
  const [request, { loading, error, result }] = useFetch(
    'Error answering questionnaire.  Please refresh the page to try again',
  )
  const answerQuestionSet = useCallback(
    questionSet => {
      const { url, method } = getUrlAndMethod(questionSet)
      return request({
        method,
        url,
        config: {
          body: questionSet,
        },
        onSuccess,
        onError,
      })
    },
    [request, onSuccess, onError],
  )

  return useMemo(
    () => [
      answerQuestionSet,
      {
        result,
        loading,
        error,
      },
    ],
    [result, loading, error],
  )
}
