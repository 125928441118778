import * as React from 'react'
import { QuestionFormBaseProps } from '../../../../types'
import RadioButtonComponent from '../../base/radio-button'
import QuestionSkeleton from '../question-skeleton'
import * as _ from 'lodash'

interface CheckboxQuestionProps extends QuestionFormBaseProps {
  onChange: (selectedOption: boolean) => void
  onBlur: () => void
}

const options = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
]

const TrueFalseQuestion = (props: CheckboxQuestionProps) => {
  const { item, onChange, error, onBlur, readonly } = props

  const handleChangeOption = (option: boolean) => () => onChange(option)

  if (item.disabled) {
    return null
  }

  return (
    <QuestionSkeleton questionTitle={item.question} error={error} isRequired={item.isRequired}>
      {options.map((option: { value: boolean; label: string }, index: number) => {
        return (
          <RadioButtonComponent
            key={index}
            id={`${item.questionId}${index}radio`}
            onChange={handleChangeOption(option.value)}
            name={`${item.questionId}radioGroup`}
            label={option.label}
            value={_.toString(option.value)}
            selectedValue={item.response}
            onBlur={onBlur}
            readonly={readonly}
          />
        )
      })}
    </QuestionSkeleton>
  )
}

export default TrueFalseQuestion
