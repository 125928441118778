import { isEmpty } from 'lodash'
import React, { useState, useCallback } from 'react'
import { Hub, Auth } from 'aws-amplify'
import { useErrorNotification } from '../../../../civic-champs-shared/api/hooks'

import './style.scss'
import Popup from '../../../components/popup'
import Input from '../../../components/input'
import { Button } from '../../../components/button'
import Label from '../../../../kit/components/label'
import AnchorResendCode from '../../../../kit/components/anchor-resend-code/'
import { PALETTE_COLORS } from '../../../../constants/colors'
import ENDPOINT_ROUTES from '../../../../constants/APIs'
import BaseService from '../../../../services/base.service'
import useSuccessNotification from '../../../../civic-champs-shared/api/hooks/useSuccessNotification'

const baseService = new BaseService()

interface SignUpInfo {
  username: string
  email: string
  password: string
  phone: string
}

interface ConfirmSignUpProps {
  isOpen: boolean
  signUpInfo: SignUpInfo
  onConfirmed: () => void
  closePopup: () => void
}

const waitFor = (duration: number) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve()
    }, duration)
  })

// TODO maybe turn this into a usePrompt() hook?

export default function ConfirmSignUp(props: ConfirmSignUpProps) {
  const { isOpen, signUpInfo, onConfirmed, closePopup } = props

  const [code, setCode] = useState('')
  const [error, setError] = useState('')

  const [confirming, setConfirming] = useState(false)

  const [resendingDisabled, setResendingDisabled] = useState(false)
  const showSuccess = useSuccessNotification()
  const showError = useErrorNotification()

  const validateCode = useCallback(
    code => {
      if (isEmpty(code)) {
        setError('Code is required')
      } else if (!/^\d{6}$/.test(code)) {
        setError('Invalid Code')
      } else {
        setError('')
      }
    },
    [setError],
  )

  const handleChange = useCallback(
    e => {
      const code = e.target.value
      setCode(code)
      validateCode(code)
    },
    [setCode, validateCode],
  )

  const handleSubmit = useCallback(async () => {
    setConfirming(true)
    try {
      // TODO having the sign-up use 3 different variants feels a bit klunky
      const env = baseService.getEnvVar('ENV')
      if (env === 'development') {
        const endpointOverride = baseService.getEnvVar('NGROK_API_URL') as string
        if (!isEmpty(endpointOverride)) {
          const cognitoPoolId = baseService.getEnvVar('COGNITO_USER_POOL_ID')
          console.log(`sending ${endpointOverride} as API override for ${cognitoPoolId}`)

          const clientMetadata = { endpointOverride }
          await Auth.confirmSignUp(signUpInfo.username, code, { clientMetadata })
          await Auth.signIn(signUpInfo.username, signUpInfo.password)
        } else {
          await Auth.confirmSignUp(signUpInfo.username, code)
          const user = await Auth.signIn(signUpInfo.username, signUpInfo.password)

          await baseService.postJSON(ENDPOINT_ROUTES.User.verificate(1), { cognito_sub: user.attributes.sub })
          Hub.dispatch('app', { event: 'fetchUser', data: user })
        }
      } else {
        await Auth.confirmSignUp(signUpInfo.username, code)
        await Auth.signIn(signUpInfo.username, signUpInfo.password)
      }

      setCode('')
      onConfirmed()
      closePopup()
    } catch (error) {
      setError(error.message)
    } finally {
      setConfirming(false)
    }
  }, [signUpInfo.username, signUpInfo.password, code, onConfirmed, closePopup])

  const handleSubmitResendCode = async () => {
    setResendingDisabled(true)
    try {
      await Auth.resendSignUp(signUpInfo.username)
      showSuccess('Verification code sent')
    } catch (err) {
      showError('Error sending code', err)
    } finally {
      await waitFor(5000)
      setResendingDisabled(false)
    }
  }

  return (
    <Popup open={isOpen} height="458px" width="498px" radius="4px" closeIcon={true} closePopup={closePopup}>
      <div className="confirm-signup-popup">
        <div className="confirm-signup-popup__title" style={{ color: PALETTE_COLORS.textColors.default }}>
          Complete Sign-Up
        </div>
        <div className="confirm-signup-popup__text" style={{ color: PALETTE_COLORS.textColors.default }}>
          Please enter the verification code <br />
          we've sent to {signUpInfo.email || signUpInfo.phone}
        </div>
        <div className="confirm-signup-popup__form">
          <div className="confirm-signup-popup__form__input">
            <Input
              name="code"
              type="text"
              hasError={!!error}
              errorMessage={error}
              tag="input"
              placeholder="Code"
              maxLength={6}
              value={code}
              onChange={handleChange}
            />
            <Label
              className="confirm-signup-popup__form__label"
              title="Didn't receive the code?"
              customBackgroundColor="white"
              customTextColor="primary"
            >
              <AnchorResendCode
                anchorText="Resend verification code"
                disabled={resendingDisabled}
                onClick={handleSubmitResendCode}
              />
            </Label>
          </div>
          <div>
            <Button
              label="Verify Account"
              type="secondary"
              height={'46px'}
              width={'auto'}
              isBold={true}
              labelColor={PALETTE_COLORS.textColors.white}
              style={{ background: !code || !!error ? '#B1BED6' : '#5C8DE8' }}
              disabled={!code || !!error}
              loading={confirming}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}
