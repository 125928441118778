import { useCallback, useEffect } from 'react'
import { useFetch } from '../../api/hooks'

const STABLE_EMPTY_ARRAY = []

export const useCredentialTypes = (onResultUpdate, organizationId) => {
  const [request, { result, loading, called }] = useFetch(
    'Error Fetching Groups.  Please refresh the page and try again',
  )

  const refetch = useCallback(async () => {
    return request({
      onSuccess: result => {
        onResultUpdate && onResultUpdate(result)
      },
      method: 'get',
      url: `/identification-types`,
      config: {
        queryStringParameters: { reporting: true }
      }
    })
  }, [organizationId, request])

  useEffect(() => { refetch() }, [refetch])

  return {
    useCredentialTypes: result || STABLE_EMPTY_ARRAY,
    loading: loading || !called,
    refetch,
  }
}