import React from 'react'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepperComponent, { StepperProps } from '@material-ui/core/Stepper/Stepper'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import StepConnector from '@material-ui/core/StepConnector'
import { StepIconProps } from '@material-ui/core/StepIcon/StepIcon'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

export const Connector = withStyles(theme =>
  createStyles({
    alternativeLabel: {
      top: 16,
      left: 'calc(-50% + 15px)',
      right: 'calc(50% + 14px)',
    },
    active: {
      '& $line': {
        borderColor: theme.palette.primary.main,
      },
    },
    completed: {
      '& $line': {
        borderColor: theme.palette.primary.main,
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }),
)(StepConnector)

const useStyles = makeStyles(theme =>
  createStyles({
    stepper: {
      width: '100%',
      padding: 0,
    },
    stepLabel: {
      '&.MuiStepLabel-label': {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        marginTop: '8px',
      },
      '&.MuiStepLabel-active': {
        color: theme.palette.primary.main,
      },
      '&.MuiStepLabel-completed': {
        color: theme.palette.primary.main,
      },
    },
    stepLabelDisabled: {
      color: '#eaeaf0',
    },
    stepLabelRoot: {
      marginBottom: '40px',
    },
  }),
)

const CheckIcon = (props: StepIconProps): JSX.Element => {
  return <CheckCircleIcon color={props.active || props.completed ? 'primary' : 'disabled'} style={{ fontSize: 32 }} />
}

export interface ExtendedStepperProps extends Omit<StepperProps, 'children'> {
  steps: string[]
}

export default function StepperOld({ steps, ...props }: ExtendedStepperProps) {
  const classes = useStyles()
  return (
    <StepperComponent {...props} classes={{ root: classes.stepper }} alternativeLabel connector={<Connector />}>
      {steps.map(label => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={CheckIcon}
            classes={{
              root: classes.stepLabelRoot,
              alternativeLabel: classes.stepLabel,
              disabled: classes.stepLabelDisabled,
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </StepperComponent>
  )
}
