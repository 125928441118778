import * as yup from 'yup'

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89AB][0-9a-f]{3}-[0-9a-f]{12}$/i

yup.addMethod(yup.string, 'uuid', function () {
  return this.matches(UUID_REGEX, 'is not a UUID')
})

yup.addMethod(yup.string, 'phoneNumber', function (message) {
  return this.transform(function (value) {
    if (!value) {
      // will cause issues if the field isn't nullable()
      return null
    }
    // stripping away all '+' and '-'
    const interimValue = value.replace(/\D/g, '')
    if (interimValue === '1') {
      // this will happen if you click into the input mask and click out
      return null
    }
    // ensuring +1 is always there
    const prefix = interimValue.length === 11 ? '+' : '+1'
    return prefix + interimValue
  }).test({
    name: 'phoneNumber',
    message: message || '${path} must be a valid US phone number',
    exclusive: true,
    test: value => !value || /([+][1][2-9][0-9]{9})/.test(value),
  })
})

yup.addMethod(yup.object, 'atLeastOneOf', function (list, message) {
  return this.test({
    name: 'atLeastOneOf',
    message: message || '${path} must have at least one of: ${keys}',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: value => list.some(key => value[key]),
  })
})

// copied from here: https://github.com/jquense/yup/issues/345
yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      return true
    }

    const { path } = this
    const options = [...this.parent]
    const currentIndex = options.indexOf(value)

    const subOptions = options.slice(0, currentIndex)

    if (subOptions.some(option => option[propertyName] === value[propertyName])) {
      throw this.createError({
        path: `${path}.${propertyName}`,
        message,
      })
    }

    return true
  })
})

// https://stackoverflow.com/questions/61170893/how-to-check-value-is-already-exits-in-array-of-object-validation-yup
yup.addMethod(yup.array, 'unique', function (message, mapper = x => x) {
  return this.test('unique', message, function (list) {
    const set = [...new Set(list.map(mapper))]
    const isUnique = list.length === set.length
    if (isUnique) {
      return true
    }
    const idx = list.findIndex((l, i) => mapper(l) !== set[i])
    const { path } = this
    return this.createError({
      path: `${path}.${idx}`,
      message: message,
    })
  })
})

yup.addMethod(yup.number, 'id', function () {
  return this.integer().moreThan(0)
})

yup.addMethod(yup.object, 'optional', function (isOptional = true, defaultValue = undefined) {
  return (
    this.transform(function (value) {
      // If false is passed, skip the transform
      if (!isOptional) return value

      // If any child property has a value, skip the transform
      if (value && Object.values(value).some(v => !(v === null || v === undefined || v === ''))) {
        return value
      }

      return defaultValue
    })
      // Remember, since we're dealing with the `object`
      // type, we have to change the default value
      .default(defaultValue)
  )
})

export default yup
