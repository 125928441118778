import { isFunction } from 'lodash';

//TODO someone probably has a version of this written somewhere that has tests etc...
export default class EventEmitter {

    constructor() {
        this._events = new Map();
    }

    emit(event, ...args) {
        let listeners = this._events.get(event) || [];
        for(let listener of listeners) {
            try {
                listener(...args);
            } catch(e) {
                console.error(e);
            }
        }
    }

    listen(event, callback) {
        if( !isFunction(callback) ) {
            throw new Error(`callback for event '${event}' must be a function`);
        }

        let listeners = this._events.get(event);
        if(!listeners) {
            listeners = [];
            this._events.set(event, listeners);
        }

        listeners.push(callback);

        let unlisten = () => {
            this.unlisten(event, callback);
        }

        return unlisten;
    }

    unlisten(event, callback) {
        let listeners = this._events.get(event);
        if(!listeners) return false;

        let index = listeners.indexOf(callback);
        if(index > -1) {
            listeners.splice(index, 1);
            return true;
        }

        return false;
    }
}