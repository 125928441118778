import React from 'react'
import './style.scss'
import { ROUTE_NAMES } from 'constants/route-names'
import { Link } from 'react-router-dom'

const ErrorFallback = ({ orgId, message }: any) => {
  const urlLink = orgId ? ROUTE_NAMES.allEvents(orgId) : null
  return (
    <div className="errors-container">
      <div className={'inner_error_container'}>
        <h4 className={'error_title'}>
          Uh oh!
          <br />
          Page not found
        </h4>
        <div className={'error_message'}>
          {message || 'It looks like this page doesn’t exist or has been removed.'}
          {urlLink && (
            <>
              You can go back home <Link to={urlLink}>here</Link>.
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ErrorFallback
