import React from 'react'
import { Slider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { roundMetersTo5Feet } from '../helpers/toRoundMetersTo5Feet'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    overflow: 'hidden',
  },
  text: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > p': {
      margin: 0,
      padding: 0,
    },
  },
  title: {
    color: '#191C1D',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  radius: {
    color: '#191C1D',
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
  sliderContainer: {
    padding: '0 6px',
  },
})

interface Props {
  onChange: (e: number) => void
  radius: number
  min: number
  max: number
}

export default function CompactGeofenceRadiusSlider(props: Props) {
  const { onChange, radius, min, max } = props
  const classes = useStyles()

  const handleChangeRadius = (e?: any, newRadius?: any) => {
    onChange(newRadius)
  }

  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <p className={classes.title}>Geofence Radius</p>
        <p className={classes.radius}>approx. &nbsp;{roundMetersTo5Feet(radius)}ft</p>
      </div>
      <div className={classes.sliderContainer}>
        <Slider aria-label="Radius" min={min} max={max} value={radius} onChange={handleChangeRadius} />
      </div>
    </div>
  )
}
