import React from 'react'
import { Field, Form, Formik } from 'formik'
import Grid from '@material-ui/core/Grid'
import SwitchToggle from '../../formik/components/SwitchToggle'
import { Button, Typography } from '@material-ui/core'
import { useModalStyles } from '../StepModalContainer'
import { UserData } from './AddPersonFlow'

export function PersonOverviewStep({
  handleSubmit,
  cancel,
  person,
  personName,
}: {
  handleSubmit: (...props: any) => any
  cancel: () => void
  person: UserData
  personName: string
}) {
  const classes = useModalStyles()
  return (
    <Formik initialValues={{ sendInvite: true }} onSubmit={handleSubmit}>
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                {person.givenName} {person.familyName}
                {person.email && (
                  <>
                    <br />
                    <a className={classes.a} href={`mailto:${person.email}`}>
                      {person.email}
                    </a>
                  </>
                )}
                {person.phoneNumber && (
                  <>
                    <br />
                    {person.phoneNumber}
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field label="Send Invite" component={SwitchToggle} name="sendInvite" />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            classes={{ root: classes.buttonsContainer }}
          >
            <Button onClick={cancel} variant="contained" color="secondary" className={classes.button}>
              Back
            </Button>
            <Button
              className={classes.button}
              disabled={isSubmitting}
              variant="contained"
              color="primary"
              onClick={submitForm}
            >
              Add New {personName}
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default PersonOverviewStep
