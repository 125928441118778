import React, { useState } from 'react'
import * as _ from 'lodash'
import { useRequirementsManagement } from '../hooks/useRequirementsManagement'
import { QuestionAnsweringForm } from './question-set-answering/QuestionAnsweringForm'
import Loading from '../../core/Loading'
import { RequirementsProps } from '../types'
import WaiversToSign from './WaiversToSign'
import CredentialIds from './CredentialIds'

export const RequirementsContainer = (props: RequirementsProps) => {
  const { isAdmin, continueEvenIfError, organizationId } = props
  const [excludedQuestionSetIds, setExcludedQuestionSetIds] = useState<Array<number>>([])
  const { loading, requirements, allSet, refresh, answerQuestionSet, person } = useRequirementsManagement({
    excludedQuestionSetIds,
    person: props.person,
    filters: props.filters,
    onCompleted: props.onCompleted,
    continueEvenIfError,
  })
  const { credentials, questionSets } = requirements

  if (loading) {
    return <Loading />
  }

  const grouped = _.groupBy(credentials, 'credential.type')
  const onSubmit = (submission: any) => answerQuestionSet(submission.questionSet)

  if (allSet) {
    return <div>All Set!</div>
  }

  if (grouped.identification?.length) {
    return (
      <CredentialIds
        credentialIds={grouped.identification}
        user={person}
        complete={refresh}
        organizationId={organizationId}
      />
    )
  }

  if (grouped.waiver?.length) {
    return <WaiversToSign waivers={grouped.waiver} user={person} complete={refresh} />
  }

  if (questionSets.length) {
    const [questionSet] = questionSets
    return (
      <QuestionAnsweringForm
        onSubmit={onSubmit}
        heading={`Please answer the following questions in order to register for '${questionSet.associatedEntity.name}'`}
        questionSet={{ ...questionSet }}
        required={
          isAdmin || questionSet.questions.every(question => !question.isRequired || !!question.disabled)
            ? false
            : questionSet.required
        }
        onSkip={submission =>
          setExcludedQuestionSetIds(_.uniq([...excludedQuestionSetIds, submission.questionSet.questionSetId]))
        }
        organizationId={organizationId as number}
      />
    )
  }

  return <div>Uh oh! something went wrong</div>
}
