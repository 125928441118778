import { Button } from '../../../components/button'
import IconComponent from '../../../components/icon'
import React from 'react'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib-esm/types'
import { Auth } from 'aws-amplify'
import './style.scss'

interface Props {}

export default function SocialLogin(props: Props) {
  const googleSignIn = async () => {
    try {
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
    } catch (error) {
      console.log('error ', error)
    }
  }

  const facebookSignIn = async () => {
    try {
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })
    } catch (error) {
      console.log('error ', error)
    }
  }

  const socials = [
    { label: 'Sign In with Google', icon: 'google', onclick: googleSignIn },
    { label: 'Sign In with Facebook', icon: 'facebook', onclick: facebookSignIn },
  ]
  return (
    <div className={'social-buttons'}>
      {socials.map((soc: any, index) => {
        return (
          <Button
            key={index}
            className={'social-button'}
            type={'outline'}
            label={soc.label}
            onClick={soc.onclick}
            icon={<IconComponent height={'21px'} width={'20px'} style={{ marginRight: 8 }} name={soc.icon} />}
          />
        )
      })}
    </div>
  )
}
