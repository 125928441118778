export const paths = {
  ROOT: '/',
  UNAUTHORIZED: '/unauthorized',
  EVENT_REGISTRATION: '/organizations/:organizationId/opportunities/:eventId/event-registration/:token?',
  EVENT_DETAILS: '/organizations/:organizationId/activities/:eventId',
  MY_EVENTS_LIST: '/organizations/:organizationId/my/activities',
  TOKEN_LOGIN: '/organizations/:organizationId/token/:token',
  TOKEN_LOGIN_REGISTER: '/organizations/:organizationId/opportunity-id/:opportunityId/token/:token',
  CALENDAR: '/organizations/:organizationId/calendar/:viewType?',
  OPPORTUNITIES: '/organizations/:organizationId/opportunities',
  OPPORTUNITY_DETAILS: '/organizations/:organizationId/opportunities/:eventId',
}
