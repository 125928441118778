import { Geofencing } from '../core/location/utils'

export const DEFAULT_GEOFENCING: Geofencing = {
  location: {
    coordinates: {
      lat: 40.4260325,
      lng: -80.0109685,
    },
    type: 'Point',
  },
  radius: 60.96,
}
